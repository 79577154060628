import React from "react";
import axios from "axios";
import STRIPE_PUBLISHABLE_V2 from "./constants/stripeV2";
import PAYMENT_SERVER_URL_V2 from "./constants/serverV2";

const fromDollarToCent = (amount) => parseInt(amount * 100);

const successPayment = () => {
    window.location.href = "/confirmation";
};

const successPaymentFr = () => {
    window.location.href = "/confirmation-fr";
};

const errorPayment = () => {
    window.location.href = "/error";
};

const errorPaymentFr = () => {
    window.location.href = "/error-fr";
};

class CheckoutV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            processing: false,
        };
    }

    componentDidMount() {
        this.fetchPaymentIntent(this.props);
    }

    fetchPaymentIntent(props) {
        if (props.amount) {
            const {
                amount,
                currency,
                description,
                email,
            } = this.props;

            axios
                // first get paymentIntent from cloud function (new stripe server)
                .post(
                    PAYMENT_SERVER_URL_V2,
                    {
                        amount: fromDollarToCent(amount),
                        currency: currency,
                        description: description,
                        receipt_email: email,
                        // this is from stripe dashboard, settings, payment config (chooses which payment methods you want)
                        payment_method_configuration: "pmc_1JxeTgGvNegQjJCFyiXOXaMU",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                )
                // once payment intent is there, mount the stripe UI
                .then((r) => {
                    const stripe = window.Stripe(STRIPE_PUBLISHABLE_V2);
                    let elements = stripe.elements({
                        appearance: {
                            theme: "stripe",
                        },
                        clientSecret: r.data.clientSecret,
                    });

                    const paymentElement = elements.create("payment", {
                        layout: "tabs",
                    });
                    paymentElement.mount("#payment-element");
                    let button = document.getElementById("pay-button");

                    // change the "Pay Now" button logic to use stripe (can't be done before)

                    button.onclick = function () {
                        stripe.confirmPayment({
                                elements,
                                confirmParams: {
                                    return_url: `${window.location.origin}/confirmation`,
                                },
                        })
                    };
                }).catch((e) => {
                    // console.log("error", e);
                    window.location.href="/error"
                });
                // // unable to fetch payment intent, stripe stuff won't show up. User can refresh page

        }
    }

    render() {
        return (
            <div id="payment-form">
                <div id="payment-element"></div>
                <div style={{width: "100%"}} onClick={() => this.setState({processing: true})}>
                    <button id="pay-button">
                        <div className="spinner hidden" id="spinner"></div>
                        <span id="button-text">{this.state.processing ? `Processing` : `Continue`}</span>
                    </button>
                </div>

                <div id="payment-message" className="hidden"></div>
            </div>
        );
    }
}

export default CheckoutV2;
