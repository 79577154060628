import localStorageFunctions from "../../comp-localStorage/localStroage";

export default function checkLatestVersion (versionCode) {
    var versionNumber = "version2.3";
    if (localStorage.getItem("versionNumber") !== versionNumber) {
        // console.log(localStorage.getItem("versionNumber"))
        localStorage.clear();
        localStorage.setItem("versionNumber", versionNumber)
    } else {
        //no action needed.
    }
}