import React from "react";
import addMultipleWindowsClicked from "./components/artWindowOpening/addingMultipleWindows";
import ShowMattingWindows from "./components/artWindowOpening/showWindows";
import {frameInventory} from "../comp-prodDesc/frameInventory";
import ChangeProductRendering from "./components/changeProductRendering";
import ShowProductDescription from "./components/showProductDescription";
import MatBoardActions from "./components/matBoardActions";
import windowCountUpdate from "./components/artWindowOpening/windowCountUpdate";
import updateWindowSizeCallBack from "./components/artWindowOpening/updateWindowSize";
import frameCssRendering from "./components/artWindowOpening/frameCssRendering";
import updateWindowFunctions from "./components/artWindowOpening/updateWindowFunctions";
import {productDB} from "../../../javascript/components/comp-pricing/productDB";
import aspenFrame from "../../../resources/images/frameDetailedPhotos/frameCategoryColours/aspen.png";
import blackFrame from "../../../resources/images/frameDetailedPhotos/frameCategoryColours/black.png";
import walnutFrame from "../../../resources/images/frameDetailedPhotos/frameCategoryColours/walnut.png";
import goldFrame from "../../../resources/images/frameDetailedPhotos/frameCategoryColours/gold.png";
import silverFrame from "../../../resources/images/frameDetailedPhotos/frameCategoryColours/silver.png";
import whiteFrame from "../../../resources/images/frameDetailedPhotos/frameCategoryColours/white.png";
import localStorageFunctions from "../comp-localStorage/localStroage";
import helpIcon from "../../../resources/svgIcons/help-icon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import SecondaryMat from "./components/secondaryMat";
import DrawingAppMain from "./components/dimensionDrawing/drawingAppMain";
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';

import arrowUp from "../../../resources/svgIcons/select-mat-arrow-up.svg";
import arrowDown from "../../../resources/svgIcons/select-mat-arrow-down.svg";
import LandingPageFAQ from "../page-landing/landingPageFAQ";
import ReviewComponent from "../app-review/reviewComponent";
import InstagramLoader from "../page-landing/instagramLoader";
import LandingPageReviewStars from "../page-landing/landingPageReviewStars";
import SatisfactionTransition from "../page-landing/satisfactionTransition";
import {Helmet} from "react-helmet";
import displayInputFields from "./components/displayInputFields";
import checkLatestVersion from "../page-landing/comp-landingPage/checkLatestVersion";

export default class SelectMat extends React.Component {

  constructor() {
    super();
    this.state = {
      liveChatModalOpen: false,
      selectedFrame: "",
      selectedFrameSize: "",
      showMultipleWindowSelectionModal: false,
      showMultipleFrameModal: false,
      showAddNoteModal: false,
      matboardSizeValueType: "decimals",
      matboardWindowValueType: "decimals",
      doubleMattingValueType: "decimals",
      openQuantityModal: true,


      //only show matting margins when the mouse is hovered on the arrow.
      showMattingMargins: false,
      valueEntered: false,
      orderQuantity: 1,
      windowCount: 1,
      price: 49.99,
      matColour: "smoothWhite",
      multipleWindow: false,
      userSelectedFrameType: localStorageFunctions("selectedFrameType", null),
      userSelectedFrameSize: localStorageFunctions("userSelectedFrameSize", null),

      // showMargin: true,
      renderingToolWidth: 400, //400px
      renderingToolHeight: 400, // 400px
      deviceType: "web",

      fractionalObject: {
        "None": 0,
        "1/8": 0.125,
        "1/4": 0.25,
        "3/8": 0.375,
        "1/2": 0.5,
        "5/8": 0.625,
        "3/4": 0.75,
        "7/8": 0.875,
      },

      standardMatSizes: {
        "8x10": {
          "width": 8,
          "height": 10,
          "description": `- Standard sized frames.`
        },
        "9.75x9.75": {
          "width": 9.75,
          "height": 9.75,
          "description": `- IKEA square frames`
        },
        "9.75x19.75": {
          "width": 9.75,
          "height": 19.75,
          "description": `- IKEA panoramic frame (vertical)`
        },
        "10x8": {
          "width": 10,
          "height": 8,
          "description": `- Standard sized frames`
        },
        "11x14": {
          "width": 11,
          "height": 14,
          "description": `- Standard sized frames`
        },
        "12x16": {
          "width": 12,
          "height": 16,
          "description": `- Standard sized frames`
        },
        "14x11": {
          "width": 14,
          "height": 11,
          "description": `- Standard sized frames`
        },
        "16x12": {
          "width": 16,
          "height": 12,
          "description": `For 16 x 12 standard frames`
        },
        "14x18": {
          "width": 14,
          "height": 18,
          "description": `For 14 x 18 standard frames`
        },
        "18x14": {
          "width": 18,
          "height": 14,
          "description": `For 18 x 14 standard frames`
        },
        "16x20": {
          "width": 16,
          "height": 20,
          "description": `For 16 x 20 standard frames`
        },
        "18x24": {
          "width": 18,
          "height": 24,
          "description": `For 18 x 24 standard frames`
        },
        "19.75x9.75": {
          "width": 19.75,
          "height": 9.75,
          "description": `For IKEA's panoramic frames`
        },
        "19.75x19.75": {
          "width": 19.75,
          "height": 19.75,
          "description": `For 19.75 x 19.75 IKEA square frames.`
        },
        "19.75x27.5": {
          "width": 19.75,
          "height": 27.5,
          "description": `For 19.75 x 27.5 Amazon and IKEA frames`
        },
        "20x16": {
          "width": 20,
          "height": 16,
          "description": `For 20 x 16 standard frames`
        },
        "20x20": {
          "width": 20,
          "height": 20,
          "description": `For 20 x 20 standard square frames.`
        },
        "20x28": {
          "width": 20,
          "height": 28,
          "description": `For 20 x 28 standard frames`
        },
        "22x28": {
          "width": 22,
          "height": 28,
          "description": `For 22 x 28 standard frames`
        },
        "24x18": {
          "width": 24,
          "height": 18,
          "description": `For 24 x 18 standard frames`
        },
        "24x35.75": {
          "width": 24,
          "height": 35.75,
          "description": `For 24 x 35.75 IKEA frames`
        },
        "24x36": {
          "width": 24,
          "height": 36,
          "description": `For 24 x 35.75 standard frames`
        },
        "27.5x19.75": {
          "width": 27.5,
          "height": 19.75,
          "description": `For 27.5 x 19.75 Amazon and IKEA frames`
        },
        "28x20": {
          "width": 28,
          "height": 20,
          "description": `For 28 x 20 Amazon and IKEA frames`
        },
        "28x22": {
          "width": 28,
          "height": 22,
          "description": `For 28 x 22 standard frames`
        },
        "35.75x24": {
          "width": 35.75,
          "height": 24,
          "description": `For 35.75 x 24 IKEA frames`
        },
        "36x24": {
          "width": 36,
          "height": 24,
          "description": `For 35.75 x 24 standard frames`
        },
      },

      standardWindowSizes: {
        "3.75x5.75": {
          width: 3.75,
          height: 5.75,
          desc: "for 4 x 6 photo",
          descFr: "pour photo 4 x 6",
        },

        "4.75x6.75": {
          width: 4.75,
          height: 6.75,
          desc: "for 5 x 7 photo",
          descFr: "pour photo 5 x 7",
        },

        "4.75x14.75": {
          width: 4.75,
          height: 14.75,
          desc: "for 5 x 15 photo",
          descFr: "pour photo 5 x 15",
        },
        "3.75x5.75INV": {
          height: 3.75,
          width: 5.75,
          desc: "for 6 x 4 photo",
          descFr: "pour photo 6 x 4",
        },
        "4.75x6.75INV": {
          height: 4.75,
          width: 6.75,
          desc: "for 7 x 5 photo",
          descFr: "pour photo 7 x 5",
        },
        "7.75x9.75": {
          width: 7.75,
          height: 9.75,
          desc: "for 8 x 10 photo",
          descFr: "pour photo 8 x 10",
        },
        "7.8x11.2": {
          height: 11.2,
          width: 7.8,
          desc: "for 8.3 x 11.7 A4 print",
          descFr: "pour impression A4 8,3 x 11,7",
        },
        "7.75x9.75INV": {
          height: 7.75,
          width: 9.75,
          desc: "for 10 x 8 photo",
          descFr: "pour photo 10 x 8",
        },
        "10.75x13.75": {
          width: 10.75,
          height: 13.75,
          desc: "for 11 x 14 photo",
          descFr: "pour photo 11 x 14",
        },
        "11.2x7.8": {
          width: 10.7,
          height: 7.8,
          desc: "for 11.7 x 8.3 A4 print",
          descFr: "pour impression A4 11,7 x 8,3",
        },
        "16x11.2INV": {
          height: 16,
          width: 11.2,
          desc: "for 11.7 x 16.5 A3 Print",
          descFr: "pour impression A3 11,7 x 16,5",
        },
        "11.75x11.75": {
          width: 11.75,
          height: 11.75,
          desc: "for 12 x 12 photo",
          descFr: "pour photo 12 x 12",
        },
        "4.75x14.75INV": {
          height: 4.75,
          width: 14.75,
          desc: "for 15 x 5 photo",
          descFr: "pour photo 15 x 5",
        },

        "10.75x13.75INV": {
          height: 10.75,
          width: 13.75,
          desc: "for 14 x 11 photo",
          descFr: "pour photo 14 x 11",
        },
        "15.75x15.75": {
          width: 15.75,
          height: 15.75,
          desc: "for 16 x 16 photo",
          descFr: "pour photo 16 x 16",
        },
        "15.75x19.75": {
          width: 15.75,
          height: 19.75,
          desc: "for 16 x 20 photo / print",
          descFr: "pour photo 16 x 20",
        },

        "16x11.2": {
          width: 16,
          height: 11.2,
          desc: "for 16.5 x 11.7 A3 Print",
          descFr: "pour impression A3 16,5 x 11,7",
        },


        "22.9x16INV": {
          height: 22.9,
          width: 16,
          desc: "for 16.5 x 23.4 A2 Print",
          descFr: "pour impression A2 16,5 x 23,4",
        },
        "17.75x23.75": {
          width: 17.75,
          height: 23.75,
          desc: "for 18 x 24 photo / print",
          descFr: "pour photo 18 x 24",
        },
        "15.75x19.75INV": {
          height: 15.75,
          width: 19.75,
          desc: "for 20 x 16 photo / print",
          descFr: "pour photo 20 x 16",
        },
        "19.75x23.75": {
          width: 19.75,
          height: 23.75,
          desc: "for 20 x 24 photo / print",
          descFr: "pour photo 20 x 24",
        },


        "17.75x23.75INV": {
          height: 17.75,
          width: 23.75,
          desc: "for 24 x 18 photo / print",
          descFr: "pour photo 24 x 18",
        },

        "22.9x16": {
          width: 22.9,
          height: 16,
          desc: "for 23.4 x 16.5 A2 Print",
          descFr: "pour impression A2  23,4 x 16,5",
        },

        "19.75x23.75INV": {
          height: 19.75,
          width: 23.75,
          desc: "for 24 x 20 photo / print",
          descFr: "pour photo 24 x 20",
        },










      },

      doubleMattingFractionalObject: {
        a: {decimalValue: 0.125, desc: "1/8 of an inch"},
        b: {decimalValue: 0.25, desc: "1/4 of an inch (quarter)"},
        c: {decimalValue: 0.375, desc: "3/8 of an inch"},
        d: {decimalValue: 0.5, desc: "1/2 of an inch (half)"},
        e: {decimalValue: 0.625, desc: "5/8 of an inch"},
        f: {decimalValue: 0.75, desc: "3/4 of an inch"},
        g: {decimalValue: 0.875, desc: "7/8 of an inch"},
        h: {decimalValue: 1, desc: "1 inch"},
        i: {decimalValue: 1.25, desc: "1 and 1/4-inch"},
        j: {decimalValue: 1.5, desc: "1 and 1/2-inch"},
        k: {decimalValue: 1.75, desc: "1 and 3/4-inch"},
        l: {decimalValue: 2, desc: "2-inch"},
      },

      doubleMattingPopularObject: {
        b: {decimalValue: 0.25, desc: "1/4 of an inch (quarter)"},
        d: {decimalValue: 0.5, desc: "1/2 of an inch (half)"},
        h: {decimalValue: 1, desc: "1 inch"},
      },

      //device shall be either mobile, tablet, or web
      frame: {
        //if the orientation changes, the whole width and height needs to be flipped.
        width: 20,
        height: 16,
        renderingStandardLength: 20,
        //rendering standard length refers to longer of the width and height
        type: "black",
        unit: "in",

        fractionWidthValueWholeNumber: 20,
        fractionHeightValueWholeNumber: 16,
        fractionWidthValueSelected: "1/4",
        fractionHeightValueSelected: "1/4",
      },
      multipleWindowWidth: 6,
      multipleWindowHeight: 6,
      windowFractionWidthValueSelected: "None",
      windowFractionHeightValueSelected: "None",
      windowFractionWidthValueWholeNumber: 6,
      windowFractionHeightValueWholeNumber: 6,
      window: {
        rowCount: 1,
        columnCount: 1,
        window1: {
          width: 6,
          height: 6,
          marginTop: (16-6)/2,
          marginBottom: (16-6)/2,
          marginLeft: (20-6)/2,
          marginRight: (20-6)/2,
          showModal: false,
        },
      },

      //secondary matting
      secondMatPrice: 5.95,
      secondMatColour: "none",
      secondMatWidth: 0.00,

      //drawing tool
      drawingPadModalShowing: false,
      drawingPadShowing: false,
      showDrawingPadExamplesModal: false,

      canvasDrawingInProgress: false,
      additionalNoteContent: "",
      canvasDrawing: null,
      backingMatboard: false,
      isWindowOval: false,
      french: false,
      addWriting: false,
      addLogo: false,
    };

    this.addWindowMultiple = this.addWindowMultiple.bind(this);
    this.updateWindowRendering = this.updateWindowRendering.bind(this);
    this.updateComponentStateCallBack = this.updateComponentStateCallBack.bind(this);
    this.moveWindowCallBack = this.moveWindowCallBack.bind(this);
    this.updateWindowModalState = this.updateWindowModalState.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.rotateFrame = this.rotateFrame.bind(this);
    this.polaroidWindowRendering = this.polaroidWindowRendering.bind(this);
    this.setStateFrameRendering = this.setStateFrameRendering.bind(this);
    this.fetchMatboardColours = this.fetchMatboardColours.bind(this);
    this.getSecondMatPricing = this.getSecondMatPricing.bind(this);
    this.parentSetStateCallBack = this.parentSetStateCallBack.bind(this);
    this.setPictureFrameSizeFraction = this.setPictureFrameSizeFraction.bind(this);
    this.setFrameStandardSizeRendering = this.setFrameStandardSizeRendering.bind(this);
    this.setWindowSizeFraction = this.setWindowSizeFraction.bind(this);
    this.fetchLanguage = this.fetchLanguage.bind(this);
    this.clickAddSampleButton = this.clickAddSampleButton.bind(this);
  };

  componentDidMount() {

    checkLatestVersion();


    //listens to the viewport that user comes in with.
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.loadInfoFromURLQuery();


    var discountFactor = this.props.getDiscountMultiplier() ? this.props.getDiscountMultiplier() : 1;
    var secondMatPrice = 5.95;
    this.setState({secondMatPrice: secondMatPrice});

    if (window.innerWidth <= 767) {
      this.setState({showAddNoteModal: true});
    }

    if (this.props.workflow) {
      this.assignStateForWorkflow();
    } else {
      //for regular select mat section
      //determine if this is for the polaroid frame;
      if (this.props.designFor === "polaroidFrame") {
        //when the page gets loaded, it will assign a window size based on the picture frame size.
        var frameWidth = productDB[this.props.designFor].frameLists[this.state.userSelectedFrameType].sizeInventory[this.state.userSelectedFrameSize].width;
        var frameHeight = productDB[this.props.designFor].frameLists[this.state.userSelectedFrameType].sizeInventory[this.state.userSelectedFrameSize].height;
        this.setStateFrameRendering(frameWidth, frameHeight);
        this.polaroidWindowRendering(frameWidth, frameHeight);
        this.setState({selectedPolaroidType: localStorageFunctions("selectedFilmSize", null)});
        this.updateWindowDimensions();
        this.updateFrameTypeAndPrice();
        this.setState({showMultipleWindowSelectionModal: true});
      } else if (this.props.designFor === "matboardOnly") {
        //this is for the matboard only module.
        //need to pop up a module to ask how big is the picture frame.
        //did this already
      } else if (this.props.designFor === "frame") {
        // just the frame, nothing much. QA needed though
        frameWidth = productDB[this.props.designFor].frameLists[this.state.userSelectedFrameType].sizeInventory[this.state.userSelectedFrameSize].width;
        frameHeight = productDB[this.props.designFor].frameLists[this.state.userSelectedFrameType].sizeInventory[this.state.userSelectedFrameSize].height;
        this.setStateFrameRendering(frameWidth, frameHeight);
        frameWidth = productDB[this.props.designFor].frameLists[this.state.userSelectedFrameType].sizeInventory[this.state.userSelectedFrameSize].width;
        this.updateWindowDimensions();
        this.updateFrameTypeAndPrice();
      };


    }

    this.setState({
      french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
    })

  };

  loadInfoFromURLQuery() {

    const urlSearchParams = new URLSearchParams(window.location.search)

    const params = Object.fromEntries(urlSearchParams.entries());

    var centered;

    if (params.ctr === "true") {
      centered = true;
    } else if (params.ctr === "false") {
      centered = false;
    } else {
      centered = undefined;
    }

    if (centered === true) {
      this.setState(
          {
            matColour: params.mColor,
            orderQuantity: params.orderQuantity,

            frame: {

              width: parseFloat(params.mWidth),
              height: parseFloat(params.mHeight),
              renderingStandardLength: (+parseFloat(params.mWidth) > +parseFloat(params.mHeight)) ? parseFloat(params.mWidth) : parseFloat(params.mHeight),
              //rendering standard length refers to longer of the width and height
              type: "black",
              unit: "in",
              fractionWidthValueWholeNumber: 20,
              fractionHeightValueWholeNumber: 16,
              fractionWidthValueSelected: "1/4",
              fractionHeightValueSelected: "1/4",
            },
            window: {
              rowCount: 1,
              columnCount: 1,
              window1: {
                width: parseFloat(params.wWidth),
                height: parseFloat(params.wHeight),
                marginTop: (parseFloat(params.mHeight) - parseFloat(params.wHeight)) / 2,
                marginBottom: (parseFloat(params.mHeight) - parseFloat(params.wHeight)) / 2,
                marginLeft: (parseFloat(params.mWidth) - parseFloat(params.wWidth)) / 2,
                marginRight:(parseFloat(params.mWidth) - parseFloat(params.wWidth)) / 2,
                showModal: false,
              },
            },
            multipleWindowWidth: parseFloat(params.wWidth),
            multipleWindowHeight: parseFloat(params.wHeight),

            //secondary matting
            secondMatPrice: 5.95,
            secondMatColour: params.dmColor,
            secondMatWidth: parseFloat(params.dmWidth),

            //drawing tool
            drawingPadModalShowing: false,
            drawingPadShowing: false,
            showDrawingPadExamplesModal: false,

            canvasDrawingInProgress: false,
            additionalNoteContent: "",
            canvasDrawing: null,
            backingMatboard: params.backing === "true" ? true : false,
            isWindowOval: params.oval === "true" ? true : false,
            french: false,
            addWriting: false,
            addLogo: false,
          }, () => this.getSecondMatPricing()
      )
    }

    else if (centered === false) {
      this.setState(
          {
            matColour: params.mColor,
            orderQuantity: params.orderQuantity,


              frame: {
              // //if the orientation changes, the whole width and height needs to be flipped.
              // width: parseFloat(params.frameWidth),
              // height: parseFloat(params.frameHeight),
              // renderingStandardLength: (+parseFloat(params.frameWidth) > +parseFloat(params.frameHeight)) ? parseFloat(params.frameWidth) : parseFloat(params.frameHeight),
              // //rendering standard length refers to longer of the width and height
              // type: "black",
              // unit: "in",
              // fractionWidthValueWholeNumber: 20,
              // fractionHeightValueWholeNumber: 16,
              // fractionWidthValueSelected: "1/4",
              // fractionHeightValueSelected: "1/4",

              width: parseFloat(params.mWidth),
              height: parseFloat(params.mHeight),
              renderingStandardLength: (+parseFloat(params.mWidth) > +parseFloat(params.mHeight)) ? parseFloat(params.mWidth) : parseFloat(params.mHeight),
              //rendering standard length refers to longer of the width and height
              type: "black",
              unit: "in",
              fractionWidthValueWholeNumber: 20,
              fractionHeightValueWholeNumber: 16,
              fractionWidthValueSelected: "1/4",
              fractionHeightValueSelected: "1/4",
            },
            window: {
              rowCount: 1,
              columnCount: 1,
              window1: {
                width: parseFloat(params.wWidth),
                height: parseFloat(params.wHeight),
                marginTop: parseFloat(params.mTop),
                marginBottom: parseFloat(params.mBottom),
                marginLeft: parseFloat(params.mLeft),
                marginRight:parseFloat(params.mRight),
                showModal: false,
              },
            },
            multipleWindowWidth: parseFloat(params.wWidth),
            multipleWindowHeight: parseFloat(params.wHeight),

            //secondary matting
            secondMatPrice: 5.95,
            secondMatColour: params.dmColor,
            secondMatWidth: parseFloat(params.dmWidth),

            //drawing tool
            drawingPadModalShowing: false,
            drawingPadShowing: false,
            showDrawingPadExamplesModal: false,

            canvasDrawingInProgress: false,
            additionalNoteContent: "",
            canvasDrawing: null,
            backingMatboard: params.backing === "true" ? true : false,
            isWindowOval: params.oval === "true" ? true : false,
            french: false,
            addWriting: false,
            addLogo: false,
          }, () => this.getSecondMatPricing()
      )};
  }

  assignStateForWorkflow() {
    this.setState({
      selectedFrame: "",
      selectedFrameSize: this.props.frameSize,
      windowCount: this.props.window.rowCount * this.props.window.columnCount,
      matColour: this.props.matColour,
      multipleWindow: (this.props.window.rowCount * this.props.window.columnCount !== 1) ? true : false,
      userSelectedFrameType: this.props.frameType,
      userSelectedFrameSize: this.props.frameSize,
      renderingToolWidth: 480, //60vw // 90vw
      renderingToolHeight: 480, // 75vh
      deviceType: "web",
      frame: {
        //if the orientation changes, the whole width and height needs to be flipped.
        width: this.props.frameWidth,
        height: this.props.frameHeight,
        renderingStandardLength: (+this.props.frameWidth > +this.props.frameHeight) ? this.props.frameWidth : this.props.frameHeight,
        type: "black",
        unit: "in",
      },
      window: this.props.window,
      secondMatPrice: this.props.secondMatPrice,
      secondMatColour: this.props.secondMatColour,
      secondMatWidth: parseFloat(this.props.secondMatWidth),
    });
  };



  getSecondMatPricing() {
    var frameSize = this.state.frame.width * this.state.frame.height;
    var secondMatPricing = 0.00;
    // var discountFactor = this.props.getDiscountMultiplier() ? this.props.getDiscountMultiplier() : 1;

    if (frameSize <= productDB.matboardOnlyPricing.xSmall.width * productDB.matboardOnlyPricing.xSmall.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.xSmall.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.small1.width * productDB.matboardOnlyPricing.small1.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.small1.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.small2.width * productDB.matboardOnlyPricing.small2.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.small2.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.medium1.width * productDB.matboardOnlyPricing.medium1.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.medium1.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.medium2.width * productDB.matboardOnlyPricing.medium2.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.medium2.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.medium3.width * productDB.matboardOnlyPricing.medium3.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.medium3.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.large1.width * productDB.matboardOnlyPricing.large1.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.large1.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.large2.width * productDB.matboardOnlyPricing.large2.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.large2.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.xlarge1.width * productDB.matboardOnlyPricing.xlarge1.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.xlarge1.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.xlarge2.width * productDB.matboardOnlyPricing.xlarge2.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.xlarge2.secondaryMat;
    } else if (frameSize <= productDB.matboardOnlyPricing.xlarge3.width * productDB.matboardOnlyPricing.xlarge3.height) {
      secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.xlarge3.secondaryMat;
    }




    //
    // else if (frameSize <= productDB.matboardOnlyPricing.small.width * productDB.matboardOnlyPricing.small.height) {
    //   secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.small.secondaryMat;
    // } else if (frameSize <= productDB.matboardOnlyPricing.mediumSmall.width * productDB.matboardOnlyPricing.mediumSmall.height) {
    //   secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.mediumSmall.secondaryMat;
    // } else if (frameSize <= productDB.matboardOnlyPricing.medium.width * productDB.matboardOnlyPricing.medium.height) {
    //   secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.medium.secondaryMat;
    // } else if (frameSize <= productDB.matboardOnlyPricing.large.width * productDB.matboardOnlyPricing.large.height) {
    //   secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.large.secondaryMat;
    // } else if (frameSize <= productDB.matboardOnlyPricing.xLarge.width * productDB.matboardOnlyPricing.xLarge.height) {
    //   secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.xLarge.secondaryMat;
    // }

    this.setState({secondMatPrice: secondMatPricing})
  };

  updateFrameTypeAndPrice() {
    var userSelectedFrameType = localStorageFunctions("selectedFrameType", null);
    this.setState({
      selectedFrame: userSelectedFrameType,
      price: productDB[this.props.designFor].frameLists[userSelectedFrameType].sizeInventory[this.state.userSelectedFrameSize].price,
    })
  };

  determineFrameColour(colourCode) {
    if (colourCode === "simplyWhite" || colourCode === "classicWhite" || colourCode === "elegantWhite" || colourCode === "ornamentalWhite") {
      return whiteFrame
    } else if (colourCode === "simplyBlack" || colourCode === "classicBlack" || colourCode === "plushBlack") {
      return blackFrame
    } else if (colourCode === "sparklingGold" || colourCode === "metallicGold" || colourCode === "vintageGold" || colourCode === "goldStandard") {
      return goldFrame
    } else if (colourCode === "sparklingSilver" || colourCode === "metallicSilver" || colourCode === "vintageSilver") {
      return silverFrame
    } else if (colourCode === "patternedAspen") {
      return aspenFrame
    } else {
      return walnutFrame
    }
  };

  polaroidWindowRendering(frameWidth, frameHeight) {
    var selectedFilmSize = localStorageFunctions("selectedFilmSize", null);
    this.setState({
      windowCount: productDB.recommendedPolaroidLayouts[selectedFilmSize][`${frameWidth}x${frameHeight}`].columnCount * productDB.recommendedPolaroidLayouts[selectedFilmSize][`${frameWidth}x${frameHeight}`].rowCount,
      window: productDB.recommendedPolaroidLayouts[selectedFilmSize][`${frameWidth}x${frameHeight}`],
    });

    //setting up multiple window width and height for the case of polaroid
    var multipleWindowWidth, multipleWindowHeight;

    if (selectedFilmSize === "fujiInstaxMini") {
      multipleWindowWidth = 2;
      multipleWindowHeight = 3;
    } else if (selectedFilmSize === "fujiInstaxWide") {
      multipleWindowWidth = 4;
      multipleWindowHeight = 3;
    } else if (selectedFilmSize === "polaroidRegular") {
      multipleWindowWidth = 3.25;
      multipleWindowHeight = 3.75;
    } else if (selectedFilmSize === "polaroidWide") {
      multipleWindowWidth = 3.75;
      multipleWindowHeight = 4;
    }

    this.setState({
      multipleWindowWidth: multipleWindowWidth,
      multipleWindowHeight: multipleWindowHeight,
    });

  };

  setPictureFrameSizeFraction(fractionWidthValueWholeNumber, fractionWidthValueSelected, fractionHeightValueWholeNumber, fractionHeightValueSelected) {

    var combinedWidth = parseFloat(fractionWidthValueWholeNumber) + this.state.fractionalObject[fractionWidthValueSelected];
    var combinedHeight = parseFloat(fractionHeightValueWholeNumber) + this.state.fractionalObject[fractionHeightValueSelected];

    this.setState({
      frame: {
        //if the orientation changes, the whole width and height needs to be flipped.
        width: combinedWidth,
        height: combinedHeight,
        renderingStandardLength: (+combinedWidth > +combinedHeight) ? combinedWidth : combinedHeight,
        //rendering standard length refers to longer of the width and height
        type: "black",
        unit: "in",
        fractionWidthValueWholeNumber: parseFloat(fractionWidthValueWholeNumber),
        fractionHeightValueWholeNumber: parseFloat(fractionHeightValueWholeNumber),
        fractionWidthValueSelected: fractionWidthValueSelected,
        fractionHeightValueSelected: fractionHeightValueSelected,
      },
      multipleWindowWidth: Math.round(combinedWidth * 0.3),
      multipleWindowHeight: Math.round(combinedHeight * 0.3),
      window: {
        rowCount: 1,
        columnCount: 1,
        window1: {
          width: Math.round(combinedWidth * 0.3),
          height: Math.round(combinedHeight * 0.3),
          marginTop: (combinedHeight - Math.round(combinedHeight * 0.3)) / 2,
          marginBottom: (combinedHeight - Math.round(combinedHeight * 0.3)) / 2,
          marginLeft: (combinedWidth - Math.round(combinedWidth * 0.3)) / 2,
          marginRight: (combinedWidth - Math.round(combinedWidth * 0.3)) / 2,
          showModal: false,
          showBackgroundImageModal: false,
        },
      }
    })


  }

  setWindowSizeFraction(fractionWidthValueWholeNumber, fractionWidthValueSelected, fractionHeightValueWholeNumber, fractionHeightValueSelected) {

    var combinedWidth = parseFloat(fractionWidthValueWholeNumber) + this.state.fractionalObject[fractionWidthValueSelected];
    var combinedHeight = parseFloat(fractionHeightValueWholeNumber) + this.state.fractionalObject[fractionHeightValueSelected];


    this.setState({
      multipleWindowWidth: combinedWidth,
      multipleWindowHeight: combinedHeight,
      windowFractionWidthValueSelected: fractionWidthValueSelected,
      windowFractionHeightValueSelected: fractionHeightValueSelected,
      windowFractionWidthValueWholeNumber: fractionWidthValueWholeNumber,
      windowFractionHeightValueWholeNumber: fractionHeightValueWholeNumber,
      window: {
        rowCount: 1,
        columnCount: 1,
        window1: {
          width: combinedWidth,
          height: combinedHeight,
          marginTop: (this.state.frame.height - combinedHeight) / 2,
          marginBottom: (this.state.frame.height - combinedHeight) / 2,
          marginLeft: (this.state.frame.width - combinedWidth) / 2,
          marginRight: (this.state.frame.width - combinedWidth) / 2,
          showModal: false,
          showBackgroundImageModal: false,
        },
      }
    })
  }

  setStateFrameRendering(frameWidth, frameHeight) {
    this.setState({
      frame: {
        //if the orientation changes, the whole width and height needs to be flipped.
        width: frameWidth,
        height: frameHeight,
        renderingStandardLength: (+frameWidth > +frameHeight) ? frameWidth : frameHeight,
        //rendering standard length refers to longer of the width and height
        type: "black",
        unit: "in",
        fractionWidthValueWholeNumber: frameWidth,
        fractionHeightValueWholeNumber: frameHeight,
        fractionWidthValueSelected: "1/4",
        fractionHeightValueSelected: "1/4",
      },
      multipleWindowWidth: Math.round(frameWidth * 0.3),
      multipleWindowHeight: Math.round(frameHeight * 0.3),
      window: {
        rowCount: 1,
        columnCount: 1,
        window1: {
          width: Math.round(frameWidth * 0.3),
          height: Math.round(frameHeight * 0.3),
          marginTop: (frameHeight - Math.round(frameHeight * 0.3)) / 2,
          marginBottom: (frameHeight - Math.round(frameHeight * 0.3)) / 2,
          marginLeft: (frameWidth - Math.round(frameWidth * 0.3)) / 2,
          marginRight: (frameWidth - Math.round(frameWidth * 0.3)) / 2,
          showModal: false,
          showBackgroundImageModal: false,
        },
      }
    }, () => this.getSecondMatPricing());
  };

  setFrameStandardSizeRendering(e) {

    var frameWidth = this.state.standardMatSizes[e.target.value].width;
    var frameHeight =  this.state.standardMatSizes[e.target.value].height;

    this.setState({
      frame: {
        //if the orientation changes, the whole width and height needs to be flipped.
        width: frameWidth,
        height: frameHeight,
        renderingStandardLength: (+frameWidth > +frameHeight) ? frameWidth : frameHeight,
        //rendering standard length refers to longer of the width and height
        type: "black",
        unit: "in",
        fractionWidthValueWholeNumber: frameWidth,
        fractionHeightValueWholeNumber: frameHeight,
        fractionWidthValueSelected: "1/4",
        fractionHeightValueSelected: "1/4",
        selectedStandardMatboardSize: e.target.value,
      },
      multipleWindowWidth: Math.round(frameWidth * 0.3),
      multipleWindowHeight: Math.round(frameHeight * 0.3),
      window: {
        rowCount: 1,
        columnCount: 1,
        window1: {
          width: Math.round(frameWidth * 0.3),
          height: Math.round(frameHeight * 0.3),
          marginTop: (frameHeight - Math.round(frameHeight * 0.3)) / 2,
          marginBottom: (frameHeight - Math.round(frameHeight * 0.3)) / 2,
          marginLeft: (frameWidth - Math.round(frameWidth * 0.3)) / 2,
          marginRight: (frameWidth - Math.round(frameWidth * 0.3)) / 2,
          showModal: false,
          showBackgroundImageModal: false,
        },
      }
    }, () => this.getSecondMatPricing());
  }

  rotateFrame(newFrameWidth, newFrameHeight) {
    this.setStateFrameRendering(newFrameWidth, newFrameHeight);
  };

  updateWindowDimensions() {
    if (window.innerWidth >= 767 && window.innerWidth <= 1039) {
      //if tablet
      this.setState({
        renderingToolWidth: 480, //60vw
        deviceType: "tablet",
      })
    } else if (window.innerWidth <= 767) {
      //if mobile
      this.setState({
        renderingToolWidth: 480,
        deviceType: "mobile",
      })
    } else {
      this.setState({
        renderingToolWidth: 480, //480px
        deviceType: "web",
      })
    }
  };

  generateProductURL() {


    var mWidth = this.state.frame.width;
    var mHeight = this.state.frame.height;
    var wWidth = this.state.window.window1.width;
    var WHeight = this.state.window.window1.height;
    var mTop = this.state.window.window1.marginTop;
    var mBottom = this.state.window.window1.marginBottom;
    var mLeft = this.state.window.window1.marginLeft;
    var mRight = this.state.window.window1.marginRight;
    var mColor = this.state.matColour;
    var dmColor = this.state.secondMatColour;
    var dmWidth = this.state.secondMatWidth;
    var backing = this.state.backingMatboard;
    var oval = this.state.isWindowOval;
    var orderQuantity = this.state.orderQuantity;
    var ctr

    if (mTop === mBottom && mLeft === mRight) {
      ctr = true;
    } else {
      ctr = false;
    }

    var url = `customize-matboards?mWidth=${mWidth}&mHeight=${mHeight}&wWidth=${wWidth}&wHeight=${WHeight}&mTop=${mTop}&mLeft=${mLeft}&mRight=${mRight}&mBottom=${mBottom}&mColor=${mColor}&dmColor=${dmColor}&dmWidth=${dmWidth}&backing=${backing}&oval=${oval}&ctr=${ctr}&orderQuantity=${orderQuantity}`
    window.open(url,'_blank');

  };

  updateWindowModalState(modalType, windowKey) {
    var stateToBeUpdated = {...this.state.window};
    var windowId = `window${windowKey + 1}`;
    stateToBeUpdated[windowId][modalType] = !this.state.window[windowId][modalType];
    this.updateComponentStateCallBack(window, stateToBeUpdated);
  };

  updateComponentStateCallBack(dictKey, dictValue) {
    this.setState({[dictKey]: dictValue});
  };

  moveWindowCallBack(direction, windowId, windowCssRenderingValues, changedMattingNewValue, frame) {
    var stateToBeUpdated = {...this.state.window};
    windowId = `window${windowId + 1}`;
    this.updateComponentStateCallBack(window, updateWindowFunctions(stateToBeUpdated, this.state.window, direction, windowId, changedMattingNewValue, windowCssRenderingValues, frame));
  };

  updateWindowRendering(windowId, dictKey, dictValue, rotationDegrees) {
    //the number comes as a form of string. Extracting the value. Using float in case it comes in decimals
    dictValue = (dictKey === "backgroundImage") ? dictValue : parseFloat(dictValue);
    let multipleWindow = (this.state.window.columnCount > 1) || (this.state.window.rowCount) > 1 ? true : false;
    this.setState({window: updateWindowSizeCallBack(this.state.window, this.state.frame, this.state.renderingToolHeight, windowId, dictKey, dictValue, multipleWindow, rotationDegrees)});
  };

  parentSetStateCallBack(dictKey, dictValue) {

    //this functionality has been temporarily disabled.
    if (dictKey === "windowCount") {
      //this functionality exists to update the window count when "manually add window (non-grid) option was selected".
      //for window count value update.
      this.addWindowMultiple("rowColumn", windowCountUpdate(dictValue)[0], windowCountUpdate(dictValue)[1]);
    }  else if (dictKey === "multipleWindowWidth" || dictKey === "multipleWindowHeight") {
      var frameCss = frameCssRendering(this.state.frame.width, this.state.frame.height, this.state.frame.renderingStandardLength, this.state.renderingToolWidth, this.state.renderingToolHeight, this.determineFrameColour(this.state.selectedFrame));
      this.setState({
        [dictKey]: dictValue,
      }, () => {
        this.setState({
          window: addMultipleWindowsClicked(this.state.window.rowCount, this.state.window.columnCount, this.state.frame.width, this.state.frame.height, this.state.renderingToolWidth, this.state.renderingToolHeight, frameCss.height, true, this.state.multipleWindowWidth, this.state.multipleWindowHeight)
        })
      })
    } else {
        // console.log(dictKey, dictValue)
      //for order quantity and etc.
      this.setState({[dictKey]: dictValue}, () =>  {
        //for the case that user added a window manually
        if (dictKey === "window") {
          this.setState({windowCount: this.state.windowCount + 1});
        }
      });
    }
  };

    reduceQuantity() {
        if (this.state.orderQuantity > 1) {
            this.setState({orderQuantity: this.state.orderQuantity - 1})
        } else {
            alert("Order quantity cannot be less than 1.")
        }

    };

  addWindowMultiple(field, value, value2) {
    //updates the visual rendering of the window
    //before starting anything, make sure that the neither value or value 2 values cannot be NaN or 0. If that happens, there shall be no ation
    //parse Int since it cannot be in decimals
    value = parseInt(value);
    value2 = parseInt(value2);

    var multipleWindow;
    if (this.state.window.columnCount * this.state.window.rowCount === 1) {
      multipleWindow = false;
    } else if (this.state.window.columnCount * this.state.window.rowCount > 1) {
      multipleWindow = true;
    } else {
      //do nothing
    }

    var frameCss = frameCssRendering(this.state.frame.width, this.state.frame.height, this.state.frame.renderingStandardLength, this.state.renderingToolWidth, this.state.renderingToolHeight, this.determineFrameColour(this.state.selectedFrame));

    if (value === 0 || value2 === 0) {
      this.setState({window: addMultipleWindowsClicked(value, this.state.window.columnCount, this.state.frame.width, this.state.frame.height, this.state.renderingToolWidth, this.state.renderingToolHeight, frameCss.height, multipleWindow, this.state.multipleWindowWidth, this.state.multipleWindowHeight)},
        () => this.updateComponentStateCallBack("windowCount", ""));
    } else if (field === "row") {
      this.setState({window: addMultipleWindowsClicked(value, this.state.window.columnCount, this.state.frame.width, this.state.frame.height, this.state.renderingToolWidth, this.state.renderingToolHeight, frameCss.height, multipleWindow, this.state.multipleWindowWidth, this.state.multipleWindowHeight)},
        () => this.updateComponentStateCallBack("windowCount", this.state.window.rowCount * this.state.window.columnCount));
    } else if (field === "column") {
      this.setState({window: addMultipleWindowsClicked(this.state.window.rowCount, value, this.state.frame.width, this.state.frame.height, this.state.renderingToolWidth, this.state.renderingToolHeight, frameCss.height, multipleWindow, this.state.multipleWindowWidth, this.state.multipleWindowHeight)},
        () => this.updateComponentStateCallBack("windowCount", this.state.window.rowCount * this.state.window.columnCount));
    } else if (field === "rowColumn") {
      this.setState({window: addMultipleWindowsClicked(value, value2, this.state.frame.width, this.state.frame.height, this.state.renderingToolWidth, this.state.renderingToolHeight, frameCss.height, multipleWindow, this.state.multipleWindowWidth, this.state.multipleWindowHeight)},
        () => this.updateComponentStateCallBack("windowCount", this.state.window.rowCount * this.state.window.columnCount));
    }

    if (field === "row" || field === "column") {
      //if value has been entered for any of the window matrix, then we will change the state so that the CSS will be locked down, visible to users.
      this.updateComponentStateCallBack("valueEntered", true);
    }
  };

  addProductToLocalStorageCart(item) {
    //check if a shopper has already shopped something on before this item
    var shoppingCart = [];

    if (localStorageFunctions("shoppingCart", null) !== null ) {
      //this part will have to be parse json

      shoppingCart = JSON.parse(localStorageFunctions("shoppingCart", null));

      shoppingCart.push(item);
    } else {
      shoppingCart.push(item);
    };

    localStorageFunctions("shoppingCart", JSON.stringify(shoppingCart));
  };

  matboardOnlyPricingCalculator(frameWidth, frameHeight, priceOrShippingCost, secondMatPrice, secondMatColour, secondMatWidth) {
    frameWidth = parseFloat(frameWidth);
    frameHeight = parseFloat(frameHeight);


    if (frameWidth * frameHeight <= (10.1 * 10.1)) {
      //small enough parcel that fits into Canadian legal mailing
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.xSmall.price : productDB.matboardOnlyPricing.xSmall.shippingCosts;
    } else if (frameWidth * frameHeight <= (14.1 * 11.1)) {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.small1.price : productDB.matboardOnlyPricing.small1.shippingCosts;
    } else if (frameWidth * frameHeight <= (16.1 * 12.1)) {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.small2.price : productDB.matboardOnlyPricing.small2.shippingCosts;
    } else if (frameWidth * frameHeight <= (14.1 * 18.1)) {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.medium1.price : productDB.matboardOnlyPricing.medium1.shippingCosts;
    } else if (frameWidth * frameHeight <= (16.1 * 16.1)) {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.medium2.price : productDB.matboardOnlyPricing.medium2.shippingCosts;
    } else if (frameWidth * frameHeight <= (16.1 * 20.1)) {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.medium3.price : productDB.matboardOnlyPricing.medium3.shippingCosts;
    } else if (frameWidth * frameHeight <= (18.1 * 24.1)) {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.large1.price : productDB.matboardOnlyPricing.large1.shippingCosts;
    } else if (frameWidth * frameHeight <= (20.1 * 28.1)) {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.large2.price  : productDB.matboardOnlyPricing.large2.shippingCosts;
    } else if (frameWidth * frameHeight <= (24.1 * 30.1)) {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.xlarge1.price  : productDB.matboardOnlyPricing.xlarge1.shippingCosts;
    } else if (frameWidth * frameHeight <= (24.1 * 36.1)) {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.xlarge2.price  : productDB.matboardOnlyPricing.xlarge2.shippingCosts;
    } else {
      //canadapost or UPS
      return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.xlarge3.price  : productDB.matboardOnlyPricing.xlarge3.shippingCosts;
    }
  };

  submitToGTag() {
      window.gtag('event', 'add_to_cart', {
          'send_to': 'AW-732793253/RjgUCLrI2qcBEKWTtt0C',
          'value': parseFloat(this.state.price),
          'currency': "CAD",
          'transaction_id': ''
      })
  }

  clickAddToCartButton() {
    // console.log("www.custommat.ca/customize-matboards?"+"frameWidth="+this.state.frame.width+"&frameHeight="+this.state.frame.height+"&windowWidth="+this.state.multipleWindowWidth+"&windowHeight="+this.state.multipleWindowHeight+"&mTop="+this.state.window.window1.marginTop+"&mBottom="+this.state.window.window1.marginBottom+"&mLeft="+this.state.window.window1.marginLeft+"&mRight="+this.state.window.window1.marginRight)

    this.submitToGTag();


    var selectedFrame = localStorageFunctions("selectedFrameType", null);
    var item;

    if (this.props.designFor === "matboardOnly") {
      item = {
        prodType: this.props.designFor,
        frameType: "Not Applicable -- Matboard Only",
        frameSize: "Not Applicable -- Matboard Only",
        frameWidth: this.state.frame.width,
        frameHeight: this.state.frame.height,
        matColour: this.state.matColour,
        window: this.state.window,
        secondMatPrice: this.state.secondMatPrice,
        secondMatColour: this.state.secondMatColour,
        secondMatWidth: parseFloat(this.state.secondMatWidth),
        orderQuantity: parseFloat(this.state.orderQuantity),
        // //for matboard price, we will have to run a pricing mechanism for matboard pricing. We might borrow this from our legacy system.
        price: parseFloat(this.matboardOnlyPricingCalculator(this.state.frame.width, this.state.frame.height, "price", (this.state.secondMatPrice * this.props.getDiscountMultiplier()), this.state.secondMatColour, parseFloat(this.state.secondMatWidth))),
        shipping: [0, 0],
        canvasDrawing: this.state.canvasDrawing,
        additionalNoteContent: this.state.additionalNoteContent,
        backingMatboard: this.state.backingMatboard,
        isWindowOval: this.state.isWindowOval,
      };
    } else {
       item = {
        prodType: this.props.designFor,
        frameType: this.state.userSelectedFrameType,
        frameSize: this.state.userSelectedFrameSize,
        frameWidth: this.state.frame.width,
        frameHeight: this.state.frame.height,
        matColour: this.state.matColour,
        window: this.state.window,
        secondMatPrice: this.state.secondMatPrice,
        secondMatColour: this.state.secondMatColour,
        secondMatWidth: parseFloat(this.state.secondMatWidth),
        orderQuantity: parseFloat(this.state.orderQuantity),
        price: parseFloat(this.state.price),
        shipping:[
          productDB[this.props.designFor].frameLists[selectedFrame].sizeInventory[this.state.userSelectedFrameSize].shippingCanada,
          productDB[this.props.designFor].frameLists[selectedFrame].sizeInventory[this.state.userSelectedFrameSize].shippingUSA,
        ],
         canvasDrawing: this.state.canvasDrawing,
         additionalNoteContent: this.state.additionalNoteContent,
         isWindowOval: this.state.isWindowOval,
         //no backing matboard for the frames.
      };
    };



    this.addProductToLocalStorageCart(item);
    this.props.showShoppingCartModal("open");
  };

  clickAddSampleButton() {

    var item = {
      prodType: "sampleRequest",
      frameType: "Not Applicable -- Matboard Sample Request",
      frameSize: "Not Applicable -- Matboard Sample Request",
      frameWidth: "Not Applicable -- Matboard Sample Request",
      frameHeight: "Not Applicable -- Matboard Sample Request",
      matColour: this.state.matColour,
      window: this.state.window,
      secondMatPrice: this.state.secondMatPrice,
      secondMatColour: this.state.secondMatColour,
      secondMatWidth: parseFloat(this.state.secondMatWidth),
      orderQuantity: this.state.orderQuantity,
      // //for matboard price, we will have to run a pricing mechanism for matboard pricing. We might borrow this from our legacy system.
      price: parseFloat(0.00),
      shipping: this.matboardOnlyPricingCalculator(this.state.frame.width, this.state.frame.height, "shipping"),
    };

    this.addProductToLocalStorageCart(item);
    this.props.showShoppingCartModal("open");

  };

  displayCheckoutButton() {
      return <div id="order-checkout-button" onClick={() => {this.clickAddToCartButton()}}>
          <div id={"order-checkout-button-inner-layer"}>{this.fetchLanguage("Ajouter au Sac", "Add to Cart")}</div>
      </div>
  };

  displayQuestionsSection() {
    return <div>
      <Tooltip title={"Do you have any questions? Chat with us."} placement={"top"}>
        <img className={`live-help-button`} src={helpIcon} alt={"Click here to upload your art."} onClick={() => {this.setState({liveChatModalOpen: true})}}/>
      </Tooltip>
      <div className={this.state.liveChatModalOpen ? "live-help-contact-box" : "no-display"}>
        <span style={{"fontWeight": "600"}}>Got a question? Please chat with us:</span><br/>
        1. Email <a href={"mailto:hello@CustomMat.ca"}>hello@CustomMat.ca</a>. <br/>We respond within 60 minutes.<br/>
        2. Call us at <a href={"tel:226-828-1705"}>226-828-1705</a>.<br/>(Monday - Sunday 8AM - 8PM PT) <br/><br/>
        * Our matboards do not come with backing boards. If you need them, please email us.<br/>
        **If you have an advanced design, please email us with your drawing (hand drawn works well as well). It's same price as regular design.
        <div className={"live-help-contact-box-close"} onClick={() => {this.setState({liveChatModalOpen: !this.state.liveChatModalOpen})}}>Close</div>
      </div>
    </div>
  };

  fetchMatboardColours(primaryOrSecondaryMat) {
    var matboardList = Object.keys(productDB.matboards);

    return matboardList.map((mat, key) => {
      return <div className={productDB.matboards[mat].active ? "matboard-selected-colour-container" : "no-display"} key={`matboard-selected-colour-container-${key}`}>
        <Tooltip title={productDB.matboards[mat].color} placement={"top"}>
          <img alt={`Selected matboard colour is -- ${productDB.matboards[mat].color}`}
               className={ mat === this.state[primaryOrSecondaryMat] ? "matboard-colour matboard-colour-selected" : "matboard-colour"}
               src={productDB.matboards[mat].img}
               onClick={() => {this.parentSetStateCallBack(primaryOrSecondaryMat, mat)}}/>
        </Tooltip>
        {/*<div className={mat === this.state[primaryOrSecondaryMat] ? "matboard-selector-checkmark-container" : "no-display"}>*/}
        {/*  <img src={checkMark} alt={`This is a checkmark indicating ${productDB.matboards[mat].color} has been selected.`}/>*/}

      </div>
    })
  };

  showAddNoteModal() {
    return <div className={"add-matboard-note-section"}>

      <div className={"select-mat-row"}>
        <img className={"select-matboard-window-caption-arrow"}
             onClick={() => this.setState({showAddNoteModal: !this.state.showAddNoteModal})}
             src={this.state.showAddNoteModal ? arrowUp : arrowDown}
             alt={this.state.french ? "Cliquez pour ajouter une note." : "Click to add a note."}
        />

        <div className={"select-mat-row-divider"}>
          <div className="select-matboard-window-caption-text">

            {this.fetchLanguage("Notes Supplémentaires", "Additional Notes")}
          </div>
        </div>
      </div>


      <div className={this.state.showAddNoteModal ? "select-mat-row-divider add-border-top" : "no-display"}>
        <div className={"backing-mat-instructions additional-notes-add-margin"}>
          {this.fetchLanguage("Nous les lisons attentivement avant la production", "We read these carefully before production.")}
        </div>

        <div className="select-mat-input-field-direction">
          {this.fetchLanguage("remarques", "notes")}
        </div>
        <TextField
            className="add-matboard-note-section-input-field"
            id="outlined-multiline-static"
            label=""
            multiline
            // rows="10"
            variant="outlined"
            placeholder={this.fetchLanguage("Veuillez nous le faire savoir.", "Please let us know.")}
            value={this.state.additionalNoteContent}
            onChange={(evt) => this.setState({additionalNoteContent: evt.target.value})}
        />

      </div>

      {/*<div className={"drawing-app-saving-status"}>{this.state.additionalNoteContent !== "" ? "Saved" : null}</div>*/}
    </div>
  };

  singleItemSubtotalCalculator(primaryMatPrice, secondaryMatPrice,  secondaryMatAdded, backingMatAdded) {

    var subTotalPrice = 0.00;
    var discountMultiplier = this.props.getDiscountMultiplier(this.state.orderQuantity) ? this.props.getDiscountMultiplier(this.state.orderQuantity) : 1;

    subTotalPrice = subTotalPrice + primaryMatPrice;

    if (secondaryMatAdded !== "none") {
      subTotalPrice = subTotalPrice + secondaryMatPrice;
    }

    if (backingMatAdded) {
      subTotalPrice = subTotalPrice + secondaryMatPrice;
    }

    subTotalPrice = subTotalPrice * discountMultiplier;

    subTotalPrice = this.props.fxConversion(subTotalPrice);

    return subTotalPrice.toFixed(2);

  };

  showSubtotalPrice() {
    return <span>{this.singleItemSubtotalCalculator(this.matboardOnlyPricingCalculator(this.state.frame.width, this.state.frame.height, "price"), (this.state.secondMatPrice), this.state.secondMatColour, this.state.backingMatboard)}</span>
  }

  fetchLanguage(fr, en) {
    return this.state.french ? `${fr}` : `${en}`
  }

  fetchDesignToolRotation() {
    if (this.state.french) {
      return <div className={"switch-to-drawing-tool-container mobile-no-display"} onClick={() => this.setState({drawingPadShowing: !this.state.drawingPadShowing})}>
        {this.state.drawingPadShowing ? "Outil de conception standard" : "Passer à l’outil de dessin"}
      </div>
    } else {
      return <div className={"switch-to-drawing-tool-container mobile-no-display"} onClick={() => this.setState({drawingPadShowing: !this.state.drawingPadShowing})}>
        {this.state.drawingPadShowing ? "Back to design tool" : "Switch to drawing tool"}
      </div>
    }
  }

  validateQuantity(value) {
    if (Number.isInteger(parseInt(value)) === false || parseInt(value) <= 0) {
      alert("Please enter a valid quantity.")
    } else if (parseInt(value) > 0) {
      this.setState({orderQuantity: parseInt(value)})
    }
  }

  render() {

    return <div className={(window.location.pathname === "/" || window.location.pathname === "/fr") ? `select-mat-container` : `select-mat-container select-mat-container-narrow`}>

        <Helmet>
            <title>
                {this.state.french ? "Créez des passe-partouts personnalisés | Outil de conception | Ajustement parfait et design 100% personnalisé" :
                    "Create Customized Matboards | Design Tool | Perfect Fit & 100% Custom Design"}
            </title>
            <meta name="description" content={
                this.state.french ? "Concevez et créez un passe-partout entièrement personnalisé avec notre outil facile à utiliser. Obtenez un ajustement parfait pour votre cadre et votre œuvre d'art avec n'importe quelle taille, forme, couleur et design. Choisissez parmi 26 couleurs. Profitez de la livraison gratuite, des retours faciles et de la livraison partout au Canada et aux États-Unis." :
                    "Design and create a fully customized matboard with our easy-to-use tool. Get a perfect fit for your frame and artwork with any size, shape, color, and design. Choose from 26 colors. Enjoy free shipping, easy returns, and delivery across Canada & the USA."
            } />
        </Helmet>

        <div className={this.props.onGoingPromotion ? `select-mat-rendering-event` : `select-mat-rendering`} style={{height: "auto"}}>
            <div className={this.state.drawingPadShowing ? "select-mat-frame-drawing-canvas" : `no-display`}>
                {this.state.drawingPadShowing ?
                    <DrawingAppMain
                        parentSetStateCallBack={this.parentSetStateCallBack}
                        showDrawingPadExamplesModal={this.state.showDrawingPadExamplesModal}
                        canvasDrawing={this.state.canvasDrawing}
                        canvasDrawingInProgress={this.state.canvasDrawingInProgress}
                        fetchLanguage={this.fetchLanguage}
                        french={this.state.french}
                    />
                    : null }
            </div>

            <div className={this.state.drawingPadShowing ? "no-display" : `select-mat-frame`}
                 style={frameCssRendering(this.state.frame.width, this.state.frame.height, this.state.frame.renderingStandardLength, this.state.renderingToolWidth, this.state.renderingToolHeight, this.determineFrameColour(this.state.selectedFrame), this.state.deviceType)}>

                <div className={`select-matboard-section`} style={{backgroundImage: `url(${productDB.matboards[this.state.matColour] ? productDB.matboards[this.state.matColour].img : ""})`}}>
                    <ShowMattingWindows
                        frame={this.state.frame}
                        window={this.state.window}
                        selectedMatColour={this.state.matColour}
                        renderingToolWidth={this.state.renderingToolWidth}
                        renderingToolHeight={this.state.renderingToolHeight}
                        updateWindowSizeCallBack={this.updateWindowRendering}
                        moveWindowCallBack={this.moveWindowCallBack}
                        updateWindowModalState={this.updateWindowModalState}
                        totalWindowCount={this.state.window.rowCount * this.state.window.columnCount}
                        deviceType={this.state.deviceType}
                        secondMatWidth={this.state.secondMatWidth}
                        secondMatColour={this.state.secondMatColour}
                        isWindowOval={this.state.isWindowOval}
                        fetchLanguage={this.fetchLanguage()}
                        addWriting={this.state.addWriting}
                        guideDark={productDB.matboards[this.state.matColour].guideDark}
                    />
                </div>
            </div>

          {this.fetchDesignToolRotation()}

            <div className={this.state.drawingPadShowing ? "no-display" : "req-mat-sample-button-cover mobile-no-display"}>
                <div className={"select-mat-navigation-button-container"}>
                  <img src={arrowUp} className={"select-mat-navigation-icon"}/>
                  <div className={"select-mat-navigation-script"}>
                    <span className={"mobile-no-display"}>
                      {this.fetchLanguage("Cliquer sur les flèches pour déplacer la fenêtre.", "Click arrows to move the window around.")}
                    </span>
                  </div>
                </div>
            </div>

        </div>

        <div className={`select-matboard-description-container`}>

            <div className={"select-matboard-subdivider"}>
              <div className={"add-to-bag-button mobile-no-display"} onClick={() => {this.clickAddToCartButton()}}>
                {this.fetchLanguage("Ajouter au sac","Add to bag")}
              </div>
              <div className={"select-mat-container-row"} style={{cursor: "pointer"}}>
                {this.fetchLanguage("Devise","Currency")}:
                <span className={this.props.currency === "CA$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("CA$")}}> CAD </span>
                |
                <span className={this.props.currency === "US$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("US$")}}> USD </span>
                <br/>
                {this.fetchLanguage("Prix Unitaire","Unit Price")} <b>${this.showSubtotalPrice()}</b>
                <br/>
                <div className={this.props.getDiscountMultiplier(this.state.orderQuantity) === 1 ? "no-display" : "select-mat-input-field-direction-long"}>
                  {this.fetchLanguage(`Rabais de ${((1-this.props.getDiscountMultiplier(this.state.orderQuantity))*100).toFixed(0)} % appliqué`, `${((1-this.props.getDiscountMultiplier(this.state.orderQuantity))*100).toFixed(0)}% off on quantity`)}
                </div>
              </div>
              <div className={"add-to-bag-button mobile-display-only"} onClick={() => {this.clickAddToCartButton()}}>
                {this.fetchLanguage("Ajouter au sac","Add to bag")}
              </div>
              {/*//Black Friday*/}
              <div className={this.props.onGoingPromotion ? "promotion-messages" : "select-mat-container-row"}>
                {/*{this.props.onGoingPromotion ? this.fetchLanguage(`Réduction extra ${this.props.promotionRate} à la caisse`,`Additional ${this.props.promotionRate} off at checkout`) : this.fetchLanguage(`Livraison gratuite`,`Free Shipping`)}*/}
              </div>
            </div>


          <div className={"select-mat-row"} >
            <img className={"select-matboard-window-caption-arrow"}
                 onClick={() => this.setState({openQuantityModal: !this.state.openQuantityModal})}
                 src={this.state.openQuantityModal ? arrowUp : arrowDown}
                 alt={"Click to open Quantity Modal"}
            />

            <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{paddingBottom: "0", marginBottom: "0"}}>
              <div className="select-matboard-window-caption-text" style={{width: "100%", borderBottom: "1px solid #828282", paddingBottom: "12px", marginBottom: "12px"}}>
                {this.fetchLanguage("Quantité", "Quantity")}<br/>
              </div>



              <div style={{width: "100%", overflow: "auto", fontWeight: 400}}>
                <div className={"quantity-change-div"} style={{float: "left"}}>
                  <span style={{textTransform: "none", color: "#3B3B3B"}}>
                    {this.props.fetchDiscountTier(this.state.orderQuantity)}
                  </span>
                  <a target={"_blank"} href={"/pricing-and-discounts"} className={"select-mat-request-samples"}>Quantity Discount</a>
                </div>

                  <div className={"quantity-change-div"}  style={{float: "right"}}>
                      <div className={"cart-slider-shopped-item-quantity-button quantity-change-div-button"} style={{width: "auto", margin: "10px", marginRight: 0, float: "right"}} onClick={() => {this.reduceQuantity()}}>-</div>
                      <div className={"order-quantity-input-box"}>
                          {displayInputFields("", this.state.orderQuantity, (event) => this.validateQuantity(event.target.value), "outlined")}
                      </div>
                      <div className={"cart-slider-shopped-item-quantity-button quantity-change-div-button"} style={{width: "auto", margin: "10px", float: "right"}} onClick={() => {this.setState({orderQuantity: parseFloat(this.state.orderQuantity) + 1})}}>+</div>
                  </div>
              </div>

            </div>
          </div>



            <ShowProductDescription
                frameWidth={this.state.frame.width}
                frameHeight={this.state.frame.height}
                frameMeasuringUnit={this.state.frame.unit}
                framePrice={this.state.price}
                frameDescription={frameInventory[this.state.frame.type].desc}
                rotateFrame={this.rotateFrame}
                polaroidWindowRendering={this.polaroidWindowRendering}
                designFor={this.props.designFor}
                setStateFrameRendering={this.setStateFrameRendering}
                openShoppingCartModal={this.props.openShoppingCartModal}
                getSecondMatPricing={this.getSecondMatPricing}
                parentSetStateCallBack={this.parentSetStateCallBack}
                matboardSizeValueType={this.state.matboardSizeValueType}
                matboardWindowValueType={this.state.matboardWindowValueType}
                setPictureFrameSizeFraction={this.setPictureFrameSizeFraction}
                fractionalObject={this.state.fractionalObject}
                standardMatSizes={this.state.standardMatSizes}
                fractionWidthValueSelected={this.state.frame.fractionWidthValueSelected}
                fractionHeightValueSelected={this.state.frame.fractionHeightValueSelected}
                fractionWidthValueWholeNumber={this.state.frame.fractionWidthValueWholeNumber}
                fractionHeightValueWholeNumber={this.state.frame.fractionHeightValueWholeNumber}
                setFrameStandardSizeRendering={this.setFrameStandardSizeRendering}
                selectedStandardMatboardSize={this.state.selectedStandardMatboardSize}
                fetchLanguage={this.fetchLanguage}
                addWriting={this.state.addWriting}
            />

            <MatBoardActions
                window={this.state.window}
                renderingToolHeight={this.state.renderingToolHeight}
                parentSetStateCallBack={this.parentSetStateCallBack}
                matColour={this.state.matColour}
                fetchMatboardColours={this.fetchMatboardColours}
                fetchLanguage={this.fetchLanguage}
                clickAddSampleButton={this.clickAddSampleButton}
                french={this.state.french}
                location={this.props.location}
            />

            <SecondaryMat
                fetchMatboardColours={this.fetchMatboardColours}
                getDiscountMultiplier={this.props.getDiscountMultiplier}
                secondMatPrice={this.props.fxConversion(this.state.secondMatPrice * this.props.getDiscountMultiplier(this.state.orderQuantity))}
                secondMatColour={this.state.secondMatColour}
                secondMatWidth={this.state.secondMatWidth}
                parentSetStateCallBack={this.parentSetStateCallBack}
                doubleMattingValueType={this.state.doubleMattingValueType}
                doubleMattingFractionalObject={this.state.doubleMattingFractionalObject}
                doubleMattingPopularObject={this.state.doubleMattingPopularObject}
                fetchLanguage={this.fetchLanguage}
                french={this.state.french}
                fxConversion={this.props.fxConversion}
                location={this.props.location}
            />

            {this.state.drawingPadShowing ? null : <ChangeProductRendering
                showMultipleWindowSelectionModal={this.state.showMultipleWindowSelectionModal}
                isWindowOval={this.state.isWindowOval}
                multipleWindowWidth={this.state.multipleWindowWidth}
                multipleWindowHeight={this.state.multipleWindowHeight}
                windowCount={this.state.windowCount}
                rowCount={this.state.window.rowCount}
                columnCount={this.state.window.columnCount}
                orderQuantity={this.state.orderQuantity}
                frameWidth={this.state.frame.width}
                frameHeight={this.state.frame.height}
                frameUnit={this.state.frame.unit}
                parentSetStateCallBack={this.parentSetStateCallBack}
                updateWindowOpeningCallBack={this.addWindowMultiple}
                valueEntered={this.state.valueEntered}
                updateComponentStateCallBack={this.updateComponentStateCallBack}
                showMultipleFrameModal={this.state.showMultipleFrameModal}
                designFor={this.props.designFor}
                backingMatboard={this.state.backingMatboard}
                backingMatboardPrice={(this.props.fxConversion(this.state.secondMatPrice * this.props.getDiscountMultiplier(this.state.orderQuantity)))}
                matboardWindowValueType={this.state.matboardWindowValueType}
                setWindowSizeFraction={this.setWindowSizeFraction}
                windowFractionWidthValueSelected={this.state.windowFractionWidthValueSelected}
                windowFractionHeightValueSelected={this.state.windowFractionHeightValueSelected}
                windowFractionWidthValueWholeNumber={this.state.windowFractionWidthValueWholeNumber}
                windowFractionHeightValueWholeNumber={this.state.windowFractionHeightValueWholeNumber}
                fractionalObject={this.state.fractionalObject}
                standardWindowSizes={this.state.standardWindowSizes}
                fetchLanguage={this.fetchLanguage}
                addWriting={this.state.addWriting}
                location = {this.props.location}
            />}

            <div className={`select-mat-section-frame-buttons multiple-window-artwork-size-selection-panel`} style={{"borderTop": "none", marginTop: "0"}}>
                {this.showAddNoteModal()}
            </div>

            <div className={"select-mat-row-divider"}>
              <div className={"mobile-no-display"}>
                {this.displayCheckoutButton()}
              </div>
              <div className={"checkout-subtotal-figure"}>
                {this.props.french ? "Sous-total" : "Subtotal"} <b>{this.props.currency}{this.showSubtotalPrice()}</b>
              </div>
              <div className={"mobile-display-only mobile-select-mat-checkout-button"}>
                {this.displayCheckoutButton()}
              </div>

              {/*//Black Friday*/}
              <div className={this.props.onGoingPromotion ? "promotion-messages" : "no-display"}>
                {this.fetchLanguage(`Réduction extra ${this.props.promotionRate} à la caisse`,`Additional ${this.props.promotionRate} off at checkout`)}
              </div>

            </div>


            {/*{this.displayQuestionsSection()}*/}

        </div>

      <div className={"select-mat-intro"}>

        {/*<LandingPageReviewStars*/}
        {/*    french={this.state.french}*/}
        {/*    h1Tag={"Create Your Custom Matboard | Design Tool | Perfect Fit & 100% Custom Design"}*/}
        {/*/>*/}

        {/*<InstagramLoader/>*/}

        {/*<ReviewComponent/>*/}

        {/*<SatisfactionTransition*/}
        {/*    french={this.state.french}*/}
        {/*  location = {this.props.location}*/}
        {/*/>*/}

        {/*<LandingPageFAQ*/}
        {/*    french={this.state.french}*/}
        {/*    location = {this.props.location}*/}
        {/*/>*/}


        <div onClick={() => this.generateProductURL()} style={{textAlign: "center", color: "white"}}>
          Generate Product URL
        </div>


      </div>



    </div>
  }
}