import React from "react";
import RefundPolicy from "../page-disclosure/refunds";
import {marketList} from "../articleDB/articleCityNames";
import dropdownArrow from "../../../resources/svgIcons/chevron-up.svg";

export default class ShippingPage extends React.Component {

    constructor() {
        super();
        this.state = {
            bc: false,
            ab: false,
            sk: false,
            mb: false,
            on: false,
            qc: false,
            french: false,
        };
    };

    componentDidMount() {
        // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})

        this.setState({
            french: (window.location.pathname.slice(-2) === "fr") ? true : false
        })
    };


    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    }

    render() {
        return <div>
            <div className={`general-static-page-container`}>
                <div className={`general-static-page-container-heading`}>
                    {this.state.french ? "EXPÉDITION" : "Shipping"}

                </div>

                <div className={`general-static-page-container-topic-heading`}>
                    {this.state.french ? "TARIFS D’EXPÉDITION" : "Shipping Rates"}
                </div>

                <div className={`general-static-page-container-text`}>
                    <ul>
                        <li>
                            {this.state.french ? "Toutes les commandes sont expédiées gratuitement au Canada et aux États-Unis." : "All orders are shipped free of charge to Canada & the USA."}
                        </li>
                        <li>
                            {this.state.french ? "Au Canada, les livraisons sont assurées par Canada Post, Purolator et GTAGSM. Aux États-Unis, elles sont effectuées par UPS et USPS, selon la taille, le poids du colis et l’adresse de livraison.":
                                "Canadian deliveries are via Canada Post, Purolator, or GTAGSM; U.S. deliveries use UPS or USPS. The courier is selected based on package size, weight, and shipping address."}
                        </li>
                    </ul>
                </div>

                <div className={`general-static-page-container-text`}>
                    <ul>
                        <li>
                            {this.state.french ? "Custom Mat fonctionne au maximum de sa capacité et peut produire et expédier votre passe-partout dans un délai de 2 jours ouvrables." : "Custom Mat is operating at full capacity and can produce and ship your matboard within 2 business days."}

                        </li>
                        <li>
                            {this.state.french ? "Nos partenaires d’expédition : Postes Canada ou Purolator" : "Our shipping partners Canada Post, USPS, and UPS / Purolator have advised us that deliveries may take extra 1 - 4 additional days at the year-end holiday season."}

                        </li>
                        <li>
                            {this.state.french ? "Nos partenaires d’expédition peuvent prendre 1 à 3 jours supplémentaires pour les livraisons en zone rurale et éloignée." : "Our shipping partners may take extra 1 - 3 days for rural and remote area deliveries."}

                        </li>
                    </ul>
                </div>

                <div className={`general-static-page-container-topic-heading`}>
                    {this.state.french ? "CANADA (POSTES CANADA, Purolator, GTAGSM)" : "Canada (Canada Post, Purolator, GTAGSM)"}

                </div>

                <div className={`general-static-page-container-text`}>
                    <ul>
                        <li>
                            {this.state.french ? "Ontario et Québec : 2 à 5 jours" : "Ontario and Quebec: 2 - 5 days"}

                        </li>
                        <li>
                            {this.state.french ? "Provinces des Prairies : 4 à 7 jours" : "Prairie Provinces: 4 - 7 days"}

                        </li>
                        <li>
                            {this.state.french ? "Provinces de l’Atlantique : 4 à 8 jours" : "Atlantic Provinces: 4 - 8 days"}

                        </li>
                        <li>
                            {this.state.french ? "Colombie-Britannique 5 à 9 jours" : "British Columbia: 5 - 9 days"}

                        </li>
                        <li>
                            {this.state.french ? "Territoires du Nord : 7 à 12 jours" : "Northern Territories: 7 - 12 days"}

                        </li>
                        <li>
                            {this.state.french ? "Veuillez noter que les livraisons dans les régions rurales et éloignées peuvent prendre de 1 à 3 jours supplémentaires." : "Please note that rural and remote area deliveries may take extra 1 - 3 days."}

                        </li>
                    </ul>
                    <div className={`general-static-page-container-text`}>
                        {this.state.french ? "Avec une expédition plus rapide vers les zones suivantes :" : "With faster shipping to the following areas:"}

                    </div>
                </div>

                <div className={`general-static-page-container-topic-heading`}>
                    {this.state.french ? "ÉTATS-UNIS (UPS & USPS)" : "United States (UPS & USPS)"}

                </div>

                <div className={`general-static-page-container-text`}>
                    <ul>
                        <li>
                            {this.state.french ? "Côte Est et centre-ouest : 4 à 7 jours" : "East Coast and Mid-West: 4 - 7 days"}

                        </li>
                        <li>
                            {this.state.french ? "Nord-ouest : 5 à 8 jours" : "Northwest: 5 - 8 days"}

                        </li>
                        <li>
                            {this.state.french ? "Sud-est et sud-ouest : 5 à 9 jours" : "Southeast and Southwest: 5 - 9 days"}

                        </li>
                        <li>
                            {this.state.french ? "Veuillez noter que les livraisons dans les régions rurales et éloignées peuvent prendre de 1 à 3 jours supplémentaires." : "Please note that rural and remote area deliveries may take extra 1 - 3 days."}

                        </li>
                    </ul>
                </div>

                <div className={`user-policy-paragraph`}>

                    <div style={{paddingBottom: "6px"}}>
                        {this.state.french ? "Nous proposons une expédition plus rapide vers les zones suivantes :" : "We offer faster shipping to following areas:"}

                    </div>


                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("bc")}>
                            <img src={dropdownArrow} className={this.state.bc ? "faq-question-close" : "faq-question-open"}
                                alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                {this.state.french ? "Colombie-Britannique" : "British Columbia"}

                        </div>
                        </div>

                        <div className={this.state.bc ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.britishColumbia.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.britishColumbia.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.britishColumbia.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.britishColumbia.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("ab")}>
                            <img src={dropdownArrow} className={this.state.ab ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Alberta
                            </div>
                        </div>

                        <div className={this.state.ab ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.alberta.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.alberta.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.alberta.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.alberta.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("sk")}>
                            <img src={dropdownArrow} className={this.state.sk ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Saskatchewan
                            </div>
                        </div>

                        <div className={this.state.sk ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.saskatchewan.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.saskatchewan.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.saskatchewan.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.saskatchewan.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("mb")}>
                            <img src={dropdownArrow} className={this.state.mb ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Manitoba
                            </div>
                        </div>

                        <div className={this.state.mb ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.manitoba.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.manitoba.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.manitoba.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.manitoba.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("on")}>
                            <img src={dropdownArrow} className={this.state.on ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Ontario
                            </div>
                        </div>

                        <div className={this.state.on ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.ontario.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.ontario.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.ontario.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.ontario.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("qc")}>
                            <img src={dropdownArrow} className={this.state.qc ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Québec
                            </div>
                        </div>

                        <div className={this.state.qc ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.quebec.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.quebec.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.quebec.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.quebec.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>



            <div style={{marginTop: "-69px"}}>
                <RefundPolicy style={{width: "100%"}} french={this.state.french}/>
            </div>



        </div>
    }
}