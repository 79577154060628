import React from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


export default class ShippingInfo extends React.Component {

  constructor() {
    super();
    this.state = {
      newAddressNeeded: true,
    };
  }

  componentDidMount() {



    if (localStorage.getItem("customerEmail")) {
      this.setState({newAddressNeeded: false})
    } else {
      this.setState({newAddressNeeded: true})

    }
  }

  dispatchSectionHeader(text) {
    return <div className={`shipping-form-container-section-header`}>
      {text}
    </div>
  };

  deliveryForms() {
    return <div className={this.props.pickUpOrShipping === "shipping" ? "shipping-address-section-wrapper" : "shipping-address-section-wrapper"}>
      {this.props.addTextFields("shipping-address-recipient-name shipping-name inspectletIgnore", this.props.french ? "Nom et prénom" : "First and Last", "shippingAddressRecipientName", this.props.showErrorInput("shippingAddressRecipientName"), this.props.french ? "Nom" : "Name")}
      {this.props.addTextFields("shipping-address-street inspectletIgnore", "", "shippingAddressStreet", this.props.showErrorInput("shippingAddressStreet"), this.props.french ? "Adresse*" : "Address")}
      {this.props.addTextFields("shipping-address-suite inspectletIgnore", this.props.french ? "À l'attention de, Bureau n°" : "Attn, Suite #", "shippingAddressSuite", false,  this.props.french ? "Adresse 2" : "APT, Suite, Additional Info (Optional)")}
      {this.props.addTextFields("shipping-address-city inspectletIgnore", "", "shippingAddressCity", this.props.showErrorInput("shippingAddressCity"),  this.props.country === "EU" ? "City & Region" : "City")}

      <FormControl className={"province-state-selector-container"}>
        <div className={"add-text-fields-text-field-title"}>
          {this.props.country === "EU" ? "Region" : "Country"}
        </div>
        <Select
            variant={"outlined"}
            value={this.props.country}
            onChange={(evt) => {this.props.changeCountry(evt.target.value)}}
        >
          <MenuItem value="CA">Canada</MenuItem>
          <MenuItem value="US">United States</MenuItem>
          <MenuItem value="EU">Europe</MenuItem>
          <MenuItem value="AU">Australia</MenuItem>
        </Select>
      </FormControl>

      {this.props.selectProvinceOrState()}
      {this.props.addTextFields("shipping-address-postal-code", "", "shippingAddressPostalCode", this.props.showErrorInput("shippingAddressPostalCode"),
          this.props.country === "US" ? "Zip Code" : "Postal Code")}

      <div className={"delivery-timeline-notification"}>
        {this.props.french ? "Expédié de notre atelier dans les 2 prochains jours ouvrables. " : "Shipped from our workshop within the next 2 business days. "}
        <br className={"mobile-no-display"}/>
        {this.props.french ? "Par Postes Canada, UPS et USPS. La livraison prend environ 1 à 5 jours." : "Via Canada Post, UPS, and USPS. Delivery takes about 1-5 days."}
      </div>
    </div>
  };

  pickUpInstructions() {
    return <div className={this.props.pickUpOrShipping === "pickup" ? "shipping-or-pickup-options" : "no-display"}>
      You will receive an email when your order is ready (1-2 Business Days).<br/><br/>
      You can pick up at our location at<br/>
      <a href={"https://goo.gl/maps/dPjv4zVVCxnKXQCi8"} rel="noopener noreferrer" target={"_blank"} alt="Google Maps link to our location">1147 Grant Street, Vancouver, BC, V6A 2J7</a><br/><br/>
      Mon - Fri 9:30AM - 5PM or email us to arrange your pickup window.<br/>
    </div>
  };

  newAddressNeeded() {
    this.setState({newAddressNeeded: true})
  }

  render() {
    return <div className={`delivery-payments-section-shipping-payments-container`}
                id={`delivery-payments-section-web-only`}
    >

      <div className={ `shipping-form-container`}>
        <div className={"payment-section-dividers"}>

          <div className={(this.state.newAddressNeeded) ? "no-display" : "shipping-address-section-wrapper"}>
            <div className={(this.props.shippingAddressProvince === "Select") ? "no-display" : "saved-prev-address"} onClick={()=>this.props.shippingInfoCheck()}>
             <b>Ship to</b><br/><br/>
              {this.props.shippingAddressRecipientName}<br/>
              {this.props.customerEmail}<br/>
              {this.props.customerPhoneNumber !== "undefined"  ? this.props.customerPhoneNumber : null}<br/><br/>

              {this.props.shippingAddressStreet} {this.props.shippingAddressSuite !== "undefined" ? this.props.shippingAddressSuite : null}<br/>
              {this.props.shippingAddressCity}, {this.props.shippingAddressProvince}, {this.props.shippingAddressPostalCode}
            </div>

            <div className={"saved-prev-address"} onClick={() => this.newAddressNeeded()}>
              <b>Add a New Address</b>
            </div>
          </div>

          <div className={this.state.newAddressNeeded ? "display" : "no-display"}>
            {this.dispatchSectionHeader(this.props.french ? "Coordonnées" : "Contact Info")}

            <div className={"shipping-address-section-wrapper"}>
              {this.props.addTextFields("shipping-address-email inspectletIgnore", "", "customerEmail", this.props.showErrorInput("customerEmail"), "Email")}
              {this.props.addTextFields("shipping-address-recipient-phone inspectletIgnore", "", "customerPhoneNumber", false, this.props.french ? "Numéro de Téléphone" : "Phone Number (Optional)")}
            </div>

            {this.dispatchSectionHeader(this.props.french ? "Adresse de Livraison" : "Shipping Address")}
            {this.deliveryForms()}

            <div className={`checkout-shop-more-button canvas-app-add-to-cart-button important-action-button`}
                 onClick={() => {this.props.shippingInfoCheck();}}>
              {this.props.french ? "Continuer" : "Continue"}
            </div>

          </div>
        </div>
      </div>

    </div>
  }
}