import React from "react";
import fbdb from '../database/cre';
import EncDec from "../app-enc/encryption";
import SelectMat from "../app-matsize-rendering/selectMat";
import emailEnc from "../app-enc/emailEnc";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export default class WorkFlow extends React.Component {

  constructor() {
    super();
    this.state = {
      authEmail: null,
      dataLoaded: false,
      copyValueToClipboard: "",
      activeOrderIndex: -1,
      emailEntered: "",
      emailEnteredEncrypted: "",
    };

  }

  updateEmail(email) {
    this.setState({authEmail: email}, () => this.confirmEmail())
  }

  login() {
    var provider = new fbdb.auth.GoogleAuthProvider();
    fbdb.auth().signInWithPopup(provider).then(function(result) {
      var user = result.user;
      this.updateEmail(user.email);
    }.bind(this)).catch(function(error) {

    });
  };

  callUserRecFromDB() {

    var encryptedEmail = this.state.emailEntered;
    var destDirectory = `prod/paid-prod/${encryptedEmail}`;
    var valueLoaded = fbdb.database().ref(destDirectory);


    valueLoaded.once('value').then((snapshot) => {
      this.setState({ordersToShow: JSON.stringify(snapshot.val()).toString()})
    }).then(() => this.setState({dataLoaded: true}));
  }

  fetchData() {
    return <div className={this.state.authEmail === null ? "no-display" : "display"}>
      <TextField
          id={"inspectletIgnore"}
          placeholder={"Enter"}
          value={this.state.emailEntered}
          style={{width: "500px"}}
          onChange={(evt) => this.setState({emailEntered: evt.target.value})}
      />
      <Button onClick={() => this.callUserRecFromDB()}>
          Search
      </Button>
    </div>



  }

  fetchOrderList(list) {
    return list.map((item, key) => {
      return <div className={`workflow-order-container-order-items`}>
        <div className={`workflow-order-container-order-leader`}>
          <span className={"workflow-section-title"}>Overview</span><br/>
          <span className={`workflow-section-subtitle`}>Unit</span>{item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Ordered Quantity</span>{item.qItemQuantity}<br/>
        </div>

        <div className={`workflow-order-container-pricing`}>
          <span className={"workflow-section-title"}>Price</span><br/>
          <span className={`workflow-section-subtitle`}>Primary Matting</span>${item.kPrimaryMattingPrice}<br/>
          <span className={`workflow-section-subtitle`}>Primary Cutting</span>${item.lMatCuttingPrice}<br/>
          <span className={`workflow-section-subtitle`}>Double Matting</span>${item.oSecondMattingPrice}<br/>
          <span className={`workflow-section-subtitle`}>Double Matting Price</span>${item.pSecondMatCuttingPrice}<br/>
          <span className={`workflow-section-subtitle`}>Shipping</span>${item.rShippingCost}<br/>
        </div>

        <div className={`workflow-order-container-primary-matting`}>
          <span className={"workflow-section-title"}>Primary Matting</span><br/>
          <span className={`workflow-section-subtitle`}>Dimensions</span>{item.bPrimaryMatWidth} x {item.cPrimaryMatHeight} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Color</span>{item.jPrimaryColour}<br/>
        </div>

        <div className={`workflow-order-container-primary-window`}>
          <span className={"workflow-section-title"}>Window</span><br/>
          <span className={`workflow-section-subtitle`}>Dimensions</span>{item.dPrimaryWindowWidth} x {item.ePrimaryWindowHeight} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Top</span>{item.fPrimaryWindowTop} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Bottom</span>{item.gPrimaryWindowBottom} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Left</span>{item.hPrimaryWindowLeft} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Right</span>{item.iPrimaryWindowRight} {item.aMeasuringUnit}<br/>
        </div>

        <div className={`workflow-order-container-primary-secondary`}>
          <span className={"workflow-section-title"}>Secondary Matting</span><br/>
          <span className={`workflow-section-subtitle`}>Dimensions</span>{item.mSecondaryMatWidth} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Color</span>{item.nSecondaryMatColour}<br/>
        </div>

      </div>
    })
  }

  fetchWindowDetails(window) {}

  fetchShoppingCartItems(shoppingCart) {
    return shoppingCart.map((cartItem, key) => {
      return <div>
        <div>Ordered Item Info</div>
        <div>Product: {cartItem.prodType}</div>
        <div>Quantity: {cartItem.orderQuantity}</div>

        <div>Frame Info</div>
        <div><b>Type: {cartItem.frameType}</b></div>
        <div>Size: {cartItem.frameSize}</div>
        <div>Dimension: {cartItem.frameWidth} x {cartItem.frameHeight} in</div>

        <div>Mat & Window Info</div>
        <div>Mat Colour: {cartItem.matColour}</div>
        <div>Count: {cartItem.window.rowCount * cartItem.window.columnCount}</div>
        <div>Window Detailed Info: {`${cartItem.window}`}</div>
        <div>
          <b>Backing Mats: {`${cartItem.backingMatboard}`}</b>
        </div>
        <div>Canvas Drawing: {cartItem.canvasDrawing ?
        <div className={"custom-mat-design-diagram"} style={{width: "40%"}}>
                <img style={{width: "100%"}} src={cartItem.canvasDrawing} alt={"Canvas Drawing"}/>
            </div>
         : "false"}</div>
        <div>
          <b>Additional Notes:
            <div style={{color: "red"}}>{`${cartItem.additionalNoteContent}`}</div>
          </b>
        </div>

        <SelectMat
            workflow={true}
            designFor={cartItem.prodType}
            frameType={cartItem.frameType}
            frameSize={cartItem.frameSize}
            frameWidth={cartItem.frameWidth}
            frameHeight={cartItem.frameHeight}
            matColour={cartItem.matColour}
            price={cartItem.price}
            window={cartItem.window}
            secondMatPrice={cartItem.secondMatPrice ? cartItem.secondMatPrice : "NO SECOND MAT SELECTED"}
            secondMatColour={cartItem.secondMatColour ? cartItem.secondMatColour : "NO SECOND MAT SELECTED"}
            secondMatWidth={cartItem.secondMatWidth ? cartItem.secondMatWidth : 0}
            isWindowOval={cartItem.isWindowOval}
            getDiscountMultiplier={this.props.getDiscountMultiplier}
            currency={this.props.currency}
            fxConversion={this.props.fxConversion}
            changeCurrency={this.props.changeCurrency}
            fetchDiscountTier={this.props.fetchDiscountTier}
        />
      </div>
    });

  }

  copyAddressFields(value) {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
  }

  displayOrderByOrderDates(dbValue, index) {
    //second then we fetch the individual user by the timestamp


    var dbValue = JSON.parse(dbValue);

    var customerEmail = dbValue.customerEmail;

    var grandTotal = dbValue.grandTotal;

    var shoppingCart = JSON.parse(dbValue.shoppingCart);

    return <div>
      <div style={{width: "100%", float: "left", fontWeight: "600"}}>
        <div style={{fontWeight: "600", marginBottom: "1vw"}}>Customer Info</div>
        <div>{customerEmail}</div>
        <div>Grand Total Paid: {grandTotal} in CAD</div>
      </div>
      <br/><br/>

      {this.fetchShoppingCartItems(shoppingCart)}

    </div>

  }

  displayOrderDataByUser() {
    var dbValues = this.state.ordersToShow;
    return this.displayOrderByOrderDates(dbValues);
  }

  confirmEmail() {
    return this.state.authEmail === "to.richard.hong@gmail.com" ? this.fetchData() : null
  };

  render() {
    return <div className={`workflow-section`}>
      Workflow Section
      <button className={this.state.authEmail === null ? "display" : "no-display"} onClick={() => this.login()}>Login</button>
      <div className={this.state.authEmail === null ? "no-display" : "display"}>Signed In</div>

      {this.fetchData()}

      {this.state.dataLoaded && this.displayOrderDataByUser()}
    </div>
  }

}