import {productDB} from "./productDB";

export const pricingElements = {
  extraSmall: {
    maxSize: {
      in: 14*11,
      cm: 35*27,
    },
    prices: {
      mattingPrice: 4.2,
      cuttingPrice: 1.99,
    },
    shippingCost: {
      shippingType: "Envelope",
      Canada: 0,
      US: 0,
    },
  },
  small: {
    maxSize: {
      in: 20*20,
      cm: 51*51,
    },
    prices: {
      mattingPrice: 10.5,
      cuttingPrice: 2.99,
    },
    shippingCost: {
      shippingType: "Parcel",
      Canada: 8.99,
      US: 5.99,
    },
  },
  medium: {
    maxSize: {
      in: 30*30,
      cm: 76*76,
    },
    prices: {
      mattingPrice: 21,
      cuttingPrice: 3.99,
    },
    shippingCost: {
      shippingType: "Large Parcel",
      Canada: 9.99,
      US: 7.99,
    },
  },
  large: {
    maxSize: {
      in: 39*39,
      cm: 99*99,
    },
    prices: {
      mattingPrice: 28,
      cuttingPrice: 4.99,
    },
    shippingCost: {
      shippingType: "Extra Large Parcel",
      Canada: 11.99,
      US: 9.99,
    },
  }
};