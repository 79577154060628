import React from "react";
import "@babel/polyfill";
import TextField from "@material-ui/core/TextField";

export default class PromoCodeValidation extends React.Component {

  constructor() {
    super();
    this.state = {
      // promoCodeModal: false,
    // Black Friday Promo Code
    promoCodeModal: false,
    };
  };

  fetchPromoCodeBox() {
      if (this.props.onGoingPromotion) {
        return <div className={"promo-code-selector"}>

        </div>
      } else {
      return null
    }
  }

  render() {
    return <div className={this.props.onGoingPromotion ? `delivery-payments-section-shipping-payments-container` : `no-display`}>

      <div className={`shipping-form-container-section-header`}>
        {this.props.french ? "Promos" : "Promotions"}
      </div>

      {this.fetchPromoCodeBox()}

      <div className={this.state.promoCodeModal || this.props.onGoingPromotion ? "promo-code-container" : "no-display"} >

        <TextField
            className={"discount-input-summary"}
            placeholder={this.props.french ? "Code Promotionnel" : "Promo Code"}
            value={this.props.promoCodeInfo.promoCodeEntered}
            onChange={(evt) => this.props.evaluatePromoCode(evt.target.value)}
        />


        <div className={this.props.promoCodeInfo.promoCodeEntered === "" ? "no-display" : "display promo-application-view"}>
          <span className={this.props.promoCodeInfo.promoCodeApplied ? "no-display" : "display error"}>
            {this.props.french ? "Code Promotionnel Invalide" : "Hmm... Invalid Code"}
          </span>
          <span className={this.props.promoCodeInfo.promoCodeApplied ? "display success" : "no-display"}>
            {this.props.french ? "Remise appliquée" : "Bingo! Discount applied."}
          </span>
        </div>

      </div>


    </div>
  }
}