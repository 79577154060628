import React from "react";
import PromoCodeValidation from "./promoCodeValidation";
import FetchItemList from "../comp-cart/fetchItemList";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";

export default class ShoppingSummary extends React.Component {

  constructor() {
    super();
    this.state = {
      showShoppingCartItems: false,
    };

    this.fetchLanguage = this.fetchLanguage.bind(this);

  };

  componentDidMount() {

  }

  fetchLanguage(fr, en) {
    return this.state.french ? `${fr}` : `${en}`;
  }




  fetchShippingChargesPrompt(shippingCost, shippingOrPickup, shoppingSubtotal) {
    if (shoppingSubtotal >= 49 && shippingOrPickup === "shipping") {
      return "Free"
    } else if (shippingCost === 0 && shippingOrPickup === "pickup") {
      return "Free Pickup"
    } else {
      return `$${shippingCost.toFixed(2)}`
    };
  };

  abandonDecimals(number) {
    return (Math.trunc(number * 100)/100).toFixed(2);
  }

  render() {

    var quantityMultiplier = this.props.getDiscountMultiplier();
    // fxRateChange and cleaning decimals
    var preDiscountPrice = this.abandonDecimals(this.props.fxConversion(this.props.preDiscountPrice));
    var itemsSubtotal = this.abandonDecimals(this.props.fxConversion(this.props.itemsSubtotal));
    var shippingCharges = this.abandonDecimals(this.props.fxConversion(this.props.shippingCharges));
    var taxes = this.abandonDecimals(this.props.fxConversion(this.props.taxes));
    var grandTotal = this.abandonDecimals(this.props.fxConversion(this.props.grandTotal));

    return <div className={"delivery-payments-section-shipping-payments-container"} id={"shopping-summary-container"}>
      <div className={"review-section-container-header"}>
        <div className={"summary-review-button-left"}>
          {this.props.french ? "Révision" : "Review"}
          <img
              className={this.state.showShoppingCartItems ? "support-arrow-arrow-v2-inverse" : "support-arrow-arrow-v2"}
              src={landingPageArrowLeft}
              onClick={() => this.setState({showShoppingCartItems: !this.state.showShoppingCartItems}, () => {})}
              alt={this.props.french ? "Cliquez pour afficher les détails du panier." : "Click to view shopping cart details."}
          />
        </div>

        <div className={"summary-review-button-right"}>
          {this.props.getTotalItemCount()} {this.props.getTotalItemCount() > 1 ?
            this.props.french ? "Articles" : "Items" :
            this.props.french ? "Article" : "Item"}
        </div>

      </div>

      <div onClick={this.props.turnOffErrorChecker === false ? () => {this.setState({focusedModal: "summary"})} : null}>

        <div className={this.state.showShoppingCartItems ? "display" : "no-display"}>
          <FetchItemList
              shoppingCartItems={this.props.shoppingCartItems}
              updateItemList={this.props.updateItemList}
              getDiscountMultiplier={this.props.getDiscountMultiplier}
              fetchLanguage={this.fetchLanguage}
              french={this.props.french}
              fxConversion={this.props.fxConversion}
              location={this.props.location}
          />
          <div className={this.props.getDiscountMultiplier() === 1 ? "no-display" : "shipping-summary-header"} style={{width: "100%", textAlign: "right"}}>
            The unit price above reflects {((1-this.props.getDiscountMultiplier())*100).toFixed(0)}% Quantity Discount.
          </div>
        </div>

        <div className={"subtotal-calculator-container"}>


          <div className={"shipping-cost-container"}>
            <div className={"shipping-summary-header"}>{this.props.french ? "Sous Total" : "Items Subtotal"}</div>

            <div className={"shipping-summary-amount"} style={{float: "right"}}>
              ${itemsSubtotal}
            </div>
          </div>

          <div className={this.props.discountRate === 0 ? "no-display" : "display"} style={{width: "100%", textAlign: "right"}}>
            {(this.props.discountRate*100).toFixed(0)}% Promotion Discount
          </div>

          <div className={"shipping-and-taxes-container"}>

            <div className={"shipping-cost-container"}>
              <div className={"shipping-summary-header"}>{this.props.french ? "Frais d’expédition" : "Shipping Charges"}</div>
              <div className={"shipping-summary-amount"}>
                ${shippingCharges}
              </div>
            </div>

            <div className={"shipping-cost-container shipping-summary-add-bottom-line"}>
              <div className={"shipping-summary-header"}>Taxes</div>
              <div className={"shipping-summary-amount"}>
                ${taxes}
              </div>
            </div>

            <div className={"shipping-cost-container"}>
              <div className={"shipping-summary-header-total"}>Total</div>
              <div className={"shipping-summary-amount-total"}>
                {this.props.currency === "US$" ? "US$" : "CA$"}
                {grandTotal}
              </div>
            </div>

          </div>

        </div>

        {/*<PromoCodeValidation*/}
        {/*    promoCodeInfo={this.props.promoCodeInfo}*/}
        {/*    evaluatePromoCode={this.props.evaluatePromoCode}*/}
        {/*    french={this.props.french}*/}
        {/*    fxConversion={this.props.fxConversion}*/}
        {/*    onGoingPromotion={this.props.onGoingPromotion}*/}
        {/*    promotionRate={this.props.promotionRate}*/}
        {/*    promoCode={this.props.promoCode}*/}
        {/*/>*/}

        <div className={"review-page-reminder"}>
          <div className={"cart-slider-ending-main"}>
            {this.fetchLanguage("Votre commande ","Your order")}
            <b>{this.fetchLanguage(" n’est pas "," does not ")}</b>
            {this.fetchLanguage("pas livrée avec des cadres, des vitres ou des images. ","come with frames, glasses, or prints. ")}
            <br className={this.state.french ? "no-display" : ""}/>
            {this.fetchLanguage("Seulement vos passe-partout personnalisés.","Only your custom matboards.")}
          </div>
          <div className={"cart-slider-ending-subheading"}>
            {this.fetchLanguage("Si le passe-partout ne fonctionne pas ou est endommagé, faites-nous le savoir et nous remplacerons votre commande gratuitement.", "If the mat does not work or is damaged, let us know!")}<br/>
          </div>
        </div>


      </div>

    </div>
  }
}