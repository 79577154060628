import React from "react";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import checkIconDark from "../../../resources/svgIcons/check-icon.svg";
import checkIconLight from "../../../resources/svgIcons/check-icon-light.svg";
import showMoreIcon from "../../../resources/svgIcons/show-more.svg";
import showLessIcon from "../../../resources/svgIcons/show-less.svg";
import hideTextIcon from "../../../resources/svgIcons/hide-text.svg";
import showTextIcon from "../../../resources/svgIcons/show-text.svg";
import refreshIcon from "../../../resources/svgIcons/refresh.svg";

import brickWallPattern from "../../../resources/images/wallPatterns/brickWallPattern.jpg.png";
import cementWallPattern from "../../../resources/images/wallPatterns/cementWallPattern.jpg";
import industrialWallPattern from "../../../resources/images/wallPatterns/industrialWallPattern.jpg";
import paintedWallPattern from "../../../resources/images/wallPatterns/paintedWallPattern.png";

import brickWallPatternWebp from "../../../resources/images/wallPatterns/webp/brickWallPattern.jpg.webp";
import cementWallPatternWebp from "../../../resources/images/wallPatterns/webp/cementWallPattern.webp";
import industrialWallPatternWebp from "../../../resources/images/wallPatterns/webp/industrialWallPattern.webp";
import paintedWallPatternWebp from "../../../resources/images/wallPatterns/webp/paintedWallPattern.webp";

import whiteFrame from "../../../resources/images/framePatterns/white-frame-custom-mat-rendering.jpg";
import mapleFrame from "../../../resources/images/framePatterns/maple-frame-custom-mat-rendering.jpg";
import goldenFrame from "../../../resources/images/framePatterns/golden-frame-custom-mat-rendering.jpg";
import silverFrame from "../../../resources/images/framePatterns/silver-frame-custom-mat-rendering.jpg";
import blackFrame from "../../../resources/images/framePatterns/black-frame-custom-mat-rendering.jpg";
import ornamentalFrame from "../../../resources/images/framePatterns/ornamental-frame-custom-mat-rendering.jpg";

import whiteFrameWebp from "../../../resources/images/framePatterns/webp/white-frame-custom-mat-rendering.webp";
import mapleFrameWebp from "../../../resources/images/framePatterns/webp/maple-frame-custom-mat-rendering.webp";
import goldenFrameWebp from "../../../resources/images/framePatterns/webp/golden-frame-custom-mat-rendering.webp";
import silverFrameWebp from "../../../resources/images/framePatterns/webp/silver-frame-custom-mat-rendering.webp";
import blackFrameWebp from "../../../resources/images/framePatterns/webp/black-frame-custom-mat-rendering.webp";
import ornamentalFrameWebp from "../../../resources/images/framePatterns/webp/ornamental-frame-custom-mat-rendering.webp";

import PriceRendering from "../comp-pricing/pricingRendering";
import {pricingElements} from "../comp-pricing/pricingConstants";
import localStorageFunctions from "../comp-localStorage/localStroage";
import arrowTopIcon from "../../../resources/svgIcons/arrow-top.svg";
import arrowLeftIcon from "../../../resources/svgIcons/arrow-left.svg";
import "@babel/polyfill";
import {cadUsdRate} from "../comp-pricing/forExRate";
import questionIcon from "../../../resources/svgIcons/question.svg";
import addImageIcon from "../../../resources/svgIcons/add-image-icon.svg";
import {secondMattingFactorialPrice} from "../../../../src/javascript/components/comp-pricing/secondaryMat.js";

import renderImg from "../comp-renderImg/renderImg";
import checkMatBoardSize from "./checkMatBoardSize";
import analyticsDataToDB from "../comp-analyticsLog/dbLogging";
import {productDB} from "../comp-pricing/productDB";
import ReviewComponent from "../app-review/reviewComponent";

export default class CanvasApp extends React.Component {

  constructor() {
    super();
    this.state = {
      currencySelected: "CAD",

      selectedDestinationCountry: "Canada",
      selectedDestinationProvince: "BC",
      selectedMeasurementUnits: "in",

      selectedMatBoardSizeWidth: undefined,
      selectedMatBoardSizeHeight: undefined,

      selectedUnCutMatBoard: false,

      selectedMatWindowWidth: undefined,
      selectedMatWindowHeight: undefined,

      selectedMatWidthTop: undefined,
      selectedMatWidthBottom: undefined,
      selectedMatWidthLeft: undefined,
      selectedMatWidthRight: undefined,

      selectedMatColour: "pearl",
      selectedCenterMyArtwork: false,
      selectedMatThickness: "fourPly",
      selectedMatBoardQuality: "high",
      selectedMatCore: "white",

      addSecondaryMattingClicked: false,

      selectedSecondaryMatWidthTop: undefined,
      selectedSecondaryMatWidthBottom: undefined,
      selectedSecondaryMatWidthLeft: undefined,
      selectedSecondaryMatWidthRight: undefined,

      manuallyEnterSecondaryMattingClicked: true,
      selectedSecondaryMattingOption: "",
      selectedSecondaryMattingColour: "pearl",

      clickedHideTextButton: false,

      highlightMatSection: false,
      highlightWindowSection: false,

      uploadedArtworkImage: false,

      expandRenderingToolClicked: false,

      shippingDestinations: {
        Canada: {
          BC: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 2,
            PST: 0.07,
          },
          ON: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 4,
            PST: 0.08,
          },
          QC: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 4,
            PST: 0.09975,
          },
          AL: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 3,
            PST: 0.00,
          },
          MN: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 4,
            PST: 0.08,
          },
          SK: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 4,
            PST: 0.06,
          },
          NB: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 5,
            PST: 0.10,
          },
          NL: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 5,
            PST: 0.10,
          },
          NT: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 5,
            PST: 0.00,
          },
          NS: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 5,
            PST: 0.10,
          },
          NU: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 5,
            PST: 0.00,
          },
          PE: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 5,
            PST: 0.10,
          },
          YT: {
            shippingFees: 0.00,
            largeShippingCost: 9.99,
            estimatedShippingBusinessDays: 5,
            PST: 0.00,
          },
        },
        US: {
          shippingFees: productDB.matboardOnlyPricing.xLarge.shippingCosts,
          largeShippingCost: 0,
          estimatedBusinessDays: 5,
          PST: 0.00,
          //US is flat rate shipping and no taxes charged
        },
      },

      renderFrameType: "maple",
      renderWallColour: "white1",
      renderWallType: "brick",

      matSizeRenderingCSS: {
        width: "20rem",
        height: "14rem",
        backgroundColor: "#fffdf0",
      },

      frameSizeRenderingCSS: {
        width: "20rem",
        height: "14rem",
        backgroundImage: `url(${mapleFrame})`,
      },

      renderingCentered: "width",

      matRenderingMultiplier: 20,

      primaryMatWindowRenderingCSS: {
        width: "16rem",
        height: "10rem",
        // backgroundColor: "red",
        margin: "2rem",
        padding: "0rem",
      },

      measuringUnitOptions: {
        cm: {
          desc: "cm",
          details: "Change all units to centimeters (cm)."
        },
        in: {
          desc: "in",
          details: "Change all units to inches (in)."
        },
      },

      mattingColour: {
        white: {
          desc: "Chalk White",
          colorCode: "#FFFFFF",
        },
        cream: {
          desc: "Creamy White",
          colorCode: "#FEFFFA",
          //Creamy White AG6470
        },
        pearl: {
          desc: "Pearl",
          colorCode: "#fffdf0",
          //Creamy White AG6470
        },
        butter: {
          desc: "Butter",
          colorCode: "#FFFFE1",
        },
        sand: {
          desc: "Sand",
          colorCode: "#F3E1D7",
        },
        blue: {
          desc: "Skyblue",
          colorCode: "#A5B9C4",
        },
        agate: {
          desc: "Agate",
          colorCode: "#D8C3BE",
        },
        black: {
          desc: "Blueberry",
          colorCode: "#2F3F4C",
        },
      },

      renderingWallColourOptions: {
        white1: {
          desc: "Blended White",
          colorCode: "rgba(253, 253, 253, 0.5)",
        },
        white2: {
          desc: "Creamy White",
          colorCode: "rgba(240, 239, 230, 0.5)",
        },
        grey: {
          desc: "Silver Grey",
          colorCode: "rgba(203, 204, 198, 0.5)",
        },
        cream: {
          desc: "Cream",
          colorCode: "rgba(246, 226, 165, 0.5)",
        },
        green: {
          desc: "Light Emerald",
          colorCode: "rgba(194, 210, 202, 0.5)",
        },
        black: {
          desc: "Dark Grey",
          colorCode: "rgba(104, 103, 99, 0.5)",
        },
        red: {
          desc: "Passionate Red",
          colorCode: "rgba(139, 40, 41, 0.5)",
        },
      },

      renderingContainerCSS: {
        width: "30%",
        height: "70vh",
        zIndex: "0"
      },

      mattingThickness: {
        fourPly: {
          desc: "4 Ply",
          details: "A standard matting thickness. 4 paper layers are combined."
        },
      },

      matBoardQuality: {
        high: {
          desc: "Neutralized (pH 8.2)",
          details: "Neutralized and archival mat board",
        }
      },

      matBoardCore: {
        white: {
          desc: "White Core",
          details: "White Core Matboard",
        }
      },

      secondaryMattingThickness: {
        fourPly: {
          desc: "4 Ply",
          details: "A standard matting thickness. 4 paper layers are combined."
        },
      },

      frameColour: {
        white: {
          desc: "White",
          details: "Visualize with a white frame.",
          image: whiteFrame,
          imageWebp: whiteFrameWebp,
        },
        maple: {
          desc: "Maple",
          details: "Visualize with a maple frame.",
          image: mapleFrame,
          imageWebp: mapleFrameWebp,
        },
        golden: {
          desc: "Gold",
          details: "Visualize with a golden frame.",
          image: goldenFrame,
          imageWebp: goldenFrameWebp,
        },
        silver: {
          desc: "Silver",
          details: "Visualize with a silver frame.",
          image: silverFrame,
          imageWebp: silverFrameWebp,
        },
        black: {
          desc: "Black",
          details: "Visualize with a black frame.",
          image: blackFrame,
          imageWebp: blackFrameWebp,
        },
        lux: {
          desc: "Lux",
          details: "Visualize with a ornamental, museum frame.",
          image: ornamentalFrame,
          imageWebp: ornamentalFrameWebp,
        },
      },

      wallMaterial: {
        brick: {
          desc: "Brick",
          details: "Visualize a room decorated with wall paper.",
          image: brickWallPattern,
          imageWebp: brickWallPatternWebp,
        },
        cement: {
          desc: "Cement",
          details: "Visualize a painted wall.",
          image: cementWallPattern,
          imageWebp: cementWallPatternWebp,
        },
        industrial: {
          desc: "Industrial",
          details: "Visualize a modern retro, exposed brick wall.",
          image: industrialWallPattern,
          imageWebp: industrialWallPatternWebp,
        },
        paint: {
          desc: "Painted",
          details: "Visualize a hip and futuristic industrial wall.",
          image: paintedWallPattern,
          imageWebp: paintedWallPatternWebp,
        },
      },

      secondaryMattingOption: {
        in: {
          quarter: {
            desc: "1/4",
            floatValue: 0.25,
            details: "Select secondary matting as quarter inch.",
          },
          half: {
            desc: "1/2",
            floatValue: 0.5,
            details: "Select secondary matting as half inch.",
          },
          threeQuarter: {
            desc: "3/4",
            floatValue: 0.75,
            details: "Select secondary matting as 3/4 inch.",
          },
          one: {
            desc: "1",
            floatValue: 1,
            details: "Select secondary matting as 1 inch.",
          },
          fiveQuarters: {
            desc: "1.25",
            floatValue: 1.25,
            details: "Select secondary matting as 1.25 inch.",
          },
          oneHalf: {
            desc: "1.5",
            floatValue: 1.5,
            details: "Select secondary matting as 1.5 inch.",
          },
        },
        cm: {
          half: {
            desc: "0.5",
            floatValue: 0.5,
            details: "Select secondary matting as 0.5cm.",
          },
          one: {
            desc: "1",
            floatValue: 1,
            details: "Select secondary matting as 1cm.",
          },
          oneHalf: {
            desc: "1.5",
            floatValue: 1.5,
            details: "Select secondary matting as 1.5cm.",
          },
          two: {
            desc: "2",
            floatValue: 2,
            details: "Select secondary matting as 2cm.",
          },
          twoHalf: {
            desc: "2.5",
            floatValue: 2.5,
            details: "Select secondary matting as 2.5cm.",
          },
          three: {
            desc: "3",
            floatValue: 3,
            details: "Select secondary matting as 3cm.",
          },
        }
      },

      mattingPrice: 0,
      matCuttingPrice: 0,
      secondMattingPrice: 0,
      secondMatCuttingPrice: 0,
      shippingCosts: 0,
      taxes: 0,
      shippingDate: "",
      eta: "",

      showMatBoardWidthGuide: false,
      showMatBoardHeightGuide: false,
      showWindowWidthGuide: false,
      showWindowHeightGuide: false,

      showMatBoardAddnlInfo: false,
      showMatWindowAddnlInfo: false,
      showMatQualityAddnlInfo: false,
      showShippingPriceAddnlInfo: false,

    };

    this.handleChange = this.handleChange.bind(this);
  };

  componentDidMount() {

    //detecting if the user is using an IE
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    this.setState({isUserUsingIE: isIE});

    //adjusting multiplier based on the selected input. For 4K screens, we will have to change multipliers accordingly.
    let screenWidth = window.innerWidth;

    if (screenWidth > 1920) {
      this.adjustDefaultRenderingSize(40);
      let stateToBeUpdated = Object.assign({}, this.state.renderingContainerCSS);    //creating copy of object
      stateToBeUpdated.width = "40%";
      this.setState({renderingContainerCSS: stateToBeUpdated});
      // renderingContainerCSS
    } else if (screenWidth > 1450 && screenWidth < 1920) {
      this.adjustDefaultRenderingSize(25);
    } else if (screenWidth < 680) {
      let stateToBeUpdated = Object.assign({}, this.state.renderingContainerCSS);    //creating copy of object
      stateToBeUpdated.width = "calc(100% - 2rem)";
      this.setState({renderingContainerCSS: stateToBeUpdated})
      if (screenWidth < 414) {
        this.adjustDefaultRenderingSize(15);
      }
    }

    this.calculateDeliveryDates(new Date());
    var storedCurrency = localStorageFunctions("currency", null);
    if (storedCurrency !== null && storedCurrency !== undefined && storedCurrency !== "") {
      this.updateCurrencyToLocalState(storedCurrency);
    } else if (storedCurrency === null || storedCurrency === undefined || storedCurrency === "") {
      localStorageFunctions("currency", "CAD")
    }

    // window.addEventListener('storage', this.updateCurrencyToLocalState(comp-localStorage.getItem("currencySelected")));

    this.logToDBIfUserClickedNavBar();
  };

  logToDBIfUserClickedNavBar() {
    if (window.location.pathname === "/startDesigning") {
      analyticsDataToDB("navbar-button", "design-now-button-clicked");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.addSecondaryMattingClicked === false && this.state.showSecondaryMatting === true) {
      //In case secondary matting has been removed but div still showing the secondary matting.
      this.setState({showSecondaryMatting: false});
    }
  };

  updateCutOrUnCutMatboard() {
    this.setState({selectedUnCutMatBoard: !this.state.selectedUnCutMatBoard}, () => {
      if (this.state.selectedUnCutMatBoard === true) {
        this.setState({
          selectedMatWindowWidth: 0,
          selectedMatWindowHeight: 0,

          selectedMatWidthTop: 0,
          selectedMatWidthBottom: 0,
          selectedMatWidthLeft: 0,
          selectedMatWidthRight: 0,

          addSecondaryMattingClicked: false,

          selectedSecondaryMatWidthTop: 0,
          selectedSecondaryMatWidthBottom: 0,
          selectedSecondaryMatWidthLeft: 0,
          selectedSecondaryMatWidthRight: 0,
        }, () => {
          this.pricingCalculator();
        });
      }
    });

  };

  updateExpandRenderingToolStatus() {

    let screenWidth = window.innerWidth;

    this.setState({
      expandRenderingToolClicked: !this.state.expandRenderingToolClicked,
    }, () => {
      if (screenWidth > 1920) {
        this.state.expandRenderingToolClicked ? this.adjustDefaultRenderingSize(80): this.adjustDefaultRenderingSize(40);
      } else if (screenWidth > 1450 && screenWidth < 1920) {
        this.state.expandRenderingToolClicked ? this.adjustDefaultRenderingSize(50): this.adjustDefaultRenderingSize(25);
      } else {
        this.state.expandRenderingToolClicked ? this.adjustDefaultRenderingSize(40): this.adjustDefaultRenderingSize(20);
      }
    });

  };

  adjustDefaultRenderingSize(benchmarkLength) {
    //adjust default rendering size based on the screen resolution
    let stateToBeUpdated1 = Object.assign({}, this.state.frameSizeRenderingCSS);    //creating copy of object
    let stateToBeUpdated2 = Object.assign({}, this.state.matSizeRenderingCSS);    //creating copy of object
    let stateToBeUpdated3 = Object.assign({}, this.state.primaryMatWindowRenderingCSS);    //creating copy of object

    stateToBeUpdated1.width = `${benchmarkLength}rem`;
    stateToBeUpdated1.height = `${benchmarkLength * 0.7}rem`;
    stateToBeUpdated1.margin = `6rem calc( 50% - ${benchmarkLength / 2 + 1}rem)`;
    stateToBeUpdated2.width = `${benchmarkLength }rem`;
    stateToBeUpdated2.height = `${benchmarkLength * 0.7}rem`;

    stateToBeUpdated3.width = `auto`;
    stateToBeUpdated3.height = `${benchmarkLength * 0.7 - (benchmarkLength * 1/8 - 0.3) * 2}rem`;
    stateToBeUpdated3.margin = `${benchmarkLength * 1/8 - 0.3}rem`;

    this.setState({
      matRenderingMultiplier: benchmarkLength,
      frameSizeRenderingCSS: stateToBeUpdated1,
      matSizeRenderingCSS: stateToBeUpdated2,
      primaryMatWindowRenderingCSS: stateToBeUpdated3,
      renderingContainerCSS: {
        width: "calc(100% - 2rem)",
        height: "60vh",
      }
    });

  };

  updateCurrencyToLocalState(currency) {
    if (currency !== null && currency !== undefined) {
      this.setState({currencySelected: currency});
    }
  };

  changeCurrency() {
    localStorageFunctions("currency", this.state.currencySelected === "CAD" ? "USD" : "CAD");
    this.state.currencySelected === "CAD" ? this.setState({currencySelected: "USD"}) : this.setState({currencySelected: "CAD"});
    // this.state.currencySelected === "CAD" ? this.props.currencyCallbackFunction("USD") : this.props.currencyCallbackFunction("CAD");
  };

  updateHideTextButton(evt) {
    this.setState({
      clickedHideTextButton: !this.state.clickedHideTextButton
    })
  };

  adjustMattingWindowBasedOnFrameSizeValueEntered() {
    if (
      this.state.selectedMatBoardSizeWidth !== undefined && this.state.selectedMatBoardSizeHeight !== undefined &&
      this.state.selectedMatWindowWidth === undefined && this.state.selectedMatWindowHeight === undefined
    ) {
      let stateToBeUpdated = Object.assign({}, this.state.primaryMatWindowRenderingCSS);    //creating copy of object
      if (
        !Number.isNaN(parseFloat(this.state.matSizeRenderingCSS.width)) &&
        !Number.isNaN(parseFloat(this.state.matSizeRenderingCSS.height))
      ) {

        this.setState({primaryMatWindowRenderingCSS: stateToBeUpdated});
      }
    } else if (this.state.selectedMatBoardSizeWidth !== undefined && this.state.selectedMatBoardSizeHeight !== undefined &&
      this.state.selectedMatWindowWidth !== undefined && this.state.selectedMatWindowHeight !== undefined) {

      let stateToBeUpdated = Object.assign({}, this.state.primaryMatWindowRenderingCSS);

      this.setState({primaryMatWindowRenderingCSS: stateToBeUpdated});
    }
  };


  allocateUpdatedDimensionsToRendering(stateValueToUpdate) {

    var userEnteredDimensionCheck = checkMatBoardSize(this.state.selectedMatBoardSizeWidth, this.state.selectedMatBoardSizeHeight, this.state.selectedMatWindowWidth, this.state.selectedMatWindowHeight);


    if (stateValueToUpdate === "selectedMatBoardSizeWidth" || stateValueToUpdate === "selectedMatBoardSizeHeight") {
      let stateToBeUpdated1 = Object.assign({}, this.state.frameSizeRenderingCSS);    //creating copy of object

      if (this.state.selectedMatBoardSizeWidth > this.state.selectedMatBoardSizeHeight) {
        stateToBeUpdated1.width = `${this.state.matRenderingMultiplier}rem`;
        stateToBeUpdated1.height = `${this.state.selectedMatBoardSizeHeight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`;
        stateToBeUpdated1.margin = `4rem calc( 50% - ${this.state.matRenderingMultiplier/2+1}rem) 0`;

        if (userEnteredDimensionCheck[0] && userEnteredDimensionCheck[1]) {
         this.setState({
           frameSizeRenderingCSS: stateToBeUpdated1,
           renderingCentered: "width",
           matSizeRenderingCSS: {
             width: `${this.state.matRenderingMultiplier}rem`,
             height: `${this.state.selectedMatBoardSizeHeight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
             backgroundColor: this.state.mattingColour[this.state.selectedMatColour].colorCode,
           }
         }, () => this.adjustMattingWindowBasedOnFrameSizeValueEntered());
        }

        if (userEnteredDimensionCheck[1] && userEnteredDimensionCheck[2] && userEnteredDimensionCheck[3] && userEnteredDimensionCheck[4]) {
          this.setState({
            matSizeRenderingCSS: {
              width: `${this.state.matRenderingMultiplier}rem`,
              height: `${this.state.selectedMatBoardSizeHeight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              backgroundColor: this.state.mattingColour[this.state.selectedMatColour].colorCode,
            }
          }, () => this.adjustMattingWindowBasedOnFrameSizeValueEntered())
        }
      }

      else if (this.state.selectedMatBoardSizeWidth === this.state.selectedMatBoardSizeHeight) {
        stateToBeUpdated1.width = `${this.state.matRenderingMultiplier}rem`;
        stateToBeUpdated1.height = `${this.state.selectedMatBoardSizeHeight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`;
        stateToBeUpdated1.margin = `2.1rem calc( 50% - ${this.state.matRenderingMultiplier/2+1}rem) 0`;
        this.setState({
          matSizeRenderingCSS: {
            width: `${this.state.matRenderingMultiplier}rem`,
            height: `${this.state.selectedMatBoardSizeHeight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
            backgroundColor: this.state.mattingColour[this.state.selectedMatColour].colorCode,
          },
          frameSizeRenderingCSS: stateToBeUpdated1,
          renderingCentered: "width",
        }, () => {
          this.adjustMattingWindowBasedOnFrameSizeValueEntered();
        })
      }

      else {
        stateToBeUpdated1.width = `${this.state.selectedMatBoardSizeWidth / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`;
        stateToBeUpdated1.height = `${this.state.matRenderingMultiplier}rem`;
        stateToBeUpdated1.margin = `3rem calc( 50% - (${this.state.selectedMatBoardSizeWidth / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier / 2}rem + 1rem)) 0`;
        this.setState({
          matSizeRenderingCSS: {
            width: `${this.state.selectedMatBoardSizeWidth / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
            height: `${this.state.matRenderingMultiplier}rem`,
            backgroundColor: this.state.mattingColour[this.state.selectedMatColour].colorCode,
            // margin: `${this.state.selectedMatBoardSizeWidth / this.state.selectedMatBoardSizeHeight * 4}rem calc( 50% - ${this.state.selectedMatBoardSizeWidth / this.state.selectedMatBoardSizeHeight * 20 / 2}rem )`,
          },
          frameSizeRenderingCSS: stateToBeUpdated1,
          // frameSizeRenderingCSS: {
          //   width: `${this.state.selectedMatBoardSizeWidth / this.state.selectedMatBoardSizeHeight * 20}rem`,
          //   height: `20rem`,
          //   margin: `2.5rem calc( 50% - (${this.state.selectedMatBoardSizeWidth / this.state.selectedMatBoardSizeHeight * 20 / 2}rem + 1rem)) 0`
          // },
          renderingCentered: "height",
        }, () => this.adjustMattingWindowBasedOnFrameSizeValueEntered()
        )
      }
    }

    if (this.state.addSecondaryMattingClicked === false) {
      if (stateValueToUpdate === "selectedMatWindowWidth" || stateValueToUpdate === "selectedMatWindowHeight" || stateValueToUpdate === "selectedMatWidthTop" || stateValueToUpdate === "selectedMatWidthBottom" || stateValueToUpdate === "selectedMatWidthLeft" || stateValueToUpdate === "selectedMatWidthRight") {

        if (this.state.selectedMatBoardSizeWidth !== undefined && this.state.selectedMatBoardSizeHeight !== undefined && this.state.selectedMatBoardSizeWidth !== 0 && this.state.selectedMatBoardSizeHeight !== 0) {

        }

        if (this.state.renderingCentered === "width") {
          this.setState(
            {
              primaryMatWindowRenderingCSS: {
                width: `${this.state.selectedMatWindowWidth / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                height: `${this.state.selectedMatWindowHeight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                marginTop: `${this.state.selectedMatWidthTop / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                marginBottom: `${this.state.selectedMatWidthBottom / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                marginLeft: `${this.state.selectedMatWidthLeft / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                marginRight: `${this.state.selectedMatWidthRight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                backgroundColor: `${this.state.mattingColour[this.state.selectedSecondaryMattingColour].colorCode}`,
              }
            }
          )
        } else {
          this.setState(
            {
              primaryMatWindowRenderingCSS: {
                width: `${this.state.selectedMatWindowWidth / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                height: `${this.state.selectedMatWindowHeight / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                marginTop: `${this.state.selectedMatWidthTop / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                marginBottom: `${this.state.selectedMatWidthBottom / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                marginLeft: `${this.state.selectedMatWidthLeft / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                marginRight: `${this.state.selectedMatWidthRight / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                backgroundColor: `${this.state.mattingColour[this.state.selectedSecondaryMattingColour].colorCode}`,
              }
            }
          )
        }
      }
    };

    if (this.state.addSecondaryMattingClicked === true) {
      if (stateValueToUpdate === "selectedMatWindowWidth" || stateValueToUpdate === "selectedMatWindowHeight" || stateValueToUpdate === "selectedMatWidthTop" || stateValueToUpdate === "selectedMatWidthBottom" || stateValueToUpdate === "selectedMatWidthLeft" || stateValueToUpdate === "selectedMatWidthRight" || stateValueToUpdate === "selectedSecondaryMatWidth" || stateValueToUpdate === "selectedSecondaryMatWidthTop" || stateValueToUpdate === "selectedSecondaryMatWidthBottom" || stateValueToUpdate === "selectedSecondaryMatWidthLeft" || stateValueToUpdate === "selectedSecondaryMatWidthRight") {
        if (this.state.renderingCentered === "width") {
          this.setState(
            {
              primaryMatWindowRenderingCSS: {
                width: `${this.state.selectedMatWindowWidth / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                height: `${this.state.selectedMatWindowHeight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                marginTop: `${(this.state.selectedMatWidthTop - this.state.selectedSecondaryMatWidthTop) / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                marginBottom: `${(this.state.selectedMatWidthBottom - this.state.selectedSecondaryMatWidthBottom)  / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                marginLeft: `${(this.state.selectedMatWidthLeft - this.state.selectedSecondaryMatWidthLeft)  / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                marginRight: `${(this.state.selectedMatWidthRight - this.state.selectedSecondaryMatWidthRight)  / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                paddingTop: `${this.state.selectedSecondaryMatWidthTop / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                paddingBottom: `${this.state.selectedSecondaryMatWidthBottom / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                paddingLeft: `${this.state.selectedSecondaryMatWidthLeft / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                paddingRight: `${this.state.selectedSecondaryMatWidthRight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
                backgroundColor: `${this.state.mattingColour[this.state.selectedSecondaryMattingColour].colorCode}`,
              }
            }
          )
        } else {
          this.setState(
            {
              primaryMatWindowRenderingCSS: {
                width: `${this.state.selectedMatWindowWidth / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                height: `${this.state.selectedMatWindowHeight / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                marginTop: `${(this.state.selectedMatWidthTop - this.state.selectedSecondaryMatWidthTop) / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                marginBottom: `${(this.state.selectedMatWidthBottom - this.state.selectedSecondaryMatWidthBottom)  / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                marginLeft: `${(this.state.selectedMatWidthLeft - this.state.selectedSecondaryMatWidthLeft)  / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                marginRight: `${(this.state.selectedMatWidthRight - this.state.selectedSecondaryMatWidthRight)  / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                paddingTop: `${this.state.selectedSecondaryMatWidthTop / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                paddingBottom: `${this.state.selectedSecondaryMatWidthBottom / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                paddingLeft: `${this.state.selectedSecondaryMatWidthLeft / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                paddingRight: `${this.state.selectedSecondaryMatWidthRight / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
                backgroundColor: `${this.state.mattingColour[this.state.selectedSecondaryMattingColour].colorCode}`,
              }
            }
          )
        }
      }
      if (this.state.renderingCentered === "width") {
        this.setState(
          {
            primaryMatWindowRenderingCSS: {
              width: `${this.state.selectedMatWindowWidth / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              height: `${this.state.selectedMatWindowHeight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              marginTop: `${(this.state.selectedMatWidthTop - this.state.selectedSecondaryMatWidthTop) / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              marginBottom: `${(this.state.selectedMatWidthBottom - this.state.selectedSecondaryMatWidthBottom)  / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              marginLeft: `${(this.state.selectedMatWidthLeft - this.state.selectedSecondaryMatWidthLeft)  / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              marginRight: `${(this.state.selectedMatWidthRight - this.state.selectedSecondaryMatWidthRight)  / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              paddingTop: `${this.state.selectedSecondaryMatWidthTop / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              paddingBottom: `${this.state.selectedSecondaryMatWidthBottom / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              paddingLeft: `${this.state.selectedSecondaryMatWidthLeft / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              paddingRight: `${this.state.selectedSecondaryMatWidthRight / this.state.selectedMatBoardSizeWidth * this.state.matRenderingMultiplier}rem`,
              backgroundColor: `${this.state.mattingColour[this.state.selectedSecondaryMattingColour].colorCode}`,
            }
          }
        )
      } else {
        this.setState(
          {
            primaryMatWindowRenderingCSS: {
              width: `${this.state.selectedMatWindowWidth / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              height: `${this.state.selectedMatWindowHeight / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              marginTop: `${(this.state.selectedMatWidthTop - this.state.selectedSecondaryMatWidthTop) / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              marginBottom: `${(this.state.selectedMatWidthBottom - this.state.selectedSecondaryMatWidthBottom)  / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              marginLeft: `${(this.state.selectedMatWidthLeft - this.state.selectedSecondaryMatWidthLeft)  / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              marginRight: `${(this.state.selectedMatWidthRight - this.state.selectedSecondaryMatWidthRight)  / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              paddingTop: `${this.state.selectedSecondaryMatWidthTop / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              paddingBottom: `${this.state.selectedSecondaryMatWidthBottom / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              paddingLeft: `${this.state.selectedSecondaryMatWidthLeft / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              paddingRight: `${this.state.selectedSecondaryMatWidthRight / this.state.selectedMatBoardSizeHeight * this.state.matRenderingMultiplier}rem`,
              backgroundColor: `${this.state.mattingColour[this.state.selectedSecondaryMattingColour].colorCode}`,
            }
          }
        )
      }
    };
  };

  changeSelectedShippingProvinceToNull(state) {
    // in case of US selection // All states flat rate shipping within USA
    this.setState({selectedDestinationProvince: state}, () => {
    });
  };

  updateSelectedShippingCountry(evt) {
    this.setState({selectedDestinationCountry: evt.target.value},
      () => {
        this.pricingCalculator();
        if( this.state.selectedDestinationCountry === "US" ) {
          this.changeSelectedShippingProvinceToNull(null);
        } else if (this.state.selectedDestinationCountry === "Canada") {
          this.changeSelectedShippingProvinceToNull("BC");
        }
      }
    );
  };

  updateSelectedShippingProvince(evt) {
    this.setState({selectedDestinationProvince: evt.target.value});
  };

  showCountryProvinceSelection(countryOrProvince) {
    var shippingDestinationList;
    if (countryOrProvince === "country") {
      shippingDestinationList = Object.keys(this.state.shippingDestinations);
    } else {
      shippingDestinationList = Object.keys(this.state.shippingDestinations.Canada);
    }
    return <form>
      <FormControl variant={"filled"}>
        <Select
          id={"shipping-destination-country"}
          onChange={countryOrProvince === "country" ? (evt) => {
            this.updateSelectedShippingCountry(evt)
          } : (evt) => {
            this.updateSelectedShippingProvince(evt)
          }}
          value={countryOrProvince === "country" ? this.state.selectedDestinationCountry : this.state.selectedDestinationProvince}
        >
          {shippingDestinationList.reverse().map((key, index) => {
            return <MenuItem value={key} key={`dest-country-${key}-${index}`}>{key}</MenuItem>
          })}
        </Select>
      </FormControl>
    </form>
  };

  updateOptionValue(stateValueToUpdate, value) {
    this.setState({[stateValueToUpdate]: value}, () => {
      if (stateValueToUpdate === "renderFrameType") {
        let stateToBeUpdated = Object.assign({}, this.state.frameSizeRenderingCSS);    //creating copy of object


        if (this.state.isUserUsingIE) {
          stateToBeUpdated.backgroundImage = `url(${this.state.frameColour[value].image})`;
        } else {
          stateToBeUpdated.backgroundImage = `url(${this.state.frameColour[value].imageWebp})`;
        }

        this.setState({frameSizeRenderingCSS: stateToBeUpdated});
      }
      //also update comp-pricing calculator if units changed.
      if (stateValueToUpdate === "selectedMeasurementUnits") {
        this.pricingCalculator();
      }
    });
  };

  updateSecondaryMattingOptionValues(stateValueToUpdate, value) {
    this.setState({
      selectedSecondaryMatWidthTop: value,
      selectedSecondaryMatWidthBottom: value,
      selectedSecondaryMatWidthLeft: value,
      selectedSecondaryMatWidthRight: value,
    }, () => {
      this.allocateUpdatedDimensionsToRendering(stateValueToUpdate);
    })
  };

  showOptionButtons(stateOptionsValues, stateValueToUpdate) {
    if (stateValueToUpdate === "selectedSecondaryMatWidth") {
      var optionsList = Object.keys(this.state[stateOptionsValues][this.state.selectedMeasurementUnits]);
      return optionsList.reverse().map((key, index) => {
          if (this.state[stateOptionsValues][this.state.selectedMeasurementUnits][key].floatValue < (this.state.selectedMatBoardSizeWidth - this.state.selectedMatWindowWidth)/2 &&
            this.state[stateOptionsValues][this.state.selectedMeasurementUnits][key].floatValue < (this.state.selectedMatBoardSizeHeight - this.state.selectedMatWindowHeight)/2 ) {
            return <div
              className={`app-text-based-option app-button ${this.state.selectedSecondaryMattingOption === key ? "app-button-selected" : "app-button-unselected"}`}
              key={`mat-board-width${key}-${index}`}
              onClick={(evt) => {
                this.setState({selectedSecondaryMattingOption: key});
                this.updateSecondaryMattingOptionValues("selectedSecondaryMatWidth", this.state[stateOptionsValues][this.state.selectedMeasurementUnits][key]["floatValue"]);
              }} title={this.state[stateOptionsValues][this.state.selectedMeasurementUnits][key].details}>
              {this.state[stateOptionsValues][this.state.selectedMeasurementUnits][key].desc}
            </div>
          }

      })
    } else {
      optionsList = Object.keys(this.state[stateOptionsValues]);
      return optionsList.reverse().map((key, index) => {
        return <div
          className={`app-text-based-option app-button ${this.state[stateValueToUpdate] === key ? "app-button-selected" : "app-button-unselected"}`}
          key={`app-button-${key}-${index}`}
          onClick={(evt) => {
            this.updateOptionValue(stateValueToUpdate, key);
          }}
          title={this.state[stateOptionsValues][key].details}>
          {this.state[stateOptionsValues][key].desc}
        </div>
      })
    }
  };

  updateMatBoardSizing(evt, stateValueToUpdate) {

    this.setState({
      //update the user entered value
      [stateValueToUpdate]: parseFloat(evt.target.value)
    }, () => {
      var matBoardSizeCheckArray = checkMatBoardSize(this.state.selectedMatBoardSizeWidth, this.state.selectedMatBoardSizeHeight, this.state.selectedMatWindowWidth, this.state.selectedMatWindowHeight);

      if (matBoardSizeCheckArray[0] === true && matBoardSizeCheckArray[1] === true) {
        this.autoCalculateMattingWidthController(stateValueToUpdate);
        this.allocateUpdatedDimensionsToRendering(stateValueToUpdate);
        this.pricingCalculator();
        // this.updateCenterMyArtwork();
        // this.adjustMattingWindowBasedOnFrameSizeValueEntered();

      } else {
        // value is not valid, so stay with whatever that they hav enow
      }

    });
  };

  autoCalculateMattingWidthController(stateValueToUpdate) {
    var arrayForNaNCheck = [this.state.selectedMatBoardSizeWidth, this.state.selectedMatBoardSizeHeight, this.state.selectedMatWindowWidth, this.state.selectedMatWindowHeight];
    this.turnOnOrOffCenterMyArtworkButton();

    if (this.state.selectedMatBoardSizeWidth !== undefined &&
      this.state.selectedMatBoardSizeHeight !== undefined &&
      this.state.selectedMatWindowWidth !== undefined &&
      this.state.selectedMatWindowHeight !== undefined) {
      this.autoCalculateMattingWidthOne();
    } else if (arrayForNaNCheck.findIndex(Number.isNaN) === -1) {
      this.autoCalculateMattingWidthTwo(stateValueToUpdate);
    } else if (stateValueToUpdate === "selectedMatWidthTop" || stateValueToUpdate === "selectedMatWidthBottom" || stateValueToUpdate === "selectedMatWidthLeft" || stateValueToUpdate === "selectedMatWidthRight") {
      this.allocateUpdatedDimensionsToRendering(stateValueToUpdate);
    }
  };

  checkIfWindowAndMattingIsProper(stateValueToUpdate) {
    var sumEnteredValuesWidth = this.state.selectedMatWidthLeft + this.state.selectedMatWidthRight + this.state.selectedMatWindowWidth;
    var sumEnteredValuesHeight = this.state.selectedMatWidthTop + this.state.selectedMatWidthBottom + this.state.selectedMatWindowHeight;
    var warningMessage1 = "Window opening cannot be larger than matboard.";
    var warningMessage2 = "Matting width cannot be smaller than zero.";
    var warningMessage3 = "Window Size + Matting Left + Right should equal to Mat Board Size.";
    var warningMessage4 = "Mat board width cannot be 0.";
    var warningMessage5 = "We cannot deliver mat board size that is larger than 40 in.";
    var warningMessage6 = "We cannot deliver mat board size that is larger than 102 cm.";
    // var warningMessage7 = "The order needs to be bigger than 4 in or 10cm";

    if (stateValueToUpdate === "selectedMatWindowWidth" && this.state.selectedMatBoardSizeWidth - this.state.selectedMatWindowWidth <= 0) {
      return <div className={"incorrect-value-warnings"}>{warningMessage1}</div>
    } else if (stateValueToUpdate === "selectedMatWindowHeight" && this.state.selectedMatBoardSizeWidth - this.state.selectedMatWindowWidth >= 0 && this.state.selectedMatBoardSizeHeight - this.state.selectedMatWindowHeight <= 0) {
      return <div className={"incorrect-value-warnings"}>{warningMessage1}</div>
    } else if (stateValueToUpdate === "selectedMatWidthTop" && this.state.selectedMatWidthTop < 0) {
      return <div className={"incorrect-value-warnings"}>{warningMessage2}</div>
    } else if (stateValueToUpdate === "selectedMatWidthLeft" && !isNaN(sumEnteredValuesWidth) && !isNaN(sumEnteredValuesHeight)) {
      if (sumEnteredValuesWidth !== this.state.selectedMatBoardSizeWidth) {
        return <div className={"incorrect-value-warnings"}>{warningMessage3}</div>
      }
    } else if (stateValueToUpdate === "selectedMatWidthTop" && !isNaN(sumEnteredValuesWidth) && !isNaN(sumEnteredValuesHeight)) {
      if (sumEnteredValuesHeight !== this.state.selectedMatBoardSizeHeight) {
        return <div className={"incorrect-value-warnings"}>{warningMessage3}</div>
      }
    } else if (
      (stateValueToUpdate === "selectedMatBoardSizeWidth" && this.state.selectedMatBoardSizeWidth <= 0) ||
      (stateValueToUpdate === "selectedMatBoardSizeHeight" && this.state.selectedMatBoardSizeHeight <= 0)
    ) {
      return <div className={"incorrect-value-warnings"}>{warningMessage4}</div>
    } else if (
      (stateValueToUpdate === "selectedMatBoardSizeWidth" && this.state.selectedMatBoardSizeWidth >= 40 && this.state.selectedMeasurementUnits === "in") ||
      (stateValueToUpdate === "selectedMatBoardSizeHeight" && this.state.selectedMatBoardSizeHeight >= 40 && this.state.selectedMeasurementUnits === "in")
    ) {
      return <div className={"incorrect-value-warnings"}>{warningMessage5}</div>
    } else if (
      (stateValueToUpdate === "selectedMatBoardSizeWidth" && this.state.selectedMatBoardSizeWidth >= 102 && this.state.selectedMeasurementUnits === "cm") ||
      (stateValueToUpdate === "selectedMatBoardSizeHeight" && this.state.selectedMatBoardSizeHeight >= 102 && this.state.selectedMeasurementUnits === "cm")
    ) {
      return <div className={"incorrect-value-warnings"}>{warningMessage6}</div>
    }
  };

  autoCalculateMattingWidthOne() {
    // based on the value of Matboard size and window opening, it automatically centers the artwork.
    var matWidthTopBottom = (this.state.selectedMatBoardSizeHeight - this.state.selectedMatWindowHeight) / 2;
    var matWidthLeftRight = (this.state.selectedMatBoardSizeWidth - this.state.selectedMatWindowWidth) / 2;

    this.setState({
        selectedMatWidthTop: matWidthTopBottom,
        selectedMatWidthBottom: matWidthTopBottom,
        selectedMatWidthLeft: matWidthLeftRight,
        selectedMatWidthRight: matWidthLeftRight,
      }, () => {
        this.allocateUpdatedDimensionsToRendering("selectedMatWindowHeight");
      }
    )
  };

  autoCalculateMattingWidthTwo(stateValueToUpdate) {
    // based on the value of the matting width left and top, it calculates the values for matting right and bottom
    if (stateValueToUpdate === "selectedMatWidthLeft") {
      var calcMatWidthRightValue = this.state.selectedMatBoardSizeWidth - this.state.selectedMatWindowWidth - this.state.selectedMatWidthLeft;
      this.setState({
        selectedMatWidthRight: calcMatWidthRightValue,
      }, () => {
        this.turnOnOrOffCenterMyArtworkButton();
        this.allocateUpdatedDimensionsToRendering("selectedMatWidthLeft");
      })
    } else if (stateValueToUpdate === "selectedMatWidthTop") {
      var calcMatWidthRightBottom = this.state.selectedMatBoardSizeHeight - this.state.selectedMatWindowHeight - this.state.selectedMatWidthTop;
      this.setState({
        selectedMatWidthBottom: calcMatWidthRightBottom,
      }, () => {
        this.turnOnOrOffCenterMyArtworkButton();
        this.allocateUpdatedDimensionsToRendering("selectedMatWidthTop");
      })
    }
  };



  checkIfSectionWarningNeeded(stateValueToUpdate) {

    //This section shows warning signs for improper values
    var sumEnteredValuesWidth = this.state.selectedMatWidthLeft + this.state.selectedMatWidthRight + this.state.selectedMatWindowWidth;
    var sumEnteredValuesHeight = this.state.selectedMatWidthTop + this.state.selectedMatWidthBottom + this.state.selectedMatWindowHeight;

    if (stateValueToUpdate === "selectedMatWindowWidth" && this.state.selectedMatBoardSizeWidth - this.state.selectedMatWindowWidth <= 0) {
      return "input-section-highlight-warning"
    } else if (stateValueToUpdate === "selectedMatWindowHeight" && this.state.selectedMatBoardSizeHeight - this.state.selectedMatWindowHeight <= 0) {
      return "input-section-highlight-warning"
    } else if (stateValueToUpdate === "selectedMatWidthTop" || stateValueToUpdate === "selectedMatWidthBottom") {
      if (!isNaN(sumEnteredValuesWidth) && !isNaN(sumEnteredValuesHeight)) {
        if (sumEnteredValuesHeight !== this.state.selectedMatBoardSizeHeight) {
          return "input-section-highlight-warning"
        }
      }
    } else if (stateValueToUpdate === "selectedMatWidthLeft" || stateValueToUpdate === "selectedMatWidthRight") {
      if (!isNaN(sumEnteredValuesWidth) && !isNaN(sumEnteredValuesHeight)) {
        if (sumEnteredValuesWidth !== this.state.selectedMatBoardSizeWidth) {
          return "input-section-highlight-warning"
        }
      }
    } else if (stateValueToUpdate === "selectedMatBoardSizeWidth" && this.state.selectedMatBoardSizeWidth <= 0) {
      return "input-section-highlight-warning"
    } else if (stateValueToUpdate === "selectedMatBoardSizeHeight" && this.state.selectedMatBoardSizeHeight <= 0) {
      return "input-section-highlight-warning"
    } else if (stateValueToUpdate === "selectedMatWidthTop" && this.state.selectedMatWidthTop < 0) {
      return "input-section-highlight-warning"
    } else if (stateValueToUpdate === "selectedMatWidthBottom" && this.state.selectedMatWidthBottom < 0) {
      return "input-section-highlight-warning"
    } else if (stateValueToUpdate === "selectedMatWidthLeft" && this.state.selectedMatWidthLeft < 0) {
      return "input-section-highlight-warning"
    } else if (stateValueToUpdate === "selectedMatWidthRight" && this.state.selectedMatWidthRight < 0) {
      return "input-section-highlight-warning"
    }
  };

  checkWhichFieldToHighlight(stateValueToUpdate, trueOrFalse) {
    if (stateValueToUpdate === "selectedMatBoardSizeWidth") {
      this.setState({showMatBoardWidthGuide: trueOrFalse})
    } else if (stateValueToUpdate === "selectedMatBoardSizeHeight") {
      this.setState({showMatBoardHeightGuide: trueOrFalse})
    } else if (stateValueToUpdate === "selectedMatWindowWidth") {
      this.setState({showWindowWidthGuide: trueOrFalse})
    } else if (stateValueToUpdate === "selectedMatWindowHeight") {
      this.setState({showWindowHeightGuide: trueOrFalse})
    } else {
      this.setState({highlightMatSection: trueOrFalse});
    }
  }

  showMatBoardSizingOptions(divClassName, widthOrHeight, stateValueToUpdate) {
    return <div className={divClassName}>
      <TextField
        className={this.checkIfSectionWarningNeeded(stateValueToUpdate)}
        placeholder={widthOrHeight}
        type={"number"}
        value={this.state[stateValueToUpdate]}
        onChange={(evt) => {
          this.updateMatBoardSizing(evt, stateValueToUpdate);
          analyticsDataToDB("design-app", `${stateValueToUpdate}-entered`);
        }}
        onMouseEnter={(evt => {
          this.checkWhichFieldToHighlight(stateValueToUpdate, true);
        })}
        onMouseLeave={(evt => {
          this.checkWhichFieldToHighlight(stateValueToUpdate, false);
        })}
        InputProps={{
          endAdornment: <InputAdornment position={"end"}>{this.state.selectedMeasurementUnits}</InputAdornment>,
        }}/>
      {this.checkIfWindowAndMattingIsProper(stateValueToUpdate)}
    </div>
  };

  showMatBoardInputMultiplicationSign() {
    return <div className={"mat-board-size-input-multiplying-sign"}>
      X
    </div>
  };

  turnOnOrOffCenterMyArtworkButton() {
    if (this.state.selectedMatWidthLeft === this.state.selectedMatWidthRight && this.state.selectedMatWidthTop === this.state.selectedMatWidthBottom) {
      this.setState({selectedCenterMyArtwork: true});
    } else {
      this.setState({selectedCenterMyArtwork: false});
    }
  };

  updateCenterMyArtwork(evt) {
    this.setState({
      selectedCenterMyArtwork: !this.state.selectedCenterMyArtwork
    });

    if (
      this.state.selectedMatBoardSizeWidth !== undefined &&
      this.state.selectedMatBoardSizeHeight !== undefined &&
      this.state.selectedMatWindowWidth !== undefined &&
      this.state.selectedMatWindowHeight !== undefined
    ) {
      this.setState({
        selectedMatWidthTop: (this.state.selectedMatBoardSizeHeight - this.state.selectedMatWindowHeight) / 2,
        selectedMatWidthBottom: (this.state.selectedMatBoardSizeHeight - this.state.selectedMatWindowHeight) / 2,
        selectedMatWidthLeft: (this.state.selectedMatBoardSizeWidth - this.state.selectedMatWindowWidth) / 2,
        selectedMatWidthRight: (this.state.selectedMatBoardSizeWidth - this.state.selectedMatWindowWidth) / 2,
      }, () => {
        this.autoCalculateMattingWidthController("selectedMatWidthRight");
      });
    };

  };

  updateColourOptions(colourKey, stateValueToUpdate, colourRGBCode) {
    if (stateValueToUpdate === "selectedMatColour") {
      //here is where primary mat colour key gets updated
      this.setState({[stateValueToUpdate]: colourKey},
        () => {
          let stateToBeUpdated = Object.assign({}, this.state.matSizeRenderingCSS);    //cr
          // eating copy of object
          stateToBeUpdated.backgroundColor = colourRGBCode;
          this.setState(
            {matSizeRenderingCSS: stateToBeUpdated}
          );
        }
      );
    } else if (stateValueToUpdate === "renderWallColour") {
        this.setState({[stateValueToUpdate]: colourKey});
    } else if (stateValueToUpdate === "selectedSecondaryMattingColour") {
      this.setState({[stateValueToUpdate]: colourKey}, () => {
        let stateToBeUpdated = Object.assign({}, this.state.primaryMatWindowRenderingCSS);    //creating copy of object
        stateToBeUpdated.backgroundColor = colourRGBCode;
        this.setState(
          {
            primaryMatWindowRenderingCSS: stateToBeUpdated,
          }
        );
      });
    }
  };

  showColourOptions(mattingOrRendering, stateValueToUpdate) {
    var colourList = Object.keys(this.state[mattingOrRendering]);
    return colourList.map((key, index) => {
      return <div
        key={`matting-colour-${key}-${index}`}
        className={`matting-colour-selector-item app-button`}
        title={this.state[mattingOrRendering][key].desc}
        style={{backgroundColor: this.state[mattingOrRendering][key].colorCode}}
        onClick={(evt) => {
          this.updateColourOptions(key, stateValueToUpdate, this.state[mattingOrRendering][key].colorCode);
        }}
      >
        <img
          className={this.state[stateValueToUpdate] === key ? "selected-colour" : "unselected-colour"}
          alt={`Click ${stateValueToUpdate} for colour`}
          src={key === "black" || key === "red" ? checkIconLight : checkIconDark}/>
      </div>
    });
  };

  showMatBoardAdditionalInfo(text, action) {
    // showMatBoardAddnlInfo
    if (text === "Mat Board") {
      this.setState({showMatBoardAddnlInfo: action});
    } else if (text === "Artwork (Window)") {
      this.setState({showMatWindowAddnlInfo: action});
    } else if (text === "Quality") {
      this.setState({showMatQualityAddnlInfo: action});
    } else if (text === "Ship to") {
      // this.setState({showShippingPriceAddnlInfo: action});
    }
  };

  showAppSelectorHeader(text, specialClassName) {
    return <div className={`mat-board-size-selector-header app-selector-header ${specialClassName}`}>
      {text}
      {
        text === "Mat Board" || text === "Artwork (Window)" || text === "Quality" ?
          <a href="/measuring-your-matboards" target={"_blank"} rel="noopener noreferrer">
            <div className={"question-mark-icon-container"}>
              <img className={"question-mark-icon"} src={questionIcon} alt={"Click here if you have any questions."}
              />
            </div>
          </a> : ""
      }
    </div>
  };

  updateSecondaryMatting() {
    this.setState({
      addSecondaryMattingClicked: !this.state.addSecondaryMattingClicked,
      selectedSecondaryMatWidthTop: undefined,
      selectedSecondaryMatWidthBottom: undefined,
      selectedSecondaryMatWidthLeft: undefined,
      selectedSecondaryMatWidthRight: undefined,
      primaryMatWindowRenderingCSS: {
        width: this.state.primaryMatWindowRenderingCSS.width,
        height: this.state.primaryMatWindowRenderingCSS.height,
        marginTop: this.state.primaryMatWindowRenderingCSS.marginTop,
        marginBottom: this.state.primaryMatWindowRenderingCSS.marginBottom,
        marginLeft: this.state.primaryMatWindowRenderingCSS.marginLeft,
        marginRight: this.state.primaryMatWindowRenderingCSS.marginRight,
        padding: 0,
      }
    }, () => {
      this.pricingCalculator();
    });
    this.updateShowSecondaryMatting();
  };

  updateShowSecondaryMatting() {
    this.setState({showSecondaryMatting: !this.state.showSecondaryMatting});
  };

  updateManuallyEnterSecondaryMatting() {
    this.setState({manuallyEnterSecondaryMattingClicked: !this.state.manuallyEnterSecondaryMattingClicked});
  };

  handleChange(event) {
    let stateToBeUpdated = Object.assign({}, this.state.secondaryMatWindowRenderingCSS);    //creating copy of object
    stateToBeUpdated.backgroundImage = `url(${URL.createObjectURL(event.target.files[0])})`;

    // secondaryMatWindowRenderingCSS
    this.setState({
      secondaryMatWindowRenderingCSS: stateToBeUpdated,
      uploadedArtworkImage: true,
    })
  };

  updatePricingState(matting, cutting, shipping, taxes) {

    if(this.state.selectedMatBoardSizeWidth !== undefined && this.state.selectedMatBoardSizeWidth !== 0) {
      if(this.state.selectedMatBoardSizeHeight !== undefined && this.state.selectedMatBoardSizeHeight !== 0) {
        this.setState({
          mattingPrice: matting,
          shippingCosts: shipping,
          taxes: taxes,
        });
      }
    } else {
      this.setState({
        mattingPrice: 0,
        matCuttingPrice: 0,
        shippingCosts: 0,
        taxes: 0,
      });
    }

    if(this.state.selectedMatWindowWidth !== undefined && this.state.selectedMatWindowWidth !== 0) {
      if(this.state.selectedMatWindowHeight !== undefined && this.state.selectedMatWindowHeight !== 0) {
        this.setState({
          matCuttingPrice: cutting,
        });
      }
    } else {
      this.setState({
        matCuttingPrice: 0,
      });
    }

    if(this.state.addSecondaryMattingClicked) {
      this.setState({
        secondMattingPrice: matting * secondMattingFactorialPrice,
        secondMatCuttingPrice: cutting * secondMattingFactorialPrice,
      });
    } else if (this.state.addSecondaryMattingClicked === false) {
      this.setState({
        secondMattingPrice: 0,
        secondMatCuttingPrice: 0,
      });
    }
  };

  pricingCalculator() {
    var width = this.state.selectedMatBoardSizeWidth;
    var height = this.state.selectedMatBoardSizeHeight;
    var areaSize = width * height;
    var unit = this.state.selectedMeasurementUnits;
    var country = this.state.selectedDestinationCountry;

    var extraSmallDimension = pricingElements.extraSmall.maxSize[unit];
    var smallDimension = pricingElements.small.maxSize[unit];
    var mediumDimension = pricingElements.medium.maxSize[unit];

    var priceMultipleSmall, priceMultipleMedium, priceMultipleLarge;

    if (unit === "in") {
      priceMultipleSmall = 4.9/3;
      priceMultipleMedium = 4.9/3.25;
      priceMultipleLarge = 4.9/3.5;
    } else if (unit === "cm") {
      priceMultipleSmall = 0.749/3;
      priceMultipleMedium = 0.749/3.25;
      priceMultipleLarge = 0.749/3.5;
    }

    //new pricing mechanism

    if (areaSize < extraSmallDimension) {
      this.updatePricingState((areaSize / 100 * priceMultipleSmall + 4.5).toFixed(0) - 0.01, pricingElements.extraSmall.prices.cuttingPrice, pricingElements.extraSmall.shippingCost[country], 0 )
    } else if (extraSmallDimension < areaSize && areaSize <= smallDimension) {
      this.updatePricingState((areaSize / 100 * priceMultipleMedium + 4).toFixed(0) - 0.01, pricingElements.small.prices.cuttingPrice, pricingElements.small.shippingCost[country], 0 )
    } else if (smallDimension < areaSize && areaSize <= mediumDimension) {
      this.updatePricingState((areaSize / 100 * priceMultipleMedium).toFixed(0) - 0.01, pricingElements.medium.prices.cuttingPrice, pricingElements.medium.shippingCost[country], 0 )
    } else {
      this.updatePricingState((areaSize / 100 * priceMultipleLarge).toFixed(0) - 0.01, pricingElements.large.prices.cuttingPrice, pricingElements.large.shippingCost[country], 0 )
    }
  };

  finalFormSubmissionCheck() {
    var sumEnteredValuesWidth = this.state.selectedMatWidthLeft + this.state.selectedMatWidthRight + this.state.selectedMatWindowWidth;
    var sumEnteredValuesHeight = this.state.selectedMatWidthTop + this.state.selectedMatWidthBottom + this.state.selectedMatWindowHeight;

    if (this.state.selectedMatWindowWidth === undefined && this.state.selectedMatWindowHeight === undefined ) {
      sumEnteredValuesWidth = this.state.selectedMatBoardSizeWidth;
      sumEnteredValuesHeight = this.state.selectedMatBoardSizeHeight;
    }

    if (this.state.selectedUnCutMatBoard === true && this.state.selectedMatBoardSizeWidth > 0 && this.state.selectedMatBoardSizeHeight > 0) {
      return true;
    }

    if (this.state.selectedMeasurementUnits === "in" &&  this.state.selectedMatBoardSizeWidth < 40 &&  this.state.selectedMatBoardSizeHeight < 40) {
      if (
        sumEnteredValuesWidth === this.state.selectedMatBoardSizeWidth
        && sumEnteredValuesHeight === this.state.selectedMatBoardSizeHeight
        && this.state.selectedMatBoardSizeWidth !== this.state.selectedMatWindowWidth
        && this.state.selectedMatBoardSizeHeight !== this.state.selectedMatWindowHeight
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.state.selectedMeasurementUnits === "cm" &&  this.state.selectedMatBoardSizeWidth < 102 &&  this.state.selectedMatBoardSizeHeight < 102) {
      if (
        sumEnteredValuesWidth === this.state.selectedMatBoardSizeWidth
        && sumEnteredValuesHeight === this.state.selectedMatBoardSizeHeight
        && this.state.selectedMatBoardSizeWidth !== this.state.selectedMatWindowWidth
        && this.state.selectedMatBoardSizeHeight !== this.state.selectedMatWindowHeight
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  moveToPaymentPage() {
    if (this.finalFormSubmissionCheck()) {
      var ordersToStore = localStorageFunctions("a1ysQM3xOEe9nGpQg5D5g", null);
      if (ordersToStore === null) {
        ordersToStore = [];
      };
      var secondaryMattingWidth = this.state.selectedSecondaryMatWidthTop ? this.state.selectedSecondaryMatWidthTop : "Not Selected";
      var secondaryMattingColour = this.state.selectedSecondaryMatWidthTop ? this.state.selectedSecondaryMattingColour : "Not Selected";;

      if(this.state.selectedMatBoardSizeWidth === 0 || this.state.selectedMatBoardSizeWidth === "" || this.state.selectedMatBoardSizeWidth === undefined) {
        alert("Please add mat board dimensions.")
      } else {
        ordersToStore.push({
          // PRIMARY MATTING INFO
          aMeasuringUnit: this.state.selectedMeasurementUnits,
          bPrimaryMatWidth: this.state.selectedMatBoardSizeWidth,
          cPrimaryMatHeight: this.state.selectedMatBoardSizeHeight,
          dPrimaryWindowWidth: this.state.selectedMatWindowWidth,
          ePrimaryWindowHeight: this.state.selectedMatWindowHeight,
          fPrimaryWindowTop: this.state.selectedMatWidthTop,
          gPrimaryWindowBottom: this.state.selectedMatWidthBottom,
          hPrimaryWindowLeft: this.state.selectedMatWidthLeft,
          iPrimaryWindowRight: this.state.selectedMatWidthRight,
          jPrimaryColour: this.state.selectedMatColour,
          kPrimaryMattingPrice: this.state.mattingPrice,
          lMatCuttingPrice: this.state.matCuttingPrice,
          // SECOND MATTING INFO
          mSecondaryMatWidth: secondaryMattingWidth,
          nSecondaryMatColour: secondaryMattingColour,
          oSecondMattingPrice: this.state.secondMattingPrice,
          pSecondMatCuttingPrice: this.state.secondMatCuttingPrice,
          // ITEM QUANTITY
          qItemQuantity: 1,
          // GENERAL SHIPPING INFO
          rShippingCost: this.state.shippingCosts,
          sTaxes: this.state.taxes,
          tEta: this.state.eta,
        });
        localStorageFunctions("a1ysQM3xOEe9nGpQg5D5g", ordersToStore, "encObj");
        localStorageFunctions("selectedDestinationCountry", this.state.selectedDestinationCountry, "encObj");
        localStorageFunctions("shippingAddressProvince", this.state.selectedDestinationProvince, "encObj");
        window.location.href = "/payments";
      }
    } else {
      alert("There's an error in mat board dimensions. Please check the error messages in red.")
    }
  };

  calculateDeliveryDates(currentDate) {
    var shippingDate;
    var deliveryDate;

    if (currentDate.getDay() === 5) {

      if (currentDate.getHours() >= 15) {
        shippingDate = "next Monday"
        deliveryDate = "4 to 8 days"
        this.updateDeliveryDates(shippingDate, deliveryDate);
      }
    } else if (currentDate.getDay() > 5) {
      shippingDate = "next Monday"
      deliveryDate = "4 to 8 days"
      this.updateDeliveryDates(shippingDate, deliveryDate);
    } else {
      if (currentDate.getHours() > 15) {
        shippingDate = "tomorrow"
        deliveryDate = "2 to 6 days"
        this.updateDeliveryDates(shippingDate, deliveryDate);
      } else {
        shippingDate = "today"
        deliveryDate = "1 to 6 days"
        this.updateDeliveryDates(shippingDate, deliveryDate);
      }
    }
  };

  updateDeliveryDates(shippingDate, eta) {
    this.setState({
      shippingDate: shippingDate,
      eta: eta,
    });
  };

  showAnswersToQuestionMarks() {
    return <div>

      <div className={this.state.showMatBoardAddnlInfo ? "question-mark-content" : "no-display" }>
        How to measure mat board size?
        <div className="gallery-section-content-highlights">
          Option 1: Refer to the frame dimensions provided by the manufacturer.
        </div>
        <div className="content-paragraph">
          The easiest way to get the mat board size would be using the seller provided information. You can get the
          numbers from the frame wrapper or the seller website.
        </div>


      </div>
      <div className={this.state.showMatWindowAddnlInfo ? "question-mark-content" : "no-display" }>
        How to measure window size?
      </div>
      <div className={this.state.showMatQualityAddnlInfo ? "question-mark-content" : "no-display" }>
        Our mat board quality
      </div>
      <div className={this.state.showShippingPriceAddnlInfo ? "question-mark-content" : "no-display" }>
        Our shipping price
      </div>
    </div>
  };

  restartPage() {
    window.location.href="/";
  };

  render() {
    return <div className="canvas-app-container">
      {this.showAnswersToQuestionMarks()}

      <div className={"canvas-app-mat-dimensions"} id={"canvas-app-primary-mat-dimensions"}>
        <div className={"dimensions-unit-selector-container"}>
          {this.showOptionButtons("measuringUnitOptions", "selectedMeasurementUnits")}
        </div>
        <div className={"primary-matting-dimensions-container"}>
          Primary Mat Dimensions
        </div>
        <form noValidate autoComplete="off">
          <div className={`matting-dimensions-container ${this.state.showSecondaryMatting ? "no-display" : "display"}`}>
            <div className={"mat-board-size-selector selector-container"}>
              {this.showAppSelectorHeader("Mat Board", "")}
              <div className={"mat-board-size-selector-form selector-form"}>
                {this.showMatBoardSizingOptions("mat-board-size-input-field", "Width", "selectedMatBoardSizeWidth")}
                {this.showMatBoardInputMultiplicationSign()}
                {this.showMatBoardSizingOptions("mat-board-size-input-field", "Height", "selectedMatBoardSizeHeight")}
              </div>
            </div>
            <div className={`only-matboard-option`} onClick={() => {this.updateCutOrUnCutMatboard()}}>
              {this.state.selectedUnCutMatBoard ? "Need a window" : "Don't need a window"}
            </div>
            <div className={this.state.selectedUnCutMatBoard ? "display no-mat-board-opening-indicator" : "no-display"}>
              Your mat board will not have a window opening
            </div>
            <div className={this.state.selectedUnCutMatBoard ? "no-display" : "display"}>
              <div className={"window-size-selector selector-container"}>
                {this.showAppSelectorHeader("Artwork (Window)", "")}
                <div className={"mat-board-size-selector-form selector-form"}>
                  {this.showMatBoardSizingOptions("mat-board-size-input-field", "Width", "selectedMatWindowWidth")}
                  {this.showMatBoardInputMultiplicationSign()}
                  {this.showMatBoardSizingOptions("mat-board-size-input-field", "Height", "selectedMatWindowHeight")}
                </div>
              </div>
              <div className={"selector-container"}>
                <div className={"add-more-window-button app-underline-button no-display"}>
                  Need more opening +
                </div>
                <div className={"add-more-window-button app-underline-button no-display"}>
                  Change Window Shape
                </div>
              </div>
              <div className={"matting-colour-selector selector-container"}>
                {this.showAppSelectorHeader("Matting Colour", "matting-colour-selector-header")}
                <div className={"matting-colour-selector-form selector-form"}>
                  {this.showColourOptions("mattingColour", "selectedMatColour")}
                </div>
              </div>
              <div className={"matting-type-selector selector-container"}>
                {this.showAppSelectorHeader("Quality", "matting-thickness-selector-header")}
                <div className={"matting-thickness-selector-form selector-form"}>
                  {this.showOptionButtons("matBoardQuality", "selectedMatBoardQuality")}
                  {this.showOptionButtons("mattingThickness", "selectedMatThickness")}
                  {this.showOptionButtons("matBoardCore", "selectedMatCore")}
                </div>
              </div>
            </div>

          </div>

          <div className={this.state.selectedUnCutMatBoard ? "no-display" : "display"}>
            <div className={`primary-matting-chevron ${this.state.addSecondaryMattingClicked ? "display" : "no-display"}`}
                 onClick={(evt) => {
                   this.updateShowSecondaryMatting()
                 }}>
              <img alt={this.state.showSecondaryMatting ? `Click here to expand primary matting option.` : `Click here to reduce secondary matting option.`}
                   src={this.state.showSecondaryMatting ? showMoreIcon : showLessIcon}/>
            </div>
            <div className={"add-second-matting-button app-underline-button"}
                 onClick={(evt) => {
                   this.updateSecondaryMatting()
                 }}
            >
              {this.state.addSecondaryMattingClicked ? "Remove " : "Add "} Secondary Matting {this.state.addSecondaryMattingClicked ? "-" : "+"}
            </div>
            <div className={"add-second-matting-button app-underline-button"}>
              <a href={"/how-to-measure-mat-board-without-a-ruler"} rel="noopener noreferrer" target={"_blank"}><div>Don't have a ruler. Alternatives?</div></a>
            </div>

            <div className={`matting-dimensions-container ${this.state.showSecondaryMatting ? "display" : "no-display"}`}>
              <div
                className={`primary-matting-dimensions-container ${this.state.addSecondaryMattingClicked ? "display" : "no-display"}`}>
                Add Double Mat
              </div>
              <div
                className={`canvas-app-secondary-mat-dimensions ${this.state.showSecondaryMatting ? "display" : "no-display"}`}>
                {/*<form noValidate autoComplete="off">*/}
                  <div className={"secondary-matting-dimensions-container"}>
                    <div className={"matting-width-selector selector-container"}>
                      {this.showAppSelectorHeader("Double Matting Width", "matting-width-header")}
                      <div className={"matting-width-selector matting-measurement-unit-indicator"}>
                        {this.state.selectedMeasurementUnits === "in" ? "in inches" : "in cms"}
                      </div>

                      <div className={`second-matting-options ${this.state.manuallyEnterSecondaryMattingClicked ? "display" : "no-display"}`}>
                        {this.state.addSecondaryMattingClicked && this.showOptionButtons("secondaryMattingOption", "selectedSecondaryMatWidth")}
                      </div>

                      <div className={`mat-board-size-selector-form selector-form ${this.state.manuallyEnterSecondaryMattingClicked ? "no-display" : "display"}`} >
                        {this.showMatBoardSizingOptions("mat-board-opening-input-field", "Left", "selectedSecondaryMatWidthLeft")}
                        {this.showMatBoardSizingOptions("mat-board-opening-input-field", "Right", "selectedSecondaryMatWidthRight")}
                        {this.showMatBoardSizingOptions("mat-board-opening-input-field", "Top", "selectedSecondaryMatWidthTop")}
                        {this.showMatBoardSizingOptions("mat-board-opening-input-field", "Bottom", "selectedSecondaryMatWidthBottom")}
                      </div>

                      <div className={"add-second-matting-button app-underline-button"}
                           onClick={(evt) => {this.updateManuallyEnterSecondaryMatting(evt)}}
                      >
                        <div className={`${this.state.manuallyEnterSecondaryMattingClicked ? "display" : "no-display"}`}>Let me enter my numbers ></div>
                        <div className={`${this.state.manuallyEnterSecondaryMattingClicked ? "no-display" : "display"}`}>Show me popular options ></div>
                      </div>

                    </div>

                    <div className={"matting-colour-selector selector-container"}>
                      {this.showAppSelectorHeader("Matting Colour", "matting-colour-selector-header")}
                      <div className={"matting-colour-selector-form selector-form"}>
                        {this.showColourOptions("mattingColour", "selectedSecondaryMattingColour")}
                      </div>
                    </div>
                    <div className={"matting-type-selector selector-container"}>
                      {this.showAppSelectorHeader("Matting Thickness", "matting-thickness-selector-header")}
                      <div className={"matting-thickness-selector-form selector-form"}>
                        {this.showOptionButtons("secondaryMattingThickness", "selectedMatThickness")}
                      </div>
                    </div>
                  </div>
                {/*</form>*/}
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className={`canvas-app-mat-rendering canvas-app-rendering-wall-layer`}
           style={Object.assign({}, this.state.renderingContainerCSS, {backgroundColor: this.state.renderingWallColourOptions[this.state.renderWallColour].colorCode})}>
        <div className={"canvas-app-rendering-hide-text-button cursor-pointer float-left"}
        onClick={(evt) => this.updateHideTextButton()}>
          <div className={`canvas-app-rendering-expand-button-script
          ${this.state.clickedHideTextButton ? "no-display" : ""}`}>{this.state.clickedHideTextButton ? "Show" : "Hide"} Text</div>
          <img src={this.state.clickedHideTextButton ? showTextIcon : hideTextIcon} alt={`Hide texts.`}/>
        </div>

        <div className={"canvas-app-rendering-hide-text-button cursor-pointer float-right"}
             onClick={(evt) => this.restartPage()}>
          <img src={this.state.clickedHideTextButton ? refreshIcon : refreshIcon} alt={`Hide texts.`}/>
          <div className={`canvas-app-rendering-expand-button-script
          ${this.state.clickedHideTextButton ? "no-display" : ""}`}>Refresh Tool</div>
        </div>

        <div className={`wall-background`}>
          {
            renderImg(this.state.wallMaterial[this.state.renderWallType].image,
            this.state.wallMaterial[this.state.renderWallType].imageWebp, "frame-sample-image",
            this.state.wallMaterial[this.state.renderWallType].details)
          }
        </div>

        {/*this layer should be designed to show the wall pattern and wall colour*/}
        <div className={"frame-layer"}
             style={this.state.frameSizeRenderingCSS}>
          <div className={`canvas-app-rendering-moulding-layer
          ${this.state.highlightMatSection ? "rendering-section-hovered" : ""}`} style={this.state.matSizeRenderingCSS}>
            <div
              className={(this.state.showMatBoardWidthGuide || this.state.showMatBoardHeightGuide) ? `matting-dimension-indicator-helper-display` : `matting-dimension-indicator-helper`}
                style={
                this.state.showMatBoardWidthGuide ?
                {"width": `${parseInt(this.state.matSizeRenderingCSS.width) + 1}rem`, "height": "0.3rem", "marginLeft": "-0.5rem"} :
                {"width": "0.3rem", "height": `${parseInt(this.state.matSizeRenderingCSS.height) + 1}rem`, "marginTop": "-0.5rem"}
              }
                >
                <div className={(this.state.showMatBoardWidthGuide || this.state.showMatBoardHeightGuide) ? `matting-dimension-indicator-helper-display` : `matting-dimension-indicator-helper`}
                  style={this.state.showMatBoardHeightGuide ? {"textAlign": "left", "margin": "0.5rem"} : null}>
                  <div className={`matting-dimension-indicator-helper-display-text`}>
                    <span className={(this.state.showMatBoardWidthGuide) ? `matting-dimension-indicator-helper-display` : `matting-dimension-indicator-helper`}>
                      <img className={`matting-dimension-indicator-helper-arrow`} src={arrowTopIcon}
                           alt={"This is an indicator for measurement."}
                      />
                    </span>
                    <span className={(this.state.showMatBoardHeightGuide) ? `matting-dimension-indicator-helper-display` : `matting-dimension-indicator-helper`}>
                      <img className={`matting-dimension-indicator-helper-arrow`} src={arrowLeftIcon} alt={"This is a measurement indicator"}/>
                    </span>
                    See the highlighted length
                </div>
              </div>
            </div>
            <div className={`rendering-matting-dimensions-indicator
            ${this.state.clickedHideTextButton ? "no-display" : "display"}`}>
              <span className={`${this.state.selectedMatBoardSizeHeight === undefined ? "no-display" : "display"}`}>
                {this.state.selectedMatBoardSizeWidth} x {this.state.selectedMatBoardSizeHeight} {this.state.selectedMeasurementUnits}
              </span>
            </div>
            {/*this layer is to show the space matting layer*/}

            <div className={this.state.selectedUnCutMatBoard ? "no-display" : `canvas-app-rendering-primary-matting-layer`} style={this.state.primaryMatWindowRenderingCSS}>
              {/*this layer is to show the primary matting layer*/}

              <div
                className={this.state.selectedUnCutMatBoard ? "no-display": `canvas-app-rendering-secondary-matting-and-image-layer`}
                style={this.state.secondaryMatWindowRenderingCSS}>
                {/*this layer is to show the secondary matting layer*/}
                <div
                  className={(this.state.showWindowWidthGuide || this.state.showWindowHeightGuide) ? `matting-dimension-indicator-helper-display` : `matting-dimension-indicator-helper`}
                  style={
                    this.state.showWindowWidthGuide ?
                      {"width": this.state.primaryMatWindowRenderingCSS.width, "height": "0.3rem"} :
                      {"width": "0.3rem", "height": this.state.primaryMatWindowRenderingCSS.height}
                  }
                >

                  <div className={(this.state.showWindowWidthGuide || this.state.showWindowHeightGuide) ? `matting-dimension-indicator-helper-display-text` : `matting-dimension-indicator-helper`}
                    style={{"width": `${parseInt(this.state.matSizeRenderingCSS)-1}rem`}}
                  >
                    <span className={(this.state.showWindowWidthGuide) ? `matting-dimension-indicator-helper-display` : `matting-dimension-indicator-helper`}>
                      <img className={`matting-dimension-indicator-helper-arrow`} src={arrowTopIcon} alt={"This is an indicator for matting dimension measurement."}/>
                    </span>
                    <span className={(this.state.showWindowHeightGuide) ? `matting-dimension-indicator-helper-display` : `matting-dimension-indicator-helper`}>
                      <img className={`matting-dimension-indicator-helper-arrow`} src={arrowLeftIcon} alt={"This is an indicator for matting dimension measurement."}/>
                  </span>
                    See the highlighted length
                  </div>
                </div>

                <div className={this.state.highlightMatSection ? "show-matting-width-guide" : "no-display"}>
                  See the highlighted width
                </div>

                <div className={`rendering-matting-dimensions-indicator
                ${this.state.clickedHideTextButton ?  "no-display" : "display"}`}>
                  <span className={`${this.state.selectedMatWindowHeight === undefined ? "no-display" : "display"}`}>
                    {this.state.selectedMatWindowWidth} x {this.state.selectedMatWindowHeight} {this.state.selectedMeasurementUnits}
                  </span>
                </div>



                <div className={`canvas-app-rendering-upload-button ${this.state.uploadedArtworkImage === false ? "display" : "no-display"}`}>
                  <label className={`cursor-pointer ${this.state.clickedHideTextButton ? "no-display" : "display rendering-image-upload-button"}`} htmlFor="artworkImageFile">
                    <img className={`${this.state.clickedHideTextButton ? "no-display" : "upload-image-icon"}`} src={addImageIcon} alt={"Click here to upload your art."}/>
                    <div className={`${this.state.clickedHideTextButton ? "no-display" : "upload-image-label"}`}>
                      {this.state.uploadedArtworkImage ? "Replace" : 'Upload'} Image
                    </div>

                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className={"canvas-app-rendering-pricing"}>
        <div className={"canvas-app-rendering-container canvas-app-rendering-comp-pricing-section visualization-tool-section"}>
          <div className={"common-level"}>
            {this.showAppSelectorHeader("Visualization Tool", "canvas-app-rendering-comp-pricing-section-header visualization-tool-header")}
            <div className={"canvas-app-rendering-comp-pricing-section-options upload-image-button app-button"}>
              <label htmlFor="artworkImageFile">{this.state.uploadedArtworkImage ? "Change" : 'Upload'} Artwork Image</label>
              <input type="file" id="artworkImageFile" className={"no-display"}
                     onChange={this.handleChange}
              ></input>
            </div>
          </div>

          <div className={"common-level"}>
            {this.showAppSelectorHeader("Frame", "canvas-app-rendering-comp-pricing-section-options canvas-app-rendering-options")}
            <div className={"dimensions-unit-selector-container"}>
              {this.showOptionButtons("frameColour", "renderFrameType")}
            </div>
          </div>

          <div className={"common-level"}>
            {this.showAppSelectorHeader("Wall Colour", "canvas-app-rendering-comp-pricing-section-options canvas-app-rendering-option")}
            <div className={"dimensions-unit-selector-container"}>
              {this.showColourOptions("renderingWallColourOptions", "renderWallColour")}
            </div>
          </div>

          <div className={"common-level"}>
            {this.showAppSelectorHeader("Wall Pattern", "canvas-app-rendering-comp-pricing-section-options canvas-app-rendering-options")}
            <div className={"dimensions-unit-selector-container"}>
              {this.showOptionButtons("wallMaterial", "renderWallType")}
            </div>
          </div>
        </div>

        <div className={"canvas-app-rendering-container canvas-app-rendering-comp-pricing-section"}>
          <div className={"comp-pricing-summary-shipping-line-item"}>
            {this.showAppSelectorHeader("Ship to", "comp-pricing-summary-shipping-location-selector canvas-app-rendering-comp-pricing-section-header")}
            <div className={"shipping-location-options"}>
              {this.showCountryProvinceSelection("country")}
              {this.state.selectedDestinationCountry === "Canada" ? this.showCountryProvinceSelection("province") : ""}
            </div>
          </div>
          <a href="/pricing" target="_blank" rel="noopener noreferrer">
            <div className={`go-to-pricing-info`}>More on our Pricing & Shipping Costs +</div>
          </a>
        </div>

        <PriceRendering
          primaryMattingPrice={this.state.mattingPrice}
          matCuttingPrice={this.state.matCuttingPrice}
          secondaryMattingPrice={this.state.secondMattingPrice}
          secondMatCuttingPrice={this.state.secondMatCuttingPrice}
          shipping={this.state.shippingCosts}
          taxes={this.state.taxes}
          shippingDate={this.state.shippingDate}
          eta={this.state.eta}
          currencySelected={this.state.currencySelected}
        />

        <div className={this.state.currencySelected === "USD" ? "display forEx-information" : "no-display"}>
          1 USD = {cadUsdRate} CAD | Billed in US Dollars
        </div>

        <div className={"comp-pricing-section-line-item change-currency-button"} onClick={() => this.changeCurrency()}>
          Show prices in {this.state.currencySelected === "CAD" ? "US" : "Canada"} Dollars
        </div>

        <div className={"canvas-app-add-to-cart-button important-action-button"} onClick={() => this.moveToPaymentPage()}>
          Add to Cart
        </div>

      </div>

    </div>
  }
}