import TextField from "@material-ui/core/TextField";
import React from "react";

export default function displayInputFields(fieldLabel, value, onChangeFunction, variant) {

  return <TextField
    id="filled-number"
    label={fieldLabel}
    value={value}
    onChange={onChangeFunction}
    type="number"
    margin="normal"
    variant={variant}
    className={"window-size-input"}
    size={"small"}
    onWheel={(e) => e.target.blur()}
  />
};