import React from "react";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import {MenuItem} from "@material-ui/core";
import fetchPrice from "../comp-orderSummary/fetchPrice";
import calculateTotalPrice from "../comp-pricing/pricingCalculator";
import localStorageFunctions from "../comp-localStorage/localStroage";
import "@babel/polyfill";
import {secondMattingFactorialPrice} from "../comp-pricing/secondaryMat";
import showDollarPerUnit from "../comp-orderSummary/showDollarPerUnit";
import analyticsDataToDB from "../comp-analyticsLog/dbLogging";
import FormControl from '@material-ui/core/FormControl';
import {productDB} from "../../../javascript/components/comp-pricing/productDB";
import ShippingInfo from "./shippingInfo";
import {cadUsdRate} from "../../components/comp-pricing/forExRate";
import checkLatestVersion from "../page-landing/comp-landingPage/checkLatestVersion";
import EmailValidator from 'email-validator';
import connectToDB from "../database/connectDB";


export default class PaymentAppV2 extends React.Component {

  constructor() {
    super();
    this.state = {
      fetchSpinner: false,
      provinceSelected: false,
      pickUpOrShipping: "shipping",
      turnOffErrorChecker: true,
      french: false,
      promoCodeApplied: false,
      promoCodeEntered: "",
      // shippingAddressProvince: "Select",
      // country: "CA",
        customerEmail: "",
        customerPhoneNumber: "",
        shippingAddressRecipientName: "",
        shippingAddressStreet: "",
        shippingAddressSuite: "",
        shippingAddressCity: "",
        shippingAddressPostalCode: "",
        customerEmailValidated: false,
    };

    this.showSpinner = this.showSpinner.bind(this);
    this.selectProvinceOrState = this.selectProvinceOrState.bind(this);
    this.shippingInfoCheck = this.shippingInfoCheck.bind(this);
    this.showErrorInput = this.showErrorInput.bind(this);
    this.calculateShippingCosts = this.calculateShippingCosts.bind(this);
    this.fetchLanguage = this.fetchLanguage.bind(this);
    this.addTextFields = this.addTextFields.bind(this);
    this.moveToPaymentSection = this.moveToPaymentSection.bind(this);
    this.changeCountry = this.changeCountry.bind(this);
  }

  componentDidMount() {

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries());
    // checkLatestVersion();
    // if (params.focusedModal === "summary")



    this.loadStoredOrdersFromLocalStorage();
    localStorageFunctions("orderInProgress", "orderInProgress");
    localStorageFunctions("discountRate", `${this.state.discountRate}`, "encObj");

    this.loadLocalStorage();

    this.setState({
      french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false,
      location: localStorage.getItem("location"),
      // country: (localStorage.getItem("location") === "us" || localStorage.getItem("currency") === "US$") ? "US" : "CA",
      // shippingAddressProvince: localStorage.getItem("shippingAddressProvince"),
    })

    if (typeof window.pintrk === "function") {
      window.pintrk('track', 'AddToCart', {
        value: parseFloat(this.props.calculateItemSubtotal()),
        order_quantity: 1,
        currency: 'CAD'
      });
    }

    if (typeof window.ttq.track === "function") {
      window.ttq.track("AddToCart", {
        content_id: '301',
        content_type: "product",
        quantity: 1,
        price: parseFloat(this.props.calculateItemSubtotal()),
        value: parseFloat(this.props.calculateItemSubtotal()),
        currency: 'CAD',
      });
    }

  };



  validateCustomerEmail(propsObjectName) {
    if (EmailValidator.validate(this.state.customerEmail)) {
      this.setState({customerEmailValidated: true})
    } else {
      return null
    }
  }

  addTextFields(cssClassName, placeholderContent, propsObjectName, hasError, fieldTitle) {

    return <div className={cssClassName}>
      <div className={"add-text-fields-text-field-title"}>
        {fieldTitle}
      </div>
      <TextField
          id={"inspectletIgnore"}
          autoComplete={"off"}
          error={hasError}
          placeholder={placeholderContent}
          value={this.state[propsObjectName]}
          variant={"outlined"}
          onChange={(evt) => {this.setState({[propsObjectName]: evt.target.value}, () => {this.validateCustomerEmail(propsObjectName)})}}
      />
    </div>
  };

  loadLocalStorage() {
    var items = { ...localStorage };

    if (items.customerEmail) {
      this.setState({
        customerEmail: items.customerEmail,
        customerPhoneNumber: items.customerPhoneNumber,
        shippingAddressRecipientName: items.shippingAddressRecipientName,
        shippingAddressStreet: items.shippingAddressStreet,
        shippingAddressSuite: items.shippingAddressSuite,
        shippingAddressCity: items.shippingAddressCity,
        shippingAddressProvince: items.shippingAddressProvince,
        shippingAddressPostalCode: items.shippingAddressPostalCode,
        country: items.country,
      }, ()=>{this.validateCustomerEmail("customerEmail")} );
    } else {
      return null
    }
  }

  updateParentStateForCheckout() {
    // first update the address to address book.

    // updating following address info to the parent state to synchronize the info
    localStorage.setItem("country", this.state.country);
    localStorage.setItem("customerEmail", this.state.customerEmail);
    localStorage.setItem("customerPhoneNumber",  this.state.customerPhoneNumber);
    localStorage.setItem("discountRate", this.state.discountRate);
    localStorage.setItem("orderConfirmationNumber", this.state.orderConfirmationNumber);
    localStorage.setItem("promoCodeApplied", this.state.promoCodeApplied);
    localStorage.setItem("shippingAddressCity", this.state.shippingAddressCity);
    localStorage.setItem("shippingAddressPostalCode", this.state.shippingAddressPostalCode);
    localStorage.setItem("shippingAddressProvince", this.state.shippingAddressProvince);
    localStorage.setItem("shippingAddressRecipientName", this.state.shippingAddressRecipientName);
    localStorage.setItem("shippingAddressStreet", this.state.shippingAddressStreet);
    localStorage.setItem("shippingAddressSuite", this.state.shippingAddressSuite);

    this.props.parentSetStateCallback("country", this.state.country);
    this.props.parentSetStateCallback("customerEmail", this.state.customerEmail);
    this.props.parentSetStateCallback("customerPhoneNumber",  this.state.customerPhoneNumber);
    this.props.parentSetStateCallback("discountRate", this.state.discountRate);
    this.props.parentSetStateCallback("orderConfirmationNumber", this.state.orderConfirmationNumber);
    this.props.parentSetStateCallback("promoCodeApplied", this.state.promoCodeApplied);
    this.props.parentSetStateCallback("shippingAddressCity", this.state.shippingAddressCity);
    this.props.parentSetStateCallback("shippingAddressPostalCode", this.state.shippingAddressPostalCode);
    this.props.parentSetStateCallback("shippingAddressProvince", this.state.shippingAddressProvince);
    this.props.parentSetStateCallback("shippingAddressRecipientName", this.state.shippingAddressRecipientName);
    this.props.parentSetStateCallback("shippingAddressStreet", this.state.shippingAddressStreet);
    this.props.parentSetStateCallback("shippingAddressSuite", this.state.shippingAddressSuite);
    //updating the information to the local storage to be fetch by the next page

    // calculate 1. items subtotal (after quantity and promo discounts) 2. taxes based on that, and 3. grand total and update the app.js states
    // this.props.updatePrices();

    // go to payment page
    window.location.href="/payments-checkout";
  }


  submitInfoToGtag(tag) {
    window.gtag('event', tag, {
      'send_to': 'AW-732793253/RjgUCLrI2qcBEKWTtt0C',
      'value': parseFloat(this.props.calculateItemSubtotal()),
      'currency': "CAD",
      'transaction_id': ''
    })
  }

  fetchLanguage(fr, en) {
    return this.state.french ? `${fr}` : `${en}`;
  }

  paymentPageUserAnalytics() {
    analyticsDataToDB("payment-page", this.state);
  };

  loadStoredOrdersFromLocalStorage() {
    // // This function loads local storage and sets the local state

    this.setState({
          // MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw: destinationCountry,
          // ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE: destinationProvince,
        }
        , ()=> {

          //logs user data for analytics purposes
          // this.paymentPageUserAnalytics();



          if (this.state.shoppingItem == null) {
            // this.rerouteToOrderPage();
          } else {
            this.updateTotalAmountToLocalStorage();
          }


        }
    );
  };

  updateTotalAmountToLocalStorage() {
    var unencryptedTotalAmount = calculateTotalPrice("total", this.state.shoppingItem);

    this.setState({totalCost: unencryptedTotalAmount});
    localStorageFunctions("a7DqdNz46FaG4kHddQ11KNQ", unencryptedTotalAmount, "encObj");
  };

  updateState(objectToUpdate, objectSequence, objectKeyToUpdate, objectValue) {
    let stateToBeUpdated = Object.assign([], this.state[objectToUpdate]);    //creating copy of object
    stateToBeUpdated[objectSequence][objectKeyToUpdate] = objectValue;

    if (objectKeyToUpdate === "qItemQuantity") {
      if (objectValue === "0") {
        if (window.confirm('Do you want to remove your item?')) {
          this.deleteItem(objectToUpdate, objectSequence);
        } else {
          //DO NOTHING Because user don't wanna delete.
        }
      } else {
        this.setState({[objectToUpdate]: stateToBeUpdated}, () => this.updateLocalStorage());
      }
    } else {
      this.setState({[objectToUpdate]: stateToBeUpdated}, () => this.updateLocalStorage());
    }
  };

  updateLocalStorage() {
    localStorageFunctions("a1ysQM3xOEe9nGpQg5D5g", this.state.shoppingItem, "encObj");
    this.updateTotalAmountToLocalStorage();
  };

  deleteItem(objectToUpdate, sequence) {
    let stateToBeUpdated = Object.assign([], this.state[objectToUpdate]);    //creating copy of object
    stateToBeUpdated.splice(sequence, 1);
    this.setState({[objectToUpdate]: stateToBeUpdated}, () => this.updateLocalStorage()
    );
  };

  deleteItemOption(objectToUpdate, sequence, itemElementToDelete) {
    let stateToBeUpdated = Object.assign([], this.state[objectToUpdate]);    //creating copy of object

    if (window.confirm(`Do you want to remove the ${itemElementToDelete}?`)) {
      if(stateToBeUpdated && itemElementToDelete === "mat opening") {
        stateToBeUpdated[sequence].dPrimaryWindowWidth = 0;
        stateToBeUpdated[sequence].ePrimaryWindowHeight = 0;
        stateToBeUpdated[sequence].fPrimaryWindowTop = 0;
        stateToBeUpdated[sequence].gPrimaryWindowBottom = 0;
        stateToBeUpdated[sequence].hPrimaryWindowLeft = 0;
        stateToBeUpdated[sequence].iPrimaryWindowRight = 0;
        stateToBeUpdated[sequence].lMatCuttingPrice = 0;
        stateToBeUpdated[sequence].mSecondaryMatWidth = 0;
        stateToBeUpdated[sequence].nSecondaryMatColour = "Not Selected";
        stateToBeUpdated[sequence].oSecondMattingPrice = 0;
        stateToBeUpdated[sequence].pSecondMatCuttingPrice = 0;
        this.setState({[objectToUpdate]: stateToBeUpdated}
            , () => this.updateLocalStorage()
        );
      } else if (stateToBeUpdated && itemElementToDelete === "second matting") {
        stateToBeUpdated[sequence].mSecondaryMatWidth = "Not Selected";
        stateToBeUpdated[sequence].nSecondaryMatColour = "Not Selected";
        stateToBeUpdated[sequence].oSecondMattingPrice = 0;
        stateToBeUpdated[sequence].pSecondMatCuttingPrice = 0;
        this.setState({[objectToUpdate]: stateToBeUpdated}
            , () => this.updateLocalStorage()
        );
      }
    } else {
      //DO NOTHING Because user don't wanna delete.fetchShippingChargesPrompt
    }
  };

  rerouteToOrderPage() {
    window.location.href="/";
  };

  fetchOrderedItems() {

    //this is a part of legacy code
    if (this.state.shoppingItem === null) {
      this.rerouteToOrderPage();
    }

    var forExMultiplier = 1.00;

    if (this.state.currency === "USD") {
      forExMultiplier = cadUsdRate;
    }

    return <div>
      {this.state.shoppingItem.map((item, key) => {
        return  <div className={`order-summary-item-container`} key={`order-summary-item-container-${key}`}>

          <div className={`order-summary-subsection-container order-summary-item-breakdown`}>
            <div className={`float-left-section`}>
              Item {this.state.shoppingItem.length > 1 ? (key + 1) : ""}
            </div>

            <div className={`float-right-section order-summary-small-font`}>
              Quantity
              <input className={`order-summary-quantity-input-summary`} type={"text"}
                     value={this.state.shoppingItem[key].qItemQuantity}
                     onChange={(evt) => this.updateState("shoppingItem", key,  "qItemQuantity", evt.target.value)}
              />
            </div>
          </div>

          <div className={`order-summary-subsection-container order-summary-small-font`}>
            <div className={`float-left-section`}>
              Primary Mat ({item.bPrimaryMatWidth} <span> x </span> {item.cPrimaryMatHeight} {item.aMeasuringUnit}, <span className={`capitalize`}>{`${item.jPrimaryColour}`}</span>)
            </div>
            <div className={`float-right-section`}>
              <span className={"currency-container"} title={"in Canada Dollars"}>{this.state.currency}</span>
              <span>{fetchPrice(item.kPrimaryMattingPrice * forExMultiplier)} </span>
              {this.state.shoppingItem[key].qItemQuantity > 1 ? showDollarPerUnit(this.state.shoppingItem[key].qItemQuantity) : ""}
            </div>
          </div>

          <div className={`order-summary-remove-item-container order-summary-small-font`}>
            <div className={`float-right-section delete-button`}
                 onClick={() => this.updateState("shoppingItem", key,  "qItemQuantity", "0")}
            >
              Remove
            </div>
          </div>

          <div className={ (item.dPrimaryWindowWidth === undefined || item.dPrimaryWindowWidth === 0) ? `no-display` : `order-summary-subsection-container order-summary-small-font`}>
            <div className={`float-left-section`}>
              Opening
              ({item.dPrimaryWindowWidth} <span> x </span> {item.ePrimaryWindowHeight} {item.aMeasuringUnit})
            </div>
            <div className={`float-right-section`}>
              <span className={"currency-container"} title={"in Canada Dollars"}>{this.state.currency}</span>
              <span>{fetchPrice(item.lMatCuttingPrice * forExMultiplier)} </span>
              {this.state.shoppingItem[key].qItemQuantity > 1 ? showDollarPerUnit(this.state.shoppingItem[key].qItemQuantity) : ""}
            </div>
          </div>

          <div className={(item.dPrimaryWindowWidth === undefined || item.dPrimaryWindowWidth === 0) ? `no-display` : `order-summary-remove-item-container order-summary-small-font`}>
            <div className={`float-left-section order-summary-indent`}>
              Top {item.fPrimaryWindowTop} |
              Bottom {item.gPrimaryWindowBottom} |
              Left {item.hPrimaryWindowLeft} |
              Right {item.iPrimaryWindowRight} ({item.aMeasuringUnit})
            </div>
            <div className={`float-right-section delete-button`}
                 onClick={() => this.deleteItemOption("shoppingItem", key, "mat opening")}>
              Remove
            </div>
          </div>

          <div className={ (item.dPrimaryWindowWidth === undefined || item.dPrimaryWindowWidth === 0) ? `order-summary-subsection-container order-summary-small-font order-summary-italic`: `no-display`}>
            No window opening for this item
          </div>

          <div className={ item.mSecondaryMatWidth === "Not Selected" ? `order-summary-subsection-container order-summary-small-font order-summary-italic`: `no-display`}>
            No secondary matting for this item
          </div>

          <div className={ item.mSecondaryMatWidth === "Not Selected" ? `no-display` : `order-summary-subsection-container order-summary-small-font` }>
            <div className={`float-left-section`}>
              Add Double Mat <span className={"capitalize"}>({`${item.nSecondaryMatColour}`})</span>
            </div>
            <div className={`float-right-section`}>
              <span className={"currency-container"} title={"in Canada Dollars"}>{this.state.currency}</span>
              <span>{fetchPrice((item.oSecondMattingPrice + item.pSecondMatCuttingPrice)*secondMattingFactorialPrice)} </span>
              {this.state.shoppingItem[key].qItemQuantity > 1 ? showDollarPerUnit(this.state.shoppingItem[key].qItemQuantity) : ""}
            </div>
          </div>

          <div className={ item.mSecondaryMatWidth === "Not Selected" ? `no-display` : `order-summary-remove-item-container order-summary-small-font` }>
            <div className={`float-left-section order-summary-indent`}>
              Matting Width {item.mSecondaryMatWidth} ({item.aMeasuringUnit})
            </div>
            <div className={`float-right-section delete-button`}
                 onClick={() => this.deleteItemOption("shoppingItem", key, "second matting")}>
              Remove
            </div>
          </div>

        </div>
      })}
    </div>
  };

  moveToPaymentSection(shoppingSubtotal, shippingCost, taxes, grandTotal) {

    //need to update the app states, so that the next page gets accurately reflected

    // and move to href next page


    this.submitInfoToGtag('add_shipping_info');

  };

  updateShippingInfo(evt, propsObjectName) {
    this.setState({[propsObjectName]: evt.target.value}, () => {
      this.setState({shippingAddressProvince: evt.target.value})
    });
  };

  updateShippingInfoToLocalStorage(propsObjectName) {
    localStorageFunctions(`${propsObjectName}`, this.state[propsObjectName], "encObj");
  };

  addCountrySelector() {
    return <div className={"shipping-address-country"}>
      <FormControl variant={"filled"}>
        <Select
            id={"shipping-destination-country"}
            onChange={(evt) => {
              this.updateShippingInfo(evt, "country");
            }}
            value={this.state.country}
        >
          <MenuItem value={"Canada"}>Canada</MenuItem>
          <MenuItem value={"US"}>United States</MenuItem>
        </Select>
      </FormControl>
    </div>
  };

  showSpinner() {
    this.setState({fetchSpinner: true});
  };

  showErrorInput(input) {

    if (this.state.turnOffErrorChecker === false) {
      if (input === "shippingAddressProvince") {
        if (this.state[input] === "Select" || this.state[input] === "" || this.state[input] === null) {
          return true
        } else {
          return false
        }
      } else if (input === "customerEmail") {
        return this.state.customerEmail === "" || this.state.customerEmail === null || this.state.customerEmailValidated === false ? true : false
      } else {
        return this.state[input] === "" || this.state[input] === null ? true : false
      }

    } else {
      return false
    }
  };

  selectProvinceOrState() {
    if (this.state.country === "CA" || this.state.country === "US" || this.state.country === "EU"  || this.state.country === "AU") {
      var list = Object.keys(productDB.taxRates[this.state.country]);
      return <FormControl
          error={this.showErrorInput("shippingAddressProvince")}
          variant="outlined" className={"province-state-selector-container"}>
        <div className={"add-text-fields-text-field-title"}>
          {this.state.country === "CA" ? "Province" : "State"}
        </div>
        <Select value={this.state.shippingAddressProvince}
                onChange={(evt) => {
                  this.updateShippingInfo(evt, "shippingAddressProvince");
                }}
        >
          <MenuItem value={"Select"}>
            {this.state.country === "CA" ? "Please select / Veuillez sélectionner" : "Please select / Veuillez sélectionner"}
          </MenuItem>
          {
            list.map((item, key) => {
              return <MenuItem value={item} key={`select-province-state-${key}`} >
                {item}
              </MenuItem>
            })
          }
        </Select>
      </FormControl>
    } else {
      return <FormControl variant="outlined">
        <Select
            value={this.state.shippingAddressProvince}
            onChange={(evt) => {this.setState({shippingAddressProvince: evt.target.value})}}
            className={"province-state-selector-details-input"}
        >
          <MenuItem value={"Select"}>Select Province or State</MenuItem>
        </Select>
      </FormControl>
    }
  };

  shippingInfoCheck() {
    //checks if following information section has been filled up or not;

    this.validateCustomerEmail();

    if (
        // check the email validity
        this.state.customerEmail !== "" && this.state.customerEmailValidated === true  &&
        // address street check()
        this.state.shippingAddressStreet !== "" &&
        //shipping address city check
        this.state.shippingAddressCity !== "" &&
        //province check
        this.state.shippingAddressProvince !== null && this.state.shippingAddressProvince !== "" && this.state.shippingAddressProvince !== "Select" &&
        //postal code check
        this.state.shippingAddressPostalCode !== "" &&
        // address country check
        this.state.country !== "" &&
        //not checking the recipient name, apt/suite address since you don't always need them.
        this.state.shippingAddressRecipientName !== ""
    ) {


      var urlTrack =  window.location.host.split(".").pop();
      urlTrack = urlTrack.toUpperCase()
      var orderNumber =  `${(Math.random() * 1000000).toFixed(0)}-${urlTrack}${this.state.shippingAddressProvince}${(Math.random() * 1000).toFixed(0)}`;
      this.setState({orderConfirmationNumber: orderNumber}, () => {this.updateParentStateForCheckout();});
    } else {
      //shows which fields are missing (needs to highlight the field)
      this.setState({turnOffErrorChecker: false});
      //turn or error checker and show which fields are missing in case of an error;
    }
  };

  changeCountry(country) {
    this.setState({country: country})
  }

  calculateShippingCosts(subtotalAmount, shippingItems, country, shippingOrPickup) {
    return 0;
  }

  render() {
    //here we need to drop to decimal two digits

    return <div className={`payments-container`}>

      <ShippingInfo
          focusedModal={this.state.focusedModal}
          focusModal={this.focusModal}
          selectProvinceOrState={this.selectProvinceOrState}
          fetchSpinner={this.state.fetchSpinner}
          shippingInfoCheck={this.shippingInfoCheck}
          showErrorInput={this.showErrorInput}
          fetchPaymentSectionHeader={this.fetchPaymentSectionHeader}
          turnOffErrorChecker={this.state.turnOffErrorChecker}
          forPaymentApp={true}
          calculateShippingCosts = {this.calculateShippingCosts}
          shippingCost = {this.props.shippingCharges}
          french={this.state.french}
          fetchLanguage = {this.fetchLanguage}
          addTextFields={this.addTextFields}
          moveToPaymentSection={this.moveToPaymentSection}
          changeCountry={this.changeCountry}
          enterNewAddress={this.enterNewAddress}
          //shippingInfo
          shippingAddressRecipientName={this.state.shippingAddressRecipientName}
          customerEmail={this.state.customerEmail}
          customerPhoneNumber={this.state.customerPhoneNumber}
          shippingAddressStreet={this.state.shippingAddressStreet}
          shippingAddressSuite={this.state.shippingAddressSuite}
          shippingAddressCity={this.state.shippingAddressCity}
          shippingAddressPostalCode={this.state.shippingAddressPostalCode}
          shippingAddressProvince={this.state.shippingAddressProvince}
          country={this.state.country}
          orderReferenceNumber={this.state.orderReferenceNumber}
      />

    </div>
  }

}