import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {productDB} from "../../components/comp-pricing/productDB";

import recPhoto1 from "../../../resources/precut-mat-options/18x24/4x6no1.jpg";
import sqPhoto1 from "../../../resources/precut-mat-options/20x20/4x4no1.jpg";

import localStorageFunctions from "../comp-localStorage/localStroage";
import {Helmet} from "react-helmet";

import InstagramLoader from "../page-landing/instagramLoader";
import ReviewComponent from "../app-review/reviewComponent";
import LandingPageReviewStars from "../page-landing/landingPageReviewStars";
import LandingPageFAQ from "../page-landing/landingPageFAQ";
import SatisfactionTransition from "../page-landing/satisfactionTransition";
import WhatIsMatboard from "../page-howItWorks/whatIsMatboard";

export default class ShopByFrameSize extends React.Component {

    constructor() {
        super();
        this.state = {
            orientation: "portrait",
            sortSize: "all",


        };
    };

    componentDidMount() {
        // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})

        if (this.props.selectedMatSize === false) {
            var pathname = window.location.pathname;
            var matboardSize = pathname.substring(
                // pathname.indexOf("-inch") + 1,
                pathname.lastIndexOf("-inch-matboard")
            );
        }

        if (this.props.selectedMatSize) {
            this.setState({selectedFrameSize: this.props.selectedMatSize})

            if (this.props.selectedOpeningSize) {
                this.setState({selectedOpeningSize: this.props.selectedOpeningSize})

            } else if (this.props.selectedMatSize === "9x9" || this.props.selectedMatSize === "19.75x19.75" || this.props.selectedMatSize === "20x20") {
                this.setState({selectedOpeningSize: "4x4"})
            }

        }



        this.setState({
            french: (window.location.pathname.slice(-2) === "fr") ? true : false
        })
    };

    fetchIKEAFrameNames() {
        var frameNames = `For IKEA frames, our ${this.state.selectedFrameSize} inch matboards are designed to fit `;

        if (this.state.ikeaFrameNames[this.state.selectedFrameSize]) {
            this.state.ikeaFrameNames[this.state.selectedFrameSize].map((frame, key) => {
                if (key > 0) {
                    frameNames = frameNames + ", " + frame;
                } else {
                    frameNames = frameNames + " " + frame;
                }
            });
            frameNames = frameNames + " frames."
            return frameNames
        }

    }

    clickAddToCartButton() {

        var selectedProductData = this.state.frameSize[this.state.selectedFrameSize];
        var frameWidth = selectedProductData.width;
        var frameHeight = selectedProductData.height;
        var windowWidth = selectedProductData.openSize[this.state.selectedOpeningSize].width;
        var windowHeight = selectedProductData.openSize[this.state.selectedOpeningSize].height;
        var price = selectedProductData.priceCAD;


        var item = {
            prodType: "precutMat",
            frameType: "Not Applicable -- Matboard Only",
            frameSize: "Not Applicable -- Matboard Only",
            frameWidth: frameWidth,
            frameHeight: frameHeight,
            matColour: this.state.selectedMatColour,
            window: {
                rowCount: 1,
                columnCount: 1,
                window1: {
                    width: windowWidth,
                    height: windowHeight,
                    marginTop: (frameHeight - windowHeight)/2,
                    marginBottom: (frameHeight - windowHeight)/2,
                    marginLeft: (frameWidth - windowWidth)/2,
                    marginRight: (frameWidth - windowWidth)/2,
                    showModal: false,
                },
            },
            secondMatPrice: 0,
            secondMatColour: "none",
            secondMatWidth: 0,
            orderQuantity: this.state.selectedQuantity,
            // //for matboard price, we will have to run a pricing mechanism for matboard pricing. We might borrow this from our legacy system.
            price: price,
            shipping: [9.95, 9.95],
            canvasDrawing: null,
            additionalNoteContent: "",
            backingMatboard: false,
            isWindowOval: false,
        };

        this.addProductToLocalStorageCart(item);
        this.props.showShoppingCartModal("open");
    };

    addProductToLocalStorageCart(item) {
        //check if a shopper has already shopped something on before this item
        var shoppingCart = [];

        if (localStorageFunctions("shoppingCart", null) !== null ) {
            //this part will have to be parse json

            shoppingCart = JSON.parse(localStorageFunctions("shoppingCart", null));

            shoppingCart.push(item);
        } else {
            shoppingCart.push(item);
        };

        localStorageFunctions("shoppingCart", JSON.stringify(shoppingCart));
    };

    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    };


    fetchPreCutMats(FrameOrArtworkSize) {

        var array = Object.keys(productDB.matboardByFrameSize);

        var sortedSizeMin = 0;
        var sortedSizeMax = 100;

        if (this.state.sortSize === "all") {
            sortedSizeMin = 0;
            sortedSizeMax = 100;

        } else if (this.state.sortSize === "table") {
            sortedSizeMin = 0;
            sortedSizeMax = 10.1;
        } else if (this.state.sortSize === "small") {
            sortedSizeMin = 9.9;
            sortedSizeMax = 20.1;
        } else if (this.state.sortSize === "medium") {
            sortedSizeMin = 19.9;
            sortedSizeMax = 30.1;
        } else if (this.state.sortSize === "large") {
            sortedSizeMin = 29.9;
            sortedSizeMax = 100;
        }

        return array.map((sortSize, key) => {

            var matWidth = productDB.matboardByFrameSize[sortSize].width;
            var matHeight = productDB.matboardByFrameSize[sortSize].height;
            var longestSide = productDB.matboardByFrameSize[sortSize].width >= productDB.matboardByFrameSize[sortSize].height ? productDB.matboardByFrameSize[sortSize].width : productDB.matboardByFrameSize[sortSize].height


            if (longestSide >= sortedSizeMin && longestSide <= sortedSizeMax) {
                return <a className={"pre-cut-mat-box"}
                          href={this.props.french ? `/${matWidth}x${matHeight}-inch-matboard-for-${matWidth}x${matHeight}-inch-frames-fr` : `/${matWidth}x${matHeight}-inch-matboard-for-${matWidth}x${matHeight}-inch-frames`}
                >
                    <img style={{width: "100%"}} src={matWidth === matHeight ? sqPhoto1 : recPhoto1} className={"pre-cut-mat-box-img"}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                    />
                    <div className={"fetch-precut-mats-title-container"}>
                        <div className={"fetch-precut-mats-title"}>
                            {this.props.french  ? `Passe-partout pour` : `Matboard for `}<br/>
                            {this.props.french ? `Cadres de ${matWidth} x ${matHeight} pouces` : `${matWidth} x ${matHeight} inch Frames`}
                        </div>
                    </div>
                </a>
            } else {
                return <div>

                </div>
            }


        })
    };

    fetchHeader() {
        if (this.props.french) {
            return <div className={`precut-mat-container`}>

                <div className={this.state.selectedFrameSize ? "no-display" : "display"} style={{margin: "16px 0"}}>
                    <h1 className={"inspiration-listing-header"}>
                        ACHETEZ VOTRE PASSE-PARTOUT <br className={"mobile-no-display"}/>
                        PAR TAILLE DE CADRE
                    </h1>
                    <h2 className={"inspiration-listing-desc"}>
                        PERSONNALISEZ VOTRE PASSE-PARTOUT <br className={"mobile-no-display"}/>
                        EN FONCTION DE LA TAILLE DE VOTRE CADRE. <br className={"mobile-no-display"}/>
                        CRÉEZ DES PASSE-PARTOUTS PERSONNALISÉS <br className={"mobile-no-display"}/>
                        DE TOUTES TAILLES ET FORMES.
                    </h2>

                    <div style={{width: "100%", margin: "24px 0", textAlign: "center"}}>
                        <div className={"fetch-precut-mats-title"} style={{margin: "24px 0px", textAlign: "center", width: "100%"}}>
                            Trier par Tailles
                        </div>
                        <div className={this.state.sortSize === "all" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "all"})}}>
                            TOUT AFFICHER<br/>
                            TAILLES DISPONIBLES
                        </div>
                        <div className={this.state.sortSize === "table" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "table"})}}>
                            CADRES DE TABLE<br/>
                            (JUSQU'À 10 POUCES)
                        </div>
                        <div className={this.state.sortSize === "small" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "small"})}}>
                            PETITS CADRES<br/>
                            (10" - 20")
                        </div>
                        <div className={this.state.sortSize === "medium" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "medium"})}}>
                            CADRES MOYENS<br/>
                            (20" - 30")
                        </div>
                        <div className={this.state.sortSize === "large" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "large"})}}>
                            GRANDS CADRES<br/>
                            (30" ET PLUS)
                        </div>



                    </div>

                    <div style={{width: "100%", overflow: "auto", margin: "32px 0"}}>
                        {this.fetchPreCutMats()}
                    </div>
                </div>

            </div>
        } else {
            return <div className={`precut-mat-container`}>

                <div className={this.state.selectedFrameSize ? "no-display" : "display"} style={{margin: "16px 0"}}>
                    <h1 className={"inspiration-listing-header"}>
                        Shop your matboard <br className={"mobile-no-display"}/>
                        by Frame size
                    </h1>
                    <h2 className={"inspiration-listing-desc"}>
                        Customize your matboard <br className={"mobile-no-display"}/>
                        based on your frame size. <br className={"mobile-no-display"}/>
                        Create custom matboards<br className={"mobile-no-display"}/> in any size & shape.
                    </h2>

                    <div style={{width: "100%", margin: "24px 0", textAlign: "center"}}>
                        <div className={"fetch-precut-mats-title"} style={{margin: "24px 0px", textAlign: "center", width: "100%"}}>
                            Sort by Sizes
                        </div>
                        <div className={this.state.sortSize === "all" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "all"})}}>
                            Show All <br/>
                            Available Sizes
                        </div>
                        <div className={this.state.sortSize === "table" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "table"})}}>
                            Table Frames<br/>
                            (Upto 10")
                        </div>
                        <div className={this.state.sortSize === "small" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "small"})}}>
                            Small Frames<br/>
                            (10" - 20")
                        </div>
                        <div className={this.state.sortSize === "medium" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "medium"})}}>
                            Medium Frames<br/>
                            (20" - 30")
                        </div>
                        <div className={this.state.sortSize === "large" ? "sort-by-frame-size-button-selected" : "sort-by-frame-size-button"} onClick={() => {this.setState({sortSize: "large"})}}>
                            Large Frames<br/>
                            (30"+)
                        </div>



                    </div>

                    <div style={{width: "100%", overflow: "auto", margin: "32px 0"}}>
                        {this.fetchPreCutMats()}
                    </div>
                </div>

            </div>
        }
    }

    render() {
        return <div>
            <Helmet>
                <title>
                    {this.state.french ?
                        `Passe-Partout Personnalisés | Magasinez par Taille de Cadre | Créez Toutes Tailles & Formes` :
                        `Custom Matboards | Shop by Frame Size | Create Any Size & Shape`}
                </title>
                <meta name="description" content={
                    this.state.french ?
                        `Politique de retour garantie à 100 % de bonheur. Si vous n'êtes pas satisfait de votre commande, faites-le nous savoir. Contactez-nous via hello@custommat.ca et nous corrigerons la situation. Nous avons une politique de retour et de remboursement facile. Livraison de nos passe-partout sur mesure à votre porte, partout au Canada et aux États-Unis. Toutes nos commandes proviennent de : Canada : entrepôt dans le sud de l'Ontario, États-Unis : entrepôt dans le nord de l'État de New York. Tous nos passe-partout sont fabriqués au Canada, aux États-Unis et au Royaume-Uni. Délais de production et de livraison : de la commande à la production : 1 à 2 jours ouvrables, délai de livraison : 1 à 6 jours ouvrables. Estimations de livraison : Canada - ON, QC : 2 à 5 jours, AB, SK, MB : 3 à 7 jours, BC : 5 à 8 jours, NB, NL, NS, PE : 4 à 8 jours, Territoires du Nord : 7 à 12 jours. États-Unis - Côte Est et Midwest : 3 à 6 jours, Nord-Ouest : 4 à 7 jours, Sud-Est et Sud-Ouest : 4 à 7 jours, Alaska, Hawaï, Porto Rico : 7 à 14 jours. Quantité minimale de commande (QMC) : Aucune quantité minimale requise. La livraison est gratuite pour les commandes de plus de 49 $, sinon des frais fixes de 9,95 $ s'appliquent. Détails du produit : Taille du passe-partout : 11 x 17 pouces, épaisseur standard de 4 plis (1/16 de pouce), biseau à 45 degrés et cœur blanc net, surface tamponnée sans acide pour répondre à vos besoins de préservation, résistant à la décoloration et aux saignements, fabriqué et expédié du Canada. Des questions ? Si vous avez des questions, veuillez nous envoyer un courriel à hello@CustomMat.ca ou nous envoyer un SMS au 604•345•8428.` :
                        `Explore our return policy, fast production & delivery times for custom size matboards. Get 100% Happiness Guaranteed with easy returns & refunds. Delivery estimates for Canada & the USA. No minimum order. Premium white core matboards available in various sizes. Acid-free and fade-resistant. Made and shipped from Canada. Contact us for any questions.`
                } />
            </Helmet>

            {this.fetchHeader()}

            <WhatIsMatboard/>

            <InstagramLoader
                french={this.state.french}
            />

            <ReviewComponent
                french={this.state.french}
            />

            <SatisfactionTransition
                french={this.state.french}
            />


            <LandingPageFAQ
                location = {this.props.location}
            />



        </div>
    }
}