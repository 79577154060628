export default function priceTesting () {
    var min = Math.ceil(1);
    var max = Math.floor(3);

    if (localStorage.getItem("orderConfirmationNumber")) {
        return localStorage.getItem("orderConfirmationNumber");
    } else {
        var rand = Math.floor(Math.random() * (max - min + 1)) + min;
        localStorage.setItem("orderConfirmationNumber", `${rand}`);
        return rand;
    }

}
//add if total product price goes over 50 waive the shipping cost